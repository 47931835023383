import React, { useState } from "react";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import { FSIconRenderer } from "../../../zinoIcon";
import PopupBoxSection from "../../../viewlib/PopupBoxSection/PopupBoxSection";
import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../../utils/apiCallFunction";
import axios from "axios";
import { getApiCallHeadersData } from "../../../../utils/storageFunction";
import ProcessBarSection from "../../../viewlib/ProcessBarSection/PopupBoxSection";
import { stringToJSONChange } from "../../../utils/commonFunction";
import AddWishList from "../../../../components/WishList/AddWishList";

const JobAction = ({ itemConfig, itemType, reqData = {}, callBackFunction, functionObject }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [aiPrompt, setAiPrompt] = useState("");
  const [aiResponsePopup, setAiResponsePopup] = useState(false);
  const [aiResponse, setAiResponse] = useState({});
  const [loader, setLoader] = useState(false);
  const [showAddWishList, setShowAddWishList] = useState(false);

  let aiJobs = ["perform_activity_ai", "perform_record_ai"];

  const { createNewTask } = usePageHandlerContext();
  const handleJobCallFunction = async () => {
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig?.action_start || {},
      action_end: itemConfig?.action_end || {},
      // action_end: itemConfig?.action_end
      //   ? itemConfig.action_end
      //   : { refresh: itemConfig.refresh_block ? [itemConfig.namespace] : [] },
      callback_function: [],
      callback_function_after: [callBackFunction]
    };

    let refresh = itemConfig?.action_end?.refresh || [];
    if (functionObject?.reloadFunction && refresh.length > 0) {
      element_config.callback_function_after.push(functionObject?.reloadFunction);
    }

    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
      sm_id: itemConfig.data_id || itemConfig?.action_data?.sm_id,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    if (itemType === "single") {
      action_config["instance_id"] = reqData.instance_id || reqData.id || reqData._id || null;
    } else if (itemType === "multi") {
      let instance_ids = null;
      if (reqData.instance_ids) {
        instance_ids = reqData.instance_ids;
      } else if (reqData.id || reqData._id) {
        instance_ids = [reqData.id || reqData._id];
      }
      action_config["instance_ids"] = instance_ids;
    }

    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData.data = {};
    } else if (["perform_activity_ai"].includes(action_config.action_name)) {
      reqData.data = { prompt: aiPrompt };
    } else if (["perform_record_ai"].includes(action_config.action_name)) {
      reqData.data = { ...reqData.data, user_prompt: aiPrompt };
      setIsPopupOpen(false);
      await handleRecordAiJob(action_config, reqData.data);
      setAiPrompt("");
      return null;
    } else {
      reqData.data = { ...reqData.data, sm_id: action_config.sm_id };
    }

    createNewTask(element_config, action_config, reqData.data);
    setAiPrompt("");
    setIsPopupOpen(false);
  };

  const handleRecordAiJob = async (action_config, reqData) => {
    try {
      setLoader(true);
      const payload = {
        function_name: "record_ai",
        function_id: "",
        data: {},
        params: {
          ...reqData.params,
          user_prompt: reqData.user_prompt,
          instance_id: action_config?.instance_id || null,
          instance_ids: action_config?.instance_ids || []
        }
      };
      const { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, payload, {
        headers: getApiCallHeadersData()
      });
      setAiResponse(data?.data);
      setAiResponsePopup(true);
    } catch (error) {
      toast.error("something went wrong", toastErrorMessageStyle());
    } finally {
      setLoader(false);
    }
  };

  let buttonClass = itemConfig.disabled ? "zino_btn_disabled" : "zino_btn_outline_primary";

  let class_name = "";

  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      class_name = Object.values(itemConfig.className).join(" ");
    } else {
      class_name = itemConfig.className;
    }
  }

  const btnHandler = (e) => {
    e.stopPropagation();

    if (itemConfig.job_template === "add_wishlist") {
      setShowAddWishList(true);
    } else {
      handleJobCallFunction();
    }
  };

  let body = null;

  if (aiJobs.includes(itemConfig?.job_template)) {
    body = (
      <>
        <button
          className={`${buttonClass} ${class_name} ${itemConfig?.custom_classname || ""}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsPopupOpen(true);
          }}
          id={`${itemConfig?.custom_id || ""}`}
          style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
        >
          <FSIconRenderer icon={itemConfig?.icon || ""} marginR="0px" /* marginR={itemConfig?.name ? "8px" : "0"} */ />
          {itemConfig?.name}
        </button>
        {isPopupOpen && (
          <PopupBoxSection>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "15px"
              }}
            >
              <label htmlFor="aiprompt"></label>
              <textarea
                className="aiprompt_textarea"
                id="aiprompt"
                name="prompt"
                rows="20"
                cols="50"
                onChange={(e) => {
                  setAiPrompt(e.target.value);
                }}
                defaultValue={aiPrompt}
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
                padding: "15px"
              }}
            >
              <button
                className={`zino_btn_outline_primary`}
                onClick={() => {
                  setAiPrompt("");
                  setIsPopupOpen(false);
                }}
                id={`${itemConfig?.custom_id || ""} ${itemConfig?.custom_classname || ""}`}
                style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
              >
                Cancel
              </button>
              <button
                className={`zino_btn_primary ${itemConfig?.custom_classname || ""}`}
                onClick={handleJobCallFunction}
                id={`${itemConfig?.custom_id || ""}`}
                style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
              >
                Submit
              </button>
            </div>
          </PopupBoxSection>
        )}

        {aiResponsePopup && (
          <PopupBoxSection>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px",
                  borderBottom: "1px solid #dadada"
                }}
              >
                <p>Response</p>
                <span className="material-symbols-outlined" onClick={() => setAiResponsePopup(false)}>
                  close
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px"
                }}
              >
                <p>{aiResponse?.output || "No Response"}</p>
              </div>
            </div>
          </PopupBoxSection>
        )}
        {loader && <ProcessBarSection />}
      </>
    );
  } else {
    body = (
      <button
        className={`${buttonClass} ${class_name} ${itemConfig?.custom_classname || ""}`}
        onClick={btnHandler}
        id={`${itemConfig?.custom_id || ""}`}
        style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
      >
        <FSIconRenderer icon={itemConfig?.icon || ""} marginR="0px" /* marginR={itemConfig?.name ? "8px" : "0"} */ />
        {itemConfig?.name}
      </button>
    );
  }

  return (
    <>
      {body}
      {showAddWishList && (
        <AddWishList
          onClose={setShowAddWishList}
          smId={itemConfig.data_id || itemConfig?.action_data?.sm_id}
          instanceId={reqData.instance_id}
        />
      )}
    </>
  );
};

export default JobAction;
