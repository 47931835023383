import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { commonFunction } from "../utils/commonFunction";
import { FSIconRenderer } from "../../zinoIcon";

const FFLearningTagsField = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || []
  );

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []);
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  function removeEmptyLabel(objects) {
    return objects.filter((obj) => {
      if (typeof obj === "string") {
        return obj?.trim() !== "";
      }
      return false;
    });
  }

  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState(removeEmptyLabel([...component.data.values]));
  const [filteredOptions, setFilteredOptions] = useState(options);

  if (component?.sort) {
    options.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }
  const validate = async (value) => {
    if (typeof value === "undefined") return;
    setInputValue(value);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
  };

  useEffect(() => {
    setFilteredOptions(options.filter((option) => !inputValue.includes(option)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  let styleIs = {};
  if (component.disable) {
    styleIs["pointerEvents"] = "none";
  }
  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <ApplearingTags
        keyName="value"
        id={component.id || component._id}
        options={filteredOptions}
        isSearchable={false}
        label={component.label}
        disabled={component.disable}
        required={component.validate.required}
        value={inputValue || []}
        handleChange={(value) => {
          validate(value);
        }}
        addNewOne={component["allow_new"]}
        filterOptions={(options, { input_value }) => {
          if (!component.show_default_option && input_value && input_value.trim() === "") {
            return [];
          }
          if (!input_value) {
            return options;
          }
          const inputValues = input_value.trim().toLowerCase().split(" ");
          return options.filter((option) => {
            const labelWords = option.toLowerCase().split(" ");
            const firstWord = labelWords[0];
            return inputValues.every((val, index) => {
              if (index === 0) {
                return firstWord.startsWith(val);
              } else {
                return labelWords.slice(1).some((word) => word.startsWith(val));
              }
            });
          });
        }}
      />
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id || component._id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFLearningTagsField;

const ApplearingTags = ({ id, options, handleChange, label, value, filterOptions, disabled, required, addNewOne }) => {
  const [query, setQuery] = useState("");
  // const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setisFocus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const selectValueRef = useRef(null);

  // useEffect(() => {
  // const handleClickOutside = (event) => {
  // if (selectValueRef.current && selectValueRef.current.contains(event.target)) {
  //   setisFocus(true);
  // } else {
  //   setisFocus(false);
  // }
  // if (inputRef.current && !inputRef.current.contains(event.target)) {
  //   setIsOpen(false);
  // } else {
  //   setIsOpen(true);
  // }
  // };
  // document.addEventListener("click", handleClickOutside);
  // return () => {
  //   document.removeEventListener("click", handleClickOutside);
  // };
  // }, []);

  const handleDeleteTag = (tagToDelete) => {
    const temp = value.filter((tag) => tag !== tagToDelete);
    handleChange(temp);
  };

  const selectOption = (option) => {
    handleChange([...value, option]);
    // setIsOpen(false); // Toggle isOpen state
  };

  const filteredOptions = filterOptions ? filterOptions(options, { input_value: query }) : options;

  const handleInputKeyDown = (event) => {
    setSelectedIndex(-1);
    if (event.key === "Enter" && query.trim() !== "") {
      const newValue = query.trim();
      if (addNewOne) {
        handleChange([...value, newValue]);
        setQuery("");
      } else {
        if (options.includes(newValue)) {
          handleChange([...value, newValue]);
          setQuery("");
        }
      }
    }

    if (event.key === "Backspace" && query === "") {
      value.pop();
      handleChange([...value]);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "ArrowDown") {
      if (selectedIndex < filteredOptions.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    } else if (event.key === "ArrowUp") {
      if (selectedIndex >= 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
    if (event.key === "Enter" && selectedIndex > -1) {
      handleChange([...value, filteredOptions?.[selectedIndex] || ""]);
    }
  };

  return (
    <div className="searchable_dropdown w_100 learningTag_dropdown" ref={inputRef}>
      <div className="control" onKeyDown={handleKeyPress}>
        <div
          // className={`selected-value learning-tags-selected-value ${isOpen || value ? "has-value" : ""} ${
          className={`selected-value learning-tags-selected-value ${value ? "has-value" : ""} ${
            isFocus ? "has-focus" : ""
          } `}
          ref={selectValueRef}
        >
          <div className="d_flex select_chip_box_outer">
            {value.map((valueIs, valueIndex) => {
              return (
                <div className="select-chips-box" key={valueIndex}>
                  <span>{valueIs}</span>
                  <span className="close-icon" onClick={() => handleDeleteTag(valueIs)}>
                    <FSIconRenderer marginR="0" icon={"close"} />
                  </span>
                </div>
              );
            })}
            {disabled ? (
              // <input
              //   type="text"
              //   value={query || ""}
              //   name="searchTerm"
              //   disabled={true}
              //   className={`did-floating-select ${isOpen || value ? "has-value" : ""} ${isFocus ? "has-focus" : ""}`}
              // />
              <></>
            ) : (
              <input
                type="text"
                value={query || ""}
                name="searchTerm"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                style={{ border: "none", height: "32px", width: isFocus ? "unset" : "0px" }}
                onKeyDown={handleInputKeyDown}
                className={`did-floating-select`}
                autoComplete="off"
                onFocus={() => setisFocus(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setisFocus(false);
                    setSelectedIndex(-1);
                  }, 200);
                }}
              />
            )}
          </div>
        </div>
        <div className={`arrow ${isFocus ? "open" : ""}`}></div>
        {/* <div className={`arrow ${isOpen ? "open" : ""}`}></div> */}
        {isFocus && (
          <div id={id + "_optionList"} className={`options ${isFocus ? "open" : ""}`}>
            {filteredOptions.map((option, index) => {
              return (
                <div
                  key={index}
                  onClick={(e) => {
                    selectOption(option);
                    setisFocus(true);
                  }}
                  className={`option ${(value || []).includes(option) || selectedIndex === index ? "selected" : ""}`}
                >
                  {option}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {/* <label className={`did-floating-label ${isOpen || value ? "has-value" : ""} ${isFocus ? "has-focus" : ""}`}> */}
      <label
        className={`did-floating-label ${isFocus ? "has-focus" : ""} ${
          (isFocus && (value || []).length === 0) || (value || []).length > 0 ? "has-value" : ""
        }`}
      >
        {label} {required && " *"}
      </label>
    </div>
  );
};
