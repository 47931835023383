import React, { useLayoutEffect } from "react";

import RightPart from "../components/RightPart";
import LeftPart from "../components/LeftPart";

import { getDashboardConfig } from "../../../redux/chartview/chartviewaction";
import { useDispatch, useSelector } from "react-redux";
import Tilesview from "../../tilesviewlib/Tilesview";

function ChartRendererFunction({ appdataobject, namespace, jobParamsTokenConfig = {} }) {
  const { chartDashboard } = appdataobject;

  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.chartviewstore);
  let filterDataForAPICall = storeData?.[`${namespace}_chartFilterDataForAPICall`] || [];
  let filterDataForField = storeData?.[`${namespace}_chartFilterDataForField`] || {};
  useLayoutEffect(() => {
    dispatch(getDashboardConfig(namespace, chartDashboard, jobParamsTokenConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="DashboardRF_dashboardBox ">
        <div className="DashboardRF_rightPartBox DashboardRF_rightPartBox ">
          <Tilesview tilesArray={chartDashboard?.tiles || []} tilesData={filterDataForField?.tiles || {}} />
          <div>
            <LeftPart namespace={namespace} filterData={filterDataForAPICall} />
          </div>
          <RightPart namespace={namespace} />
        </div>
      </div>
    </>
  );
}

export default ChartRendererFunction;
