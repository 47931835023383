import axios from "axios";
import { toast } from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

// ACTION TYPE
export const KANBAN_DATA = "KANBAN_DATA";

//ACTION
export const setKanbanData = (namespace, payloaddata) => ({
  type: KANBAN_DATA,
  payload: { namespace, payloaddata }
});

export const getKanbanData =
  ({ namespace }) =>
  async (dispatch) => {
    // API Call and get recordview data
    try {
      let kanban_id = namespace;
      let search_query = {
        search_term: "",
        search_fields: [],
        page: 1,
        per_page: 100
      };
      let { data } = await axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/dynamic`,
        data: {
          data: {},
          function_name: "perform_search",
          params: {
            kanban_id: kanban_id,
            search_query: search_query,
            front_end: {}
          }
        },
        headers: getApiCallHeadersData()
      });

      let viewData = data?.data;

      if (viewData.message) {
        viewData = {
          facets: [],
          documents: [],
          total_documents: 0,
          out_of: 0,
          page: 1,
          per_page: 100
        };
      }
      dispatch(setKanbanData(namespace, viewData));

      return true;
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error, toastErrorMessageStyle());
      return false;
    }
  };
