import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Badge from "@mui/material/Badge";
import { getMessaging, onMessage } from "firebase/messaging";
import { useSelector } from "react-redux";

import "./Chatbox.css";

import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { ChatIconSvg, CloseIconSvg } from "../../lib/zinoIcon";
import { PopupBoxSection } from "../../lib";
import ChaImg from "../chatbox/chat.png";
import { isZinoOrLocal } from "../../utils/findHostname";

const Chat = ({ nav_pos }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div title="Chat">
      <div className="d_flex g_8 left_navbar_chat">
        <span className="nav_icon_outer" onClick={() => setShowPopup(true)}>
          <div style={{ marginTop: "8px" }}>
            <ChatIconSvg fill="#667085" />
          </div>
        </span>
        {nav_pos !== "top" && <span className="popupSection_header_display ">Messages</span>}
      </div>
      {showPopup && (
        <PopupBoxSection closePopup={() => setShowPopup(false)} showPopup={showPopup}>
          <div className="popupSection_header ">
            <span className="popupSection_header_display ">Messages</span>
            <span className="popupSection_header_closeBTN" onClick={() => setShowPopup(false)}>
              <CloseIconSvg />
            </span>
          </div>
          <ChatApp />
        </PopupBoxSection>
      )}
    </div>
  );
};

const ChatApp = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [usersSearch, setUsersSearch] = useState([]);
  const [conversationData, setConversationData] = useState([]);

  const { user } = useSelector((state) => state.userviewstore);
  const latestMessageRef = useRef(null);

  const fetchUsers = useCallback(async () => {
    try {
      const app_id = localStorage.getItem("Zino_app_id");

      const response = await axios.get(`${getApiCallLocalPath()}api/v1/user-service/app/${app_id}`, {
        headers: getApiCallHeadersData()
      });

      setUsersSearch(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, []);

  const recentChats = useCallback(async () => {
    try {
      const appid = localStorage.getItem("Zino_app_id");
      const receiver = user.login_user_id;

      const recentusers = {
        app_id: appid,
        user_id: receiver
      };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/getrecentchatforuser`, recentusers, {
        headers: getApiCallHeadersData()
      });

      const uniqueUsers = response.data.data;

      setUsers(uniqueUsers);
    } catch (error) {
      console.error("Error fetching recent chats:", error);
    }
  }, [user.login_user_id]);

  useEffect(() => {
    fetchUsers();
    recentChats();
  }, [fetchUsers, recentChats]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSendMessage = async () => {
    try {
      const senderId = user.login_user_id;
      const senderName = user.name;
      const appId = localStorage.getItem("Zino_app_id");
      const receiverId = selectedUser.id;
      const receiverName = selectedUser.name;

      setMessage("");

      const messageData = {
        app_id: appId,
        message: message,
        receiver_id: receiverId,
        receiver_name: receiverName,
        sender_id: senderId,
        sender_name: senderName
      };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/sendMessage`, messageData, {
        headers: getApiCallHeadersData()
      });

      const latestmessage = response.data.data;
      setConversationData(latestmessage);

      const chatWindow = document.getElementById("chat-window");

      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const getConversation = useCallback(async (sender, receiver) => {
    try {
      const appid = localStorage.getItem("Zino_app_id");

      const getconvo = {
        app_id: appid,
        sender_id: sender,
        receiver_id: receiver
      };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/getConversation`, getconvo, {
        headers: getApiCallHeadersData()
      });

      const conversationData = response.data.data.map((item) => ({
        _id: item._id,
        message: item.message,
        timestamp: item.timestamp,
        sender_id: item.sender_id
      }));

      setConversationData((prevData) => {
        const isDifferent = JSON.stringify(prevData) !== JSON.stringify(conversationData);
        return isDifferent ? conversationData : prevData;
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching conversation:", error);

      return null;
    }
  }, []);

  const readMessage = useCallback(async (sender, receiver) => {
    try {
      const appid = localStorage.getItem("Zino_app_id");

      const param = {
        app_id: appid,
        sender_id: sender,
        receiver_id: receiver
      };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/getConversationNew`, param, {
        headers: getApiCallHeadersData()
      });

      const conversationData = response.data.data.map((item) => ({
        _id: item._id,
        message: item.message,
        timestamp: item.timestamp,
        sender_id: item.sender_id
      }));

      setConversationData((prevData) => {
        const isDifferent = JSON.stringify(prevData) !== JSON.stringify(conversationData);
        return isDifferent ? conversationData : prevData;
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching conversation:", error);

      return null;
    }
  }, []);

  useEffect(() => {
    if (selectedUser) {
      getConversation(user.login_user_id, selectedUser.id);
      readMessage(user.login_user_id, selectedUser.id);
    }
  }, [selectedUser, user.login_user_id, getConversation, readMessage]);

  const handleUserClick = useCallback(
    (userId, userName, unseenMessages) => {
      const user = usersSearch.find((user) => user.name === userName);

      if (user) {
        setSelectedUser({ id: user.id, name: userName });
        getConversation(user.login_user_id, user.id);

        if (unseenMessages > 0) {
          setTimeout(() => {
            setTimeout(recentChats, 2000);
          }, 1000);
        }
      } else {
        console.error(`User with name ${userName} not found.`);
      }
    },
    [usersSearch, getConversation, recentChats]
  );

  const handleIncomingMessage = (payload) => {
    const appID = localStorage.getItem("Zino_app_id");

    if (payload.data.app_id === appID) {
      const senderid = payload.data.sender_id;
      const receiverid = payload.data.receiver_id;
      const type = payload.data.type;

      if (selectedUser && selectedUser.id && senderid === selectedUser.id) {
        if (type === "send_message") {
          readMessage(senderid, receiverid);
        }
      }

      recentChats();
    }
  };

  if (isZinoOrLocal(window.location.hostname)) {
    const msg = getMessaging();

    onMessage(msg, (payload) => {
      handleIncomingMessage(payload);
    });
  }

  useEffect(() => {
    latestMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationData]);

  return (
    <div className="chatbox_outer">
      <div className="chatbox_left_panel">
        <div className="search_bar">
          <div className="input_container">
            <span className="material-symbols-outlined">search</span>
            <input
              className="zino_inputBox chat_search"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search"
            />
          </div>
        </div>

        <div>
          {searchQuery === ""
            ? users.length > 0
              ? users
                  .filter((user) => user.counterpart_name.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map((user, index) => {
                    return (
                      <div
                        className={"chatbox_user_outer" + (selectedUser?.id === user.counterpart_id ? " active" : "")}
                        key={user.user_id + index.toString()}
                        onClick={() => handleUserClick(user.user_id, user.counterpart_name, user.unseen_messages)}
                      >
                        <div className="chatbox_user">
                          <span className="chat_username">
                            {user.counterpart_name.charAt(0).toUpperCase() + user.counterpart_name.slice(1)}
                          </span>
                          <span className="chat_user_time">{formatTimestamp(user.timestamp)}</span>
                          {user.unseen_messages > 0 && (
                            <div className="chatbox_badge">
                              {user.unseen_messages > 0 && (
                                <Badge badgeContent={user.unseen_messages} color="primary"></Badge>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="chatbox_user_text">
                          <span className="">
                            {user.message.sender_name}: {user.message.message}
                          </span>
                        </div>
                      </div>
                    );
                  })
              : null
            : usersSearch
                .filter((user) => user.name.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((user, index) => (
                  <div
                    className="chatbox_user_outer"
                    key={user.id + index.toString()}
                    onClick={() => handleUserClick(user.id, user.name)}
                  >
                    <div className="chatbox_user">
                      <span className="">{user.name.charAt(0).toUpperCase() + user.name.slice(1)}</span>
                    </div>
                    <div className="chatbox_user"></div>
                  </div>
                ))}
        </div>
      </div>

      {selectedUser ? (
        <div className="chat_window_outer">
          <div className="chat_user_name">
            <p>{selectedUser.name.charAt(0).toUpperCase() + selectedUser.name.slice(1)}</p>
          </div>
          <div className="chat_window" id="chat-window">
            {conversationData.map((message, index) => (
              <div
                className="chat_user_outer"
                key={index}
                style={{ textAlign: message.sender_id === user.login_user_id ? "right" : "left" }}
              >
                <div
                  className={message.sender_id === user.login_user_id ? "chat_user_himself" : "chat_user_sender"}
                  ref={index === conversationData.length - 1 ? latestMessageRef : null}
                >
                  <p
                    className="text-left"
                    style={{
                      color: message.sender_id === user.login_user_id ? "#ffffff" : "#000000"
                    }}
                  >
                    {message.message}
                  </p>
                  <p className="chat_sender_time">{formatTimestamp(message.timestamp)}</p>
                  <div
                    style={{ fontSize: "0.5em", color: "#888", position: "absolute", bottom: "2px", right: "2px" }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          <div className="chat_typeBox_outer">
            <input
              className="chat_typeBox"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message here"
              style={{
                flex: "1",
                border: "none",
                boxSizing: "border-box",
                outline: "none"
              }}
            />

            <span
              className="material-symbols-outlined send_button"
              style={{ cursor: "pointer", color: "blue", border: "none" }}
              onClick={() => {
                handleSendMessage();
              }}
            >
              send
            </span>
          </div>
        </div>
      ) : (
        <div className="chat_text_img_outer">
          <img className="chat_text_img" src={ChaImg} alt="chat message img" />
        </div>
      )}
    </div>
  );
};
export default Chat;

const formatTimestamp = (timestamp) => {
  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  const msInDay = 24 * 60 * 60 * 1000;
  const msInWeek = 7 * msInDay;
  const msInMonth = 30 * msInDay;

  const isSameDay =
    currentDate.getDate() === inputDate.getDate() &&
    currentDate.getMonth() === inputDate.getMonth() &&
    currentDate.getFullYear() === inputDate.getFullYear();

  const isYesterday =
    currentDate.getDate() - inputDate.getDate() === 1 &&
    currentDate.getMonth() === inputDate.getMonth() &&
    currentDate.getFullYear() === inputDate.getFullYear();

  const timeDifference = currentDate.getTime() - inputDate.getTime();

  if (timeDifference >= msInMonth) {
    const monthsAgo = Math.floor(timeDifference / msInMonth);
    return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
  } else if (timeDifference >= msInWeek) {
    const weeksAgo = Math.floor(timeDifference / msInWeek);
    return `${weeksAgo} week${weeksAgo > 1 ? "s" : ""} ago`;
  } else if (timeDifference >= msInDay) {
    return inputDate.toLocaleString("en-US", { weekday: "long", hour: "2-digit", minute: "2-digit" });
  } else if (isYesterday) {
    return `Yesterday, ${String(inputDate.getHours()).padStart(2, "0")}:${String(inputDate.getMinutes()).padStart(
      2,
      "0"
    )}`;
  } else if (isSameDay) {
    return `${String(inputDate.getHours()).padStart(2, "0")}:${String(inputDate.getMinutes()).padStart(2, "0")}`;
  } else {
    return inputDate.toDateString();
  }
};
