import React from "react";

import { PopupBoxSection } from "../../lib";
import { MASTER_DATA_TABS } from "./Constants";
import { CloseIconSvg } from "../../lib/zinoIcon";

const NO_DATA = "No data found!";

const MasterDataPopup = ({ data, type, showMasterDat, setShowMasterData, title }) => {
  let tableHeading = null;
  let tableBody = null;

  if (type === MASTER_DATA_TABS.learningText) {
    tableHeading = null;
    tableBody = data
      ? data.map((value, index) => (
          <tr key={value + index.toString()} style={{ alignItems: "center" }}>
            <td style={{ display: "flex", alignItems: "center", height: "48px" }}>
              <span>{value}</span>
            </td>
          </tr>
        ))
      : NO_DATA;
  } else if (type === MASTER_DATA_TABS.keyValue) {
    tableHeading = (
      <tr>
        <th style={{ textAlign: "left" }}>Key</th>
        <th>Value</th>
      </tr>
    );

    tableBody = data
      ? data.map((valueArray, index) => (
          <tr key={index}>
            {valueArray.map((value, index) => (
              <td key={value + index.toString()}>
                <div style={{ display: "flex", alignItems: "center", height: "48px" }}>
                  <span>{value}</span>
                </div>
              </td>
            ))}
          </tr>
        ))
      : NO_DATA;
  } else if (type === MASTER_DATA_TABS.custom) {
    let tableRows = [];

    if (data) {
      tableHeading = (
        <tr>
          {data[0].map((header, i) => (
            <th key={header + i.toString()}> {header}</th>
          ))}
        </tr>
      );

      for (let i = 1; i < data.length; i++) {
        const eachRow = (
          <tr key={i.toString()}>
            {data[i]?.map((value, index) => (
              <td key={value + index.toString()}>{value}</td>
            ))}
          </tr>
        );

        tableRows.push(eachRow);
      }
    }

    tableBody = tableRows.length > 0 ? tableRows : NO_DATA;
  }

  return (
    <PopupBoxSection showPopup={showMasterDat} className="slide-left-to-right">
      <div className="popupSection_header">
        <span className="popupSection_header_closeBTN" onClick={() => setShowMasterData(false)}>
          <CloseIconSvg />
        </span>
        <span className="popupSection_header_display"></span>
      </div>
      <div style={{ width: "50vw", maxHeight: "70vh", overflowY: "auto" }}>
        <h3 style={{ paddingLeft: "20px", marginTop: "20px " }}>{title}</h3>

        <div style={{ margin: "20px", maxHeight: "40vh" }}>
          {type === MASTER_DATA_TABS.grid || type === MASTER_DATA_TABS.colorConfig ? (
            <pre> {JSON.stringify(data, 2).replaceAll("\n", "<br/>")} </pre>
          ) : (
            <table className="recordview_table">
              <thead style={{ textAlign: "left" }}>{tableHeading}</thead>
              <tbody>{tableBody}</tbody>
            </table>
          )}
        </div>
      </div>
    </PopupBoxSection>
  );
};

export default MasterDataPopup;
