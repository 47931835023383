import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { commonFunction } from "../utils/commonFunction";
import { FSIconRenderer } from "../../zinoIcon";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import { form_images_store } from "../utils/store";

const FFFileField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [imageDataArray, setImageDataArray] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setImageDataArray(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []);
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  let styleObj = {};
  if (errors.length > 0) {
    styleObj["border"] = "1px solid red";
    styleObj["color"] = "red";
  }
  if (component.disable) {
    styleObj["pointerEvents"] = "none";
    styleObj["opacity"] = "0.4";
  }

  const onSelectFile = async (e) => {
    if (component.disable) {
      return;
    }

    let files = e.target.files;
    files = Object.values(files);

    const imagesArray = [...imageDataArray];

    // loop through selected files and add each image to the imagesArray
    let ifErrorIs = [];
    for (let i = 0; i < files.length; i++) {
      let selectedFile = files[i];
      let errorM = "";

      if (
        component.fileMaxSize * 1024 * 1024 < selectedFile.size ||
        component.fileMinSize * 1024 * 1024 > selectedFile.size
      ) {
        ifErrorIs.push(`File size should be between ${component.fileMinSize}MB to ${component.fileMaxSize}MB`);
        errorM = `File size should be between ${component.fileMinSize}MB to ${component.fileMaxSize}MB`;
      }
      imagesArray.push({
        url: "true",
        file: selectedFile,
        error: errorM,
        file_name: selectedFile.name,
        file_id: currentConfigKey + Date.now() + selectedFile.name
      });
    }

    setImageDataArray(imagesArray);

    if (component.fileMaxRange < imagesArray.length) {
      ifErrorIs.push(`Only ${component.fileMaxRange} files allowed`);
    } else if (component.fileMinRange > imagesArray.length) {
      if (component.validate.required) {
        ifErrorIs.push(`Select minimum ${component.fileMinRange} files`);
      }
    }
    let dataObject = {
      value: imagesArray,
      errors: ifErrorIs
    };
    validate(dataObject);
  };

  const deleteIconFromPreviewArray = (index) => {
    let imageDataArr = [...imageDataArray];

    imageDataArr.splice(index, 1);

    let ifErrorIs = [];
    if (component.fileMaxRange < imageDataArr.length) {
      ifErrorIs.push(`Only ${component.fileMaxRange} files allowed`);
    } else if (component.fileMinRange > imageDataArr.length) {
      if (component.validate.required) {
        // if (component.validate.required || (component.fileMinRange > 1 && imageDataArr.length === 0)) {
        ifErrorIs.push(`Select minimum ${component.fileMinRange} files`);
      }
    }

    let dataObject = {
      value: imageDataArr,
      errors: ifErrorIs
    };
    setImageDataArray(imageDataArr);
    validate(dataObject);
  };

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <div className="form_inputBox ff_file_field_outerBox text_nocopy" style={styleObj}>
        {component.fileMaxRange <= imageDataArray?.length ? (
          ""
        ) : (
          <div className="ff_file_field_box" id="uploadimagesBox">
            {/* <div className="icon">
              <svg
                fill={errors.length > 0 ? "red" : "#000000"}
                height="48px"
                width="48px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.001 512.001"
              >
                <g>
                  <g>
                    <path d="M344.381,143.771C254.765,56.017,102.37,103.776,79.825,227.7c-31.849,4.598-59.138,25.445-72.018,55.076 c-0.016,0.035-0.032,0.07-0.047,0.107c-26.687,61.602,18.784,130.232,85.51,130.232h282.267 c75.246,0,136.463-61.216,136.463-136.462C512,189.241,430.314,123.682,344.381,143.771z M375.537,381.12H93.271 c-69.246,0-84.534-98.263-18.714-119.456c14.753-4.65,43.01-7.348,74.38,21.892c6.464,6.024,16.586,5.667,22.61-0.794 c6.024-6.464,5.668-16.586-0.794-22.61c-17.93-16.712-38.071-27.33-58.484-31.453c22.034-99.077,147.374-131.851,215.247-56.305 c4.189,4.661,10.714,6.451,16.693,4.57c67.272-21.117,135.795,29.374,135.795,99.69 C480.005,334.256,433.141,381.12,375.537,381.12z" />
                  </g>
                </g>
              </svg>
            </div> */}
            {/* <header>Drag &#38; Drop to Upload File</header>
            <span>OR</span> */}

            <input
              type="file"
              className="ff_file_field_button"
              multiple={component.multiple || false}
              onChange={onSelectFile}
              disabled={component.disable}
              accept={component.type === "image" ? "image/*" : ""}
            />
          </div>
        )}

        {imageDataArray?.length > 0 && (
          <div className="ff_file_field_outerBox image_uploaded">
            <div className="ff_file_field_imageOBox">
              {component.type === "image" ? (
                imageDataArray.map((preview, index) => {
                  return (
                    <div key={`${component.id}_${index}_${component.type}_preview`} className="ff_file_field_imageBox">
                      {/* <span
                        style={{ cursor: "pointer" }}
                        className="ff_file_field_imageDIcon"
                        onClick={() => deleteIconFromPreviewArray(index)}
                      >
                        <FSIconRenderer icon={"delete"} marginR="0" iconColor={"red"} />
                      </span> */}
                      <button
                        className="app_action_btn file_img_delete"
                        onClick={() => deleteIconFromPreviewArray(index)}
                      >
                        <span className="material-symbols-outlined app_action_btn delete">delete</span>
                      </button>
                      {preview.url === "true" ? (
                        <ReadFile file={preview.file} dataObject={preview} />
                      ) : preview.url ? (
                        <img src={preview.url} alt="" />
                      ) : preview.name ? (
                        <img src={`${getApiCallLocalPath()}api/v1/assets/fileName/${preview.name}`} alt="" />
                      ) : (
                        ""
                      )}
                      {preview.error && <span className="ff_file_field_imageErrorM">{preview.error}</span>}
                    </div>
                  );
                })
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody style={{ width: "40vw" }}>
                    {imageDataArray.map((preview, index) => {
                      if (preview.url === "true" && !form_images_store[preview.file_id]) {
                        uploadFile(preview.file, preview.file_id);
                      }
                      return (
                        <tr key={`${component.id}_${index}_${component.type}_preview`}>
                          <td>
                            <FSIconRenderer icon={"description"} />
                          </td>
                          <td
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: "160px"
                            }}
                            title={preview.file_name || preview.name || ""}
                          >
                            {preview.file_name || preview.name || ""}
                          </td>
                          <td>
                            {/* <span style={{ cursor: "pointer" }} onClick={() => deleteIconFromPreviewArray(index)}>
                              <FSIconRenderer icon={"delete"} marginR="0" iconColor={"red"} />
                            </span> */}
                            <button
                              style={{ marginLeft: "8px" }}
                              className="app_action_btn"
                              onClick={() => deleteIconFromPreviewArray(index)}
                            >
                              <span className="material-symbols-outlined app_action_btn delete">delete</span>
                            </button>
                          </td>
                          <td>
                            {preview.error && (
                              <FSIconRenderer icon={"info"} iconColor={"red"} iconTitle={preview.error} />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  {/* <div>
                          <FSIconRenderer icon={"description"} />
                          <span
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: "160px"
                            }}
                          >
                            {preview.file_name}
                          </span>
                          <span style={{ cursor: "pointer" }} onClick={() => deleteIconFromPreviewArray(index)}>
                            <FSIconRenderer icon={"delete"} marginR="0" iconColor={"red"}  />
                          </span>
                        </div>
                        {preview.error && <span className="ff_file_field_imageErrorM">{preview.error}</span>} */}
                </table>
              )}
            </div>
          </div>
        )}
        <span className="file_label">{component.label}</span>
      </div>
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFFileField;

const ReadFile = ({ file, dataObject }) => {
  const [previewURL, setPreviewURL] = useState("");

  if (file?.name) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      form_images_store[dataObject.file_id] = reader.result;
      setPreviewURL(reader.result);
    };

    return <img src={previewURL} alt="" />;
  } else {
    if (form_images_store[dataObject.file_id]) {
      return <img src={form_images_store[dataObject.file_id]} alt="" />;
    }
    return <img src={""} alt="" />;
  }
};

const uploadFile = (file, id) => {
  if (!file.name) return;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = async () => {
    form_images_store[id] = reader.result;
  };
};
