import React from "react";
import { FSIconRenderer } from "../zinoIcon";

const NumberFormater = ({ number, type }) => {
  if (number) {
    number = number.toString();
  }
  if (type === "dollar") {
    if (!number) {
      number = "0.00";
    } else {
      let amount = ".00";
      if (number.includes(".")) {
        amount = "." + number.split(".")[1];
        number = number.split(".")[0];
      }

      let count = 0;
      for (let index = number.length - 1; index >= 0; index--) {
        const element = number[index];
        amount = element + amount;
        ++count;
        if (index !== 0 && count === 3) {
          amount = "," + amount;
          count = 0;
        }
      }

      number = amount;
    }

    return (
      <>
        <FSIconRenderer icon={"attach_money"} marginR="0" /> <span className="card_Text_label">{number}</span>
      </>
    );
  } else if (type === "rupee") {
    if (!number) {
      number = "0.00";
    } else {
      let amount = ".00";
      if (number.includes(".")) {
        let dotVal = number.split(".")[1];
        if (dotVal.length > 2) {
          dotVal = dotVal.substring(0, 2);
        } else if (dotVal.length === 1) {
          dotVal = dotVal + "0";
        }
        amount = "." + dotVal;
        number = number.split(".")[0];
      }

      let count = 0;
      let flag = true;
      for (let index = number.length - 1; index >= 0; index--) {
        const element = number[index];
        amount = element + amount;
        ++count;

        if (flag) {
          if (index !== 0 && count === 3) {
            amount = "," + amount;
            count = 0;
            flag = false;
          }
        } else {
          if (index !== 0 && count === 2) {
            amount = "," + amount;
            count = 0;
          }
        }
      }
      number = amount;
    }
    return (
      <>
        <FSIconRenderer icon={"currency_rupee"} marginR="0" /> <span className="card_Text_label">{number}</span>
      </>
    );
  } else if (type === "percentage") {
    return (
      <>
        <span className="card_Text_label">{number}</span> <FSIconRenderer icon={"percent"} marginR="0" />
      </>
    );
  }
  return (
    <>
      {" "}
      <span className="card_Text_label">{number}</span>{" "}
    </>
  );
};

export default NumberFormater;
