import React from "react";

import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { useImagePreviewContext } from "../../applicationcontext/ImagePreviewContext";
import { convertNumbetToDate, convertNumbetToTime, stringToJSONChange } from "../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../utils/updateConfigFunction";
import DetailsViewItem from "./DetailsViewItem";
import { gridCommonFunction } from "../utils/gridCommonFunction";
import IconDecider from "../../utils/IconDecider";

const RecordsetRenderer = ({ itemConfig, detailsViewData }) => {
  const { showPopupImagePreview } = useImagePreviewContext();

  let configlayouts = itemConfig?.config?.layouts || [];
  if (!itemConfig?.table_format && configlayouts.length > 0) {
    let dataIs = [];
    getDataFromDataObjectByKey(detailsViewData, dataIs, itemConfig?.id || "");
    let dataObjectIs = dataIs[0];
    if (!dataObjectIs || dataObjectIs.length === 0) return;

    let class_name = "";
    if (itemConfig.className) {
      if (typeof itemConfig.className === "object") {
        class_name = Object.values(itemConfig.className).join(" ");
      } else {
        class_name = itemConfig.className;
      }
    }
    if (!Array.isArray(dataObjectIs)) {
      dataObjectIs = [dataObjectIs];
    }

    let layouts = itemConfig?.config?.layouts || [];
    for (let index = 0; index < layouts.length; index++) {
      const element = layouts[index];
      let element_config = element.element_config;
      element_config["id_is"] = element_config["id"];

      let id = element_config["id"];
      id = id.replaceAll(`${itemConfig?.id}.`, "");
      element_config["id"] = id;
      element_config["data_key"] = id;
    }

    if (layouts.length === 0) {
      return <></>;
    }

    return (
      <div
        className="details_block_outer"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${itemConfig.cards_per_row || "1"}, 1fr)`,
          gap: itemConfig?.cards_per_row > 1 ? "8px" : "0"
        }}
      >
        {dataObjectIs.map((dataObject, numberIsInd) => {
          return (
            <div
              className={`${class_name} ${itemConfig?.custom_classname || ""}`}
              key={numberIsInd}
              id={`${itemConfig?.custom_id || ""}`}
              style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
            >
              <DetailsViewItem
                itemConfig={layouts}
                activityCardsConfig={{}}
                detailsViewDataFormData={dataObject || {}}
                detailsViewDataTimeline={[]}
                detailsViewDataActivityCard={[]}
                fieldLabelFlag={false}
                detailsViewItemConfig={itemConfig}
              />
            </div>
          );
        })}
      </div>
    );
  } else {
    let dataIs = [];
    getDataFromDataObjectByKey(detailsViewData, dataIs, itemConfig?.id || "");
    let dataObjectIs = dataIs[0];
    if (!dataObjectIs || dataObjectIs.length === 0) return;

    let class_name = "";
    if (itemConfig.className) {
      if (typeof itemConfig.className === "object") {
        class_name = Object.values(itemConfig.className).join(" ");
      } else {
        class_name = itemConfig.className;
      }
    }
    let layouts = itemConfig?.config?.layouts || [];
    let layoutsArray = [];

    if (layouts.length > 0) {
      layouts = gridCommonFunction.sortByPosition(layouts);
      for (let index = 0; index < layouts.length; index++) {
        const element = layouts[index];
        if (element?.element_config) {
          layoutsArray.push(element?.element_config);
        }
      }
    }

    if (layoutsArray.length === 0) {
      layoutsArray = itemConfig?.config?.columns || [];
    }

    if (!Array.isArray(dataObjectIs)) {
      dataObjectIs = [dataObjectIs];
    }
    let table_class_name = "";

    if (itemConfig.table_type) {
      if (table_class_name) table_class_name += " ";
      table_class_name += "details_view_table_" + itemConfig.table_type;
    }
    return (
      <div className="details_block_outer">
        <table
          className={`recordset_renderer_table ${class_name} ${table_class_name} ${itemConfig?.custom_classname || ""}`}
          id={`${itemConfig?.custom_id || ""}`}
          style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
        >
          <thead>
            <tr>
              {itemConfig?.show_serial_number && <th></th>}
              {layoutsArray.map((colItem, colItemIndexIs) => {
                return <th key={colItem.id + colItemIndexIs}>{colItem.display}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {dataObjectIs.map((dataObject, numberIsInd) => (
              <tr key={numberIsInd}>
                {itemConfig?.show_serial_number && <td>{numberIsInd + 1}</td>}
                {layoutsArray.map((colItem, colItemIndexIs) => {
                  let idIS = colItem.id.split(".");

                  let renderData = dataObject;
                  for (let index = 1; index < idIS.length; index++) {
                    const element = idIS[index];
                    if (renderData[element]) {
                      renderData = renderData[element];
                    } else {
                      renderData = "";
                      break;
                    }
                  }

                  idIS = idIS[idIS.length - 1];
                  if (colItem.type === "phone") {
                    const phoneInfo = renderData;
                    if (phoneInfo?.phone) {
                      return <td key={idIS + colItemIndexIs}>{"+" + phoneInfo?.dial_code + " " + phoneInfo?.phone}</td>;
                    } else {
                      return <td key={idIS + colItemIndexIs}></td>;
                    }
                  }

                  if (["textfield", "number", "text", "select", "string"].includes(colItem.data_type)) {
                    return <td key={idIS + colItemIndexIs}>{renderData}</td>;
                  } else if (["textarea"].includes(colItem.data_type)) {
                    return (
                      <td
                        key={idIS + colItemIndexIs}
                        dangerouslySetInnerHTML={{
                          __html: renderData?.replaceAll(/\n/g, "<br/>")
                        }}
                      ></td>
                    );
                  } else if (["object", "array"].includes(colItem.data_type)) {
                    return <td key={idIS + colItemIndexIs}>{JSON.stringify(renderData)}</td>;
                  } else if (["radio"].includes(colItem.field_type)) {
                    return <td key={idIS + colItemIndexIs}>{JSON.stringify(renderData)}</td>;
                  } else if (["geolocation", "selectboxes"].includes(colItem.data_type)) {
                    return <td key={idIS + colItemIndexIs}>{renderData.join(", ")}</td>;
                  } else if (["boolean"].includes(colItem.data_type)) {
                    return <td key={idIS + colItemIndexIs}>{renderData.join(", ")}</td>;
                  } else if (["date", "day", "datetime"].includes(colItem.data_type)) {
                    if (renderData) {
                      let formattedDate = "";
                      let dateItem = renderData;

                      if (colItem.field_type === "time") {
                        formattedDate += convertNumbetToTime(dateItem);
                      } else {
                        formattedDate += convertNumbetToDate(dateItem, colItem.field_type, "string", [
                          "dd",
                          "mm",
                          "yyyy"
                        ]);
                      }

                      return <td key={idIS + colItemIndexIs}>{formattedDate}</td>;
                    } else {
                      return <td key={idIS + colItemIndexIs}></td>;
                    }
                  } else if (["image", "file"].includes(colItem.data_type)) {
                    let imageFile = renderData;
                    return (
                      <td key={idIS + colItemIndexIs}>
                        <div style={{ display: "flex", justifyContent: "center", gap: "10px", flexWrap: "wrap" }}>
                          {imageFile?.map((image_src, image_index) => {
                            if (typeof image_src === "object") {
                              if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
                                return (
                                  <img
                                    key={image_index}
                                    style={{ height: "auto", width: "40px" }}
                                    src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                                    alt={image_src}
                                    onClick={() => showPopupImagePreview(image_src)}
                                  />
                                );
                              } else if (image_src.url) {
                                return undefined;
                              } else {
                                return (
                                  <a
                                    key={image_index}
                                    rel="noreferrer"
                                    href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                                    target="_blank"
                                  >
                                    <IconDecider fileName={image_src.name} />
                                  </a>
                                );
                              }
                            } else {
                              const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
                              const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
                              const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");

                              if (
                                endWithPNGPattern.test(image_src) ||
                                endWithJPGPattern.test(image_src) ||
                                endWithJPEGPattern.test(image_src)
                              ) {
                                return (
                                  <img
                                    key={image_index}
                                    style={{ height: "40px", width: "40px" }}
                                    src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                                    alt={image_src}
                                  />
                                );
                              } else {
                                return (
                                  <a
                                    key={image_index}
                                    rel="noreferrer"
                                    href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                                    target="_blank"
                                  >
                                    <IconDecider fileName={image_src.name} />
                                  </a>
                                );
                              }
                            }
                          })}
                        </div>
                      </td>
                    );
                  } else if (["video"].includes(colItem.data_type)) {
                    return (
                      <td key={idIS + colItemIndexIs} style={{ aspectRatio: "16/9", height: "auto" }}>
                        <iframe
                          style={{
                            height: "100%",
                            width: "100%",
                            border: "none",
                            outline: "none"
                          }}
                          src={renderData}
                          title={colItem.display}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </td>
                    );
                  } else if (["empty"].includes(colItem.type)) {
                    return <td key={idIS + colItemIndexIs}></td>;
                  } else {
                    return <td key={idIS + colItemIndexIs}></td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default RecordsetRenderer;
