import React, { useState, useRef, useEffect } from "react";

const ApplearingText = ({ options, handleChange, label, valueObject, filterOptions, disabled, required }) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setisFocus] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const inputRef = useRef(null);
  const selectValueRef = useRef(null);
  const [optionClick, setOptionClick] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectValueRef.current && selectValueRef.current.contains(event.target)) {
        setisFocus(true);
      } else {
        setisFocus(false);
      }

      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setQuery(valueObject.value || "");
  }, [valueObject]);

  const selectOption = (option) => {
    handleChange(option);
    setIsOpen(false); // Toggle isOpen state
  };

  const handleBlur = (option) => {
    if (!optionClick && option !== query) {
      handleChange(option);
    }
    setOptionClick(false);
    setisFocus(false);
  };

  const handleClick = (option) => {
    setOptionClick(true);
    setIsSearch(false);
    selectOption(option);
  };

  const filteredOptions = filterOptions ? filterOptions(options, { inputValue: query }) : options;

  const handleKeyPress = (event) => {
    if (event.key === "ArrowDown") {
      if (isSearch) {
        if (selectedIndex < filteredOptions.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        }
      } else {
        if (selectedIndex < options.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        }
      }
    } else if (event.key === "ArrowUp") {
      if (selectedIndex >= 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
    if (event.key === "Enter" && selectedIndex > -1) {
      handleClick(filteredOptions?.[selectedIndex] || "");
      setSelectedIndex(-1);
    }
  };

  return (
    <div className="searchable_dropdown" style={{ width: "100%" }}>
      <div className="control" ref={inputRef} onKeyDown={handleKeyPress}>
        <div className="selected-value" ref={selectValueRef}>
          {disabled ? (
            <input
              type="text"
              value={query || ""}
              name="searchTerm"
              disabled={true}
              className={`did-floating-select ${isOpen || valueObject.value ? "has-value" : ""} ${
                isFocus ? "has-focus" : ""
              }`}
            />
          ) : (
            <input
              type="text"
              value={query || ""}
              name="searchTerm"
              // autoFocus
              onClick={() => setIsOpen(true)}
              onChange={(e) => {
                setQuery(e.target.value);
                setIsSearch(true);
                setOptionClick(false);
                setisFocus(true);
                setIsOpen(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Tab" || e.key === "Enter") {
                  selectOption(e.target.value);
                }
              }}
              onBlur={({ target: { value } }) => handleBlur(value)}
              className={`did-floating-select ${isOpen || valueObject.value ? "has-value" : ""} ${
                isFocus ? "has-focus" : ""
              }`}
              autoComplete="off"
              onFocus={() => {
                setisFocus(true);
                setIsOpen(true);
              }}
            />
          )}
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
        <div className={`options ${isOpen ? "open" : ""}`}>
          {(isSearch ? filteredOptions : options).map((option, index) => {
            return (
              <div
                key={index}
                onClick={(e) => {
                  handleClick(option);
                }}
                className={`option ${option === valueObject.value || selectedIndex === index ? "selected" : ""}`}
              >
                {option}
              </div>
            );
          })}
        </div>
      </div>
      <label
        className={`did-floating-label ${isOpen || valueObject.value ? "has-value" : ""}  ${
          isFocus ? "has-focus" : ""
        }`}
      >
        {label} {required && " *"}
      </label>
    </div>
  );
};

export { ApplearingText };

// import React, { useState, useRef, useEffect } from "react";

// const ApplearingText = ({ options, handleChange, label, value, filterOptions, disabled, required }) => {
//   const [query, setQuery] = useState("");
//   const [isOpen, setIsOpen] = useState(false);
//   const [isFocus, setisFocus] = useState(false);
//   const [isSearch, setIsSearch] = useState(false);
//   const inputRef = useRef(null);
//   const selectValueRef = useRef(null);
//   const [optionClick, setOptionClick] = useState(false);

// useEffect(() => {
//   const handleClickOutside = (event) => {
//     if (inputRef.current && !inputRef.current.contains(event.target)) {
//       setIsOpen(false);
//     } else {
//       setIsOpen(true);
//     }
//   };
//   document.addEventListener("click", handleClickOutside);
//   return () => {
//     document.removeEventListener("click", handleClickOutside);
//   };
// }, []);
// useEffect(() => {
//   const handleClickOutside = (event) => {
//     if (inputRef.current && !inputRef.current.contains(event.target)) {
//       setIsOpen(false);
//     } else {
//       setIsOpen(true);
//     }
//   };

// const inputRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (selectValueRef.current && selectValueRef.current.contains(event.target)) {
//         setisFocus(true);
//       } else {
//         setisFocus(false);
//       }

//       if (inputRef.current && !inputRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };
//     document.addEventListener("click", handleClickOutside);
//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     setQuery(value || "");
//   }, [value]);

//   const selectOption = (option) => {
//     handleChange(option);
//     setIsOpen(false); // Toggle isOpen state
//   };

//   const handleBlur = (option) => {
//     if (optionClick) {
//       setOptionClick(false);
//     } else {
//       handleChange(option);
//       setOptionClick(false);
//     }
//   };

//   const handleClick = (option) => {
//     setOptionClick(true);
//     setIsSearch(false);
//     selectOption(option);
//   };

//   const filteredOptions = filterOptions ? filterOptions(options, { inputValue: query }) : options;

//   return (
//     <div className="searchable_dropdown" style={{ width: "100%" }}>
//       <div className="control" ref={inputRef}>
//         <div className="selected-value" ref={selectValueRef}>
//           {disabled ? (
//             <input
//               type="text"
//               value={query || ""}
//               name="searchTerm"
//               disabled={true}
//               className={`did-floating-select ${isOpen || value ? "has-value" : ""} ${isFocus ? "has-focus" : ""}`}
//             />
//           ) : (
//             <input
//               type="text"
//               value={query || ""}
//               name="searchTerm"
//               // autoFocus
//               onClick={() => setIsOpen(true)}
//               onChange={(e) => {
//                 setQuery(e.target.value);
//                 setIsSearch(true);
//                 setOptionClick(false);
//               }}
//               onKeyDown={(e) => {
//                 if (e.key === "Tab" || e.key === "Enter") {
//                   selectOption(e.target.value);
//                 }
//               }}
//               onBlur={({ target: { value } }) => handleBlur(value)}
//               className={`did-floating-select ${isOpen || value ? "has-value" : ""} ${isFocus ? "has-focus" : ""}`}
//               autoComplete="off"
//             />
//           )}
//         </div>
//         <div className={`arrow ${isOpen ? "open" : ""}`}></div>
//         <div className={`options ${isOpen ? "open" : ""}`}>
//           {(isSearch ? filteredOptions : options).map((option, index) => {
//             return (
//               <div
//                 key={index}
//                 onClick={(e) => {
//                   handleClick(option);
//                 }}
//                 className={`option ${option === value ? "selected" : ""}`}
//               >
//                 {option}
//               </div>
//             );
//           })}
//         </div>
//       </div>
//       <label className={`did-floating-label ${isOpen || value ? "has-value" : ""}  ${isFocus ? "has-focus" : ""}`}>
//         {label} {required && " *"}
//       </label>
//     </div>
//   );
// };

// export { ApplearingText };
