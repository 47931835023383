import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { commonFunction } from "../utils/commonFunction";
import { AppToggleBtn } from "../html/HtmlInput";

const FFToggleField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || false
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || false);
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  return (
    <div className={`form_field_outer_box toggle_switchBtn_wrap ${component.disable ? "disabled" : ""}`}>
      <div className="toggle_switchBtn_inline">
        <AppToggleBtn
          id={component.id}
          checked={inputValue}
          onClick={() => validate(!inputValue)}
          required={component.validate.required}
          type="toggle"
          error={errors.length > 0}
          label={component.label}
          disabled={component.disable}
        />
        <span className="form_check_label mb_none">
          {component.label} {component?.validate?.required && " *"}
        </span>
      </div>

      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFToggleField;
