import React, { useEffect, useState } from "react";

import { checkDataUniqueOrNot, getDataById, validateGridCollItem } from "../utils/storeFunction";
import { commonFunction } from "../../utils/commonFunction";
import { convertNumbetToDate } from "../../../utils/commonFunction";
import { FSIconRenderer } from "../../../zinoIcon";

const FGTextField = ({
  namespace,
  gridCollItem,
  currentConfigKey,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  const [inputOption, setInputOption] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || ""
  );
  const [inputOptionError, setInputOptionError] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []
  );
  const [input, setInput] = useState(getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || "");
  let field_type = gridCollItem.type;
  if (field_type === "textfield") {
    field_type = "text";
  } else if (field_type === "datetime") {
    field_type = "datetime-local";
  }

  const optionKeydownFunction = (event) => {
    if (event.type === "blur") {
      validate(event, false, event.target.value);
    } else if (event.type === "keydown") {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
        case "Tab":
        case "ArrowLeft":
        case "ArrowRight":
          validate(event, false, event.target.value);
          break;
        case "Enter":
          validate(event, true, event.target.value);
          break;

        default:
          break;
      }
    }
    commonFunctionObject.keyboardKeydownFun(event);
  };

  const validate = async (e, addNewRowFlag, value) => {
    if (gridCollItem.unique) {
      const uniqueDataFlag = checkDataUniqueOrNot(
        stateObject.gridComponentFormElements,
        gridCollItem.id,
        value || "",
        gridFlagStateObject.gridRowIndex
      );
      if (uniqueDataFlag) {
        value = "";
      }
    }
    setInputOption(value);

    await validateGridCollItem(
      e,
      namespace,
      currentConfigKey,
      stateObject,
      value || "",
      gridCollItem,
      commonFunction,
      gridFlagStateObject,
      addNewRowFlag,
      stateFunctionObject,
      commonFunctionObject,
      false
    );
  };

  useEffect(() => {
    if (stateObject.gridComponentFormElements) {
      setInputOption(getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || "");
      setInput(getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElements]);

  useEffect(() => {
    setInputOptionError(getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElementsError]);

  if (stateObject.gridFocusedElement === currentConfigKey) {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 200}px`,
          width: `${gridCollItem?.width || 200}px`,
          maxWidth: `${gridCollItem?.width || 200}px`
        }}
      >
        <input
          type={field_type}
          id={currentConfigKey}
          className={"formGrid_coll_item_input_focus"}
          onKeyDown={optionKeydownFunction}
          onBlur={optionKeydownFunction}
          disabled={gridCollItem.disable}
          autoComplete="off"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
        />
      </div>
    );
  } else {
    let fieldType = "text";
    if (["password", "datetime-local"].includes(field_type)) {
      if (inputOption) {
        fieldType = field_type;
      }
    }

    let value = inputOption;

    if (gridCollItem.type === "date") {
      value = dateFormatter(inputOption);
    }

    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 200}px`,
          width: `${gridCollItem?.width || 200}px`,
          maxWidth: `${gridCollItem?.width || 200}px`
        }}
      >
        <input
          type={fieldType}
          id={currentConfigKey}
          className={"formGrid_coll_item_input"}
          onFocus={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          onClick={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          onChange={() => {}}
          value={value}
          title={value}
          readOnly={true}
          disabled={gridCollItem.disable}
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
        />
        {inputOptionError.length > 0 && (
          <span className="formGrid_coll_item_input_icon">
            <FSIconRenderer icon={"error"} iconColor={"red"} marginR="0" iconTitle={inputOptionError.join(" , ")} />
          </span>
        )}
      </div>
    );
  }
};

export default FGTextField;

const dateFormatter = (date) => {
  if (!date) {
    return "";
  }

  const formattedDate = convertNumbetToDate(date, "date", "string", ["dd", "mm", "yyyy"]);

  return formattedDate;
};
