import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import KanbanCard from "./KanbanCard";

function TaskCard({ task, isDropableFlag = true, layout, namespace, sm_id }) {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task
    }
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };

  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={{
          ...style,
          display: "flex",
          position: "relative",
          padding: "0.625rem",
          alignItems: "center",
          borderRadius: "0.75rem",
          borderWidth: "2px",
          textAlign: "left",
          cursor: "grab",
          opacity: "0.3",
          height: "100px"
        }}
      />
    );
  }
  const styleDivIs = {
    // display: "flex",
    // position: "relative",
    // padding: "0.2rem",
    // alignItems: "center",
    // borderRadius: "0.25rem",
    // textAlign: "left",
    cursor: "grab"
    // height: "100px",
    // background: "#DADCE0"
  };

  if (isDropableFlag) {
    return (
      <div
        ref={setNodeRef}
        style={{
          ...style,
          ...styleDivIs
        }}
        {...attributes}
        {...listeners}
      >
        <KanbanCard layout={layout} layoutData={task} namespace={namespace} sm_id={sm_id} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          ...style,
          ...styleDivIs
        }}
      >
        <KanbanCard layout={layout} layoutData={task} namespace={namespace} sm_id={sm_id} />
      </div>
    );
  }
}

export default TaskCard;
