import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { commonFunction } from "../utils/commonFunction";

const FFSelectChipsField = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || []
  );

  function removeEmptyLabel(objects) {
    return objects.filter((obj) => obj.label.trim() !== "");
  }

  let options = removeEmptyLabel([...component.data.values]);

  if (component?.sort) {
    options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []);
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  const filterFun = (inputValue, value) => {
    if (!inputValue.includes(value)) {
      return [...inputValue, value];
    } else {
      return inputValue.filter((item) => item !== value);
    }
  };
  const handleClick = (value) => {
    if (component.disable) {
      return;
    }
    let newData = JSON.parse(JSON.stringify(filterFun(inputValue, value)));
    setInputValue(newData);
    validate(newData);
  };

  // useEffect(() => {
  //   validate(inputValue);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inputValue]);

  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
  };

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <label className="form_inputBox d_flex_column">
        <span className="app_label mb_8">
          {component.label}
          {component.validate.required && " *"}
        </span>
        <div className="select_chip_box_wrap">
          {options.map((option, index) => {
            let class_name = "select-chips-box";
            if (inputValue.includes(option.value)) {
              class_name += " selected-chips-box";
            }
            return (
              <span key={index} className={class_name} onClick={() => handleClick(option.value)}>
                {option.label}
              </span>
            );
          })}
        </div>
      </label>

      {errors.length > 0 && (
        <div className="invalid_feedback">
          {errors.map((error, index) => (
            <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default FFSelectChipsField;
