const FileDownloadIconSvg = ({ width = 20, height = 20, fill = "#667085" }) => {
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.1198 22 8.79986 22H12.5M14 11H8M10 15H8M16 7H8M15 19L18 22M18 22L21 19M18 22V16"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const UserIconSvg = ({ width = 26, height = 26, fill = "#667085" }) => {
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M15.9997 15.9987C14.533 15.9987 13.2775 15.4765 12.233 14.432C11.1886 13.3876 10.6663 12.132 10.6663 10.6654C10.6663 9.1987 11.1886 7.94314 12.233 6.8987C13.2775 5.85425 14.533 5.33203 15.9997 5.33203C17.4663 5.33203 18.7219 5.85425 19.7663 6.8987C20.8108 7.94314 21.333 9.1987 21.333 10.6654C21.333 12.132 20.8108 13.3876 19.7663 14.432C18.7219 15.4765 17.4663 15.9987 15.9997 15.9987ZM5.33301 26.6654V22.932C5.33301 22.1765 5.52745 21.482 5.91634 20.8487C6.30523 20.2154 6.8219 19.732 7.46634 19.3987C8.84412 18.7098 10.2441 18.1931 11.6663 17.8487C13.0886 17.5043 14.533 17.332 15.9997 17.332C17.4663 17.332 18.9108 17.5043 20.333 17.8487C21.7552 18.1931 23.1552 18.7098 24.533 19.3987C25.1775 19.732 25.6941 20.2154 26.083 20.8487C26.4719 21.482 26.6663 22.1765 26.6663 22.932V26.6654H5.33301ZM7.99967 23.9987H23.9997V22.932C23.9997 22.6876 23.9386 22.4654 23.8163 22.2654C23.6941 22.0654 23.533 21.9098 23.333 21.7987C22.133 21.1987 20.9219 20.7487 19.6997 20.4487C18.4775 20.1487 17.2441 19.9987 15.9997 19.9987C14.7552 19.9987 13.5219 20.1487 12.2997 20.4487C11.0775 20.7487 9.86634 21.1987 8.66634 21.7987C8.46634 21.9098 8.30523 22.0654 8.18301 22.2654C8.06079 22.4654 7.99967 22.6876 7.99967 22.932V23.9987ZM15.9997 13.332C16.733 13.332 17.3608 13.0709 17.883 12.5487C18.4052 12.0265 18.6663 11.3987 18.6663 10.6654C18.6663 9.93203 18.4052 9.30425 17.883 8.78203C17.3608 8.25981 16.733 7.9987 15.9997 7.9987C15.2663 7.9987 14.6386 8.25981 14.1163 8.78203C13.5941 9.30425 13.333 9.93203 13.333 10.6654C13.333 11.3987 13.5941 12.0265 14.1163 12.5487C14.6386 13.0709 15.2663 13.332 15.9997 13.332Z" />
      </g>
    </svg>
  );
};

const BellIconSvg = ({ width = 22, height = 22, fill = "#667085" }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M5.33301 25.3332V22.6665H7.99967V13.3332C7.99967 11.4887 8.55523 9.84984 9.66634 8.4165C10.7775 6.98317 12.2219 6.04428 13.9997 5.59984V4.6665C13.9997 4.11095 14.1941 3.63873 14.583 3.24984C14.9719 2.86095 15.4441 2.6665 15.9997 2.6665C16.5552 2.6665 17.0275 2.86095 17.4163 3.24984C17.8052 3.63873 17.9997 4.11095 17.9997 4.6665V5.59984C19.7775 6.04428 21.2219 6.98317 22.333 8.4165C23.4441 9.84984 23.9997 11.4887 23.9997 13.3332V22.6665H26.6663V25.3332H5.33301ZM15.9997 29.3332C15.2663 29.3332 14.6386 29.0721 14.1163 28.5498C13.5941 28.0276 13.333 27.3998 13.333 26.6665H18.6663C18.6663 27.3998 18.4052 28.0276 17.883 28.5498C17.3608 29.0721 16.733 29.3332 15.9997 29.3332ZM10.6663 22.6665H21.333V13.3332C21.333 11.8665 20.8108 10.6109 19.7663 9.5665C18.7219 8.52206 17.4663 7.99984 15.9997 7.99984C14.533 7.99984 13.2775 8.52206 12.233 9.5665C11.1886 10.6109 10.6663 11.8665 10.6663 13.3332V22.6665Z" />
      </g>
    </svg>
  );
};

const ChatIconSvg = ({ width = 22, height = 22, fill = "#667085" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 24 24">
      <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 20l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 11H6v-2h12v2zm0-3H6V8h12v2z" />
    </svg>
  );
};

const MenuIconSvg = ({ width = 22, height = 22, fill = "#667085" }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="4" rx="2" width="22" x="1" y="2" />
      <rect height="4" rx="2" width="22" x="1" y="18" />
      <rect height="4" rx="2" width="22" x="1" y="10" />
    </svg>
  );
};

const LocationIconSvg = ({ width = 24, height = 24, fill = "#ff0000" }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M87.084,192c-0.456-5.272-0.688-10.6-0.688-16C86.404,78.8,162.34,0,256.004,0s169.6,78.8,169.6,176  c0,5.392-0.232,10.728-0.688,16h0.688c0,96.184-169.6,320-169.6,320s-169.6-223.288-169.6-320H87.084z M256.004,224  c36.392,1.024,66.744-27.608,67.84-64c-1.096-36.392-31.448-65.024-67.84-64c-36.392-1.024-66.744,27.608-67.84,64  C189.26,196.392,219.612,225.024,256.004,224z" />
    </svg>
  );
};
const ListIconSvg = ({ width = 16, height = 16, fill = "#ffffff" }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29,16c0,1.104-0.896,2-2,2H11c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C28.104,14,29,14.896,29,16z" />
      <path d="M29,6c0,1.104-0.896,2-2,2H11C9.896,8,9,7.104,9,6s0.896-2,2-2h16C28.104,4,29,4.896,29,6z" />
      <path d="M29,26c0,1.104-0.896,2-2,2H11c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C28.104,24,29,24.896,29,26z" />
      <path d="M3,6c0,1.103,0.897,2,2,2s2-0.897,2-2S6.103,4,5,4S3,4.897,3,6z" />
      <path d="M3,16c0,1.103,0.897,2,2,2s2-0.897,2-2s-0.897-2-2-2S3,14.897,3,16z" />
      <path d="M3,26c0,1.103,0.897,2,2,2s2-0.897,2-2s-0.897-2-2-2S3,24.897,3,26z" />
    </svg>
  );
};

const CloseIconSvg = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      // fill={fill}
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" />
      </g>
    </svg>
  );
};

const DragIndicatorIconSvg = ({ width = 16, height = 16, fill = "#667085" }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z" />
    </svg>
  );
};

const ExpandLessIconSvg = ({ width = 16, height = 16, fill = "#667085" }) => {
  return (
    <svg
      className="expand_arrow_icon"
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.714,11.297c-0.389-0.389-1.039-0.389-1.429,0l-8.999,8.976  c-0.394,0.394-0.394,1.033,0,1.426c0.394,0.394,1.034,0.394,1.428,0L16,13.436l8.285,8.264c0.395,0.394,1.034,0.394,1.429,0  c0.394-0.394,0.394-1.033,0-1.426L16.714,11.297z" />
    </svg>
  );
};
const ExpandMoreIconSvg = ({ width = 16, height = 16, fill = "#667085" }) => {
  return (
    <svg
      className="expand_arrow_icon"
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24.285,11.284L16,19.571l-8.285-8.288c-0.395-0.395-1.034-0.395-1.429,0  c-0.394,0.395-0.394,1.035,0,1.43l8.999,9.002l0,0l0,0c0.394,0.395,1.034,0.395,1.428,0l8.999-9.002  c0.394-0.395,0.394-1.036,0-1.431C25.319,10.889,24.679,10.889,24.285,11.284z" />
    </svg>
  );
};
const ArrowUpwardIconSvg = ({ width = 24, height = 24, fill = "#667085" }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      fill={fill}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 5.41V21a1 1 0 0 1-2 0V5.41l-5.3 5.3a1 1 0 1 1-1.4-1.42l7-7a1 1 0 0 1 1.4 0l7 7a1 1 0 1 1-1.4 1.42L13 5.4z" />
    </svg>
  );
};
const ArrowDownwardIconSvg = ({ width = 24, height = 24, fill = "#667085" }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      stroke={fill}
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 19L17.9999 13M11.9999 19L5.99988 13M11.9999 19L11.9999 5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

const LockIconSvg = ({ width = 20, height = 20, fill = "#667085", locked = false }) => {
  if (!locked) {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        fill={fill}
        version="1.1"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g transform="translate(334.000000, 430.000000)">
            <path d="M-313.2-401.2h-3v-6.4c0-7.8,6.4-14.2,14.2-14.2c7.2,0,13.2,5.3,14.1,12.4l-2.9,0.4     c-0.7-5.6-5.5-9.8-11.2-9.8c-6.2,0-11.2,5-11.2,11.2V-401.2" />
            <path d="M-289.3-374.2h-25.5c-2.6,0-4.8-2.1-4.8-4.8v-19.1c0-2.6,2.1-4.8,4.8-4.8h25.5     c2.6,0,4.8,2.1,4.8,4.8v19.1C-284.5-376.3-286.6-374.2-289.3-374.2L-289.3-374.2z M-314.7-399.6c-0.9,0-1.6,0.7-1.6,1.6v19.1     c0,0.9,0.7,1.6,1.6,1.6h25.5c0.9,0,1.6-0.7,1.6-1.6v-19.1c0-0.9-0.7-1.6-1.6-1.6H-314.7L-314.7-399.6z" />
            <path d="M-298.8-393.3c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2     S-298.8-395-298.8-393.3" />
            <polyline points="-301.2,-393.3 -302.8,-393.3 -304.4,-383.7 -299.6,-383.7 -301.2,-393.3    " />
          </g>
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        fill={fill}
        version="1.1"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g transform="translate(284.000000, 430.000000)">
            <path d="M-237.7-401.3h-3v-6.4c0-6.2-5.1-11.3-11.3-11.3c-6.2,0-11.3,5.1-11.3,11.3v6.4h-3v-6.4     c0-7.9,6.4-14.3,14.3-14.3s14.3,6.4,14.3,14.3V-401.3" />
            <path d="M-239.2-374.1h-25.6c-2.6,0-4.8-2.2-4.8-4.8v-19.2c0-2.6,2.2-4.8,4.8-4.8h25.6     c2.6,0,4.8,2.2,4.8,4.8v19.2C-234.4-376.2-236.6-374.1-239.2-374.1L-239.2-374.1z M-264.8-399.7c-0.9,0-1.6,0.7-1.6,1.6v19.2     c0,0.9,0.7,1.6,1.6,1.6h25.6c0.9,0,1.6-0.7,1.6-1.6v-19.2c0-0.9-0.7-1.6-1.6-1.6H-264.8L-264.8-399.7z" />
            <path d="M-248.8-393.3c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2S-248.8-395-248.8-393.3     " />
            <polyline points="-251.2,-393.3 -252.8,-393.3 -254.4,-383.7 -249.6,-383.7 -251.2,-393.3    " />
          </g>
        </g>
      </svg>
    );
  }
};

const ChackBoxSvg = ({ width = 20, height = 20, indeterminate, checked, onSelectAllClick, fill = "" }) => {
  if (indeterminate) {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => onSelectAllClick(false)}
      >
        <rect width="24" height="24" rx="4" />
        <line x1="5" y1="12" x2="19" y2="12" stroke="white" strokeWidth="2" />
        {/* <line x1="12" y1="19" x2="12" y2="5" stroke="white" strokeWidth="2" /> */}
      </svg>
    );
  } else if (checked) {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => onSelectAllClick(false)}
      >
        <rect width="24" height="24" rx="4" />
        <path d="M9.707 14.293L6.414 11L5 12.414L9.707 17.121L19.414 7.414L18 6L9.707 14.293Z" fill="white" />
      </svg>
    );
  } else {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        version="1.1"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => onSelectAllClick(true)}
      >
        <g clipPath="url(#clip0_101_23)">
          <rect x="1" y="1" width="22" height="22" rx="3" fill="" stroke="#767676" strokeWidth="2" />
        </g>
        <defs>
          <rect width="24" height="24" fill="white" />
        </defs>
      </svg>
    );
  }
};

const ChevronLeftIconSvg = ({ width = 20, height = 20, fill = "#667085", side = "top" }) => {
  if (side === "left") {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 32 32"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M11.262,16.714l9.002,8.999  c0.395,0.394,1.035,0.394,1.431,0c0.395-0.394,0.395-1.034,0-1.428L13.407,16l8.287-8.285c0.395-0.394,0.395-1.034,0-1.429  c-0.395-0.394-1.036-0.394-1.431,0l-9.002,8.999C10.872,15.675,10.872,16.325,11.262,16.714z"
        />
      </svg>
    );
  } else if (side === "right") {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 32 32"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z"
          fillRule="evenodd"
        />
      </svg>
    );
  } else if (side === "top") {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 32 32"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <line className="cls-1" x1="16" x2="25" y1="11.5" y2="20.5" />
          <line className="cls-1" x1="7" x2="16" y1="20.5" y2="11.5" />
        </g>
      </svg>
    );
  } else if (side === "down") {
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
          fillRule="evenodd"
        />
      </svg>
    );
  }
};

const CircularProgressSvg = ({ width = 36, height = 36 }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="circular_progress_svg"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          fill="#667085"
          d="M2263 4994 c-419 -50 -843 -223 -1185 -482 -48 -36 -86 -67 -85 -69
  2 -2 114 13 248 32 143 21 258 33 279 30 90 -17 163 -88 176 -172 6 -40 -24
  -307 -43 -392 l-6 -24 44 27 c383 235 844 307 1272 200 151 -37 328 -110 440
  -181 l40 -25 -109 -109 c-59 -59 -106 -110 -103 -112 6 -5 1133 -165 1138
  -161 3 3 -139 1017 -156 1112 l-6 34 -88 -88 -88 -87 -108 70 c-343 225 -688
  352 -1079 398 -156 18 -427 18 -581 -1z"
        />
        <path
          fill="blue"
          d="M890 4209 c-294 -43 -542 -78 -550 -78 -8 -1 29 -45 83 -99 53 -53
  97 -100 97 -104 0 -3 -23 -44 -52 -90 -177 -286 -303 -652 -344 -1004 -20
  -166 -14 -481 11 -639 64 -405 228 -794 473 -1117 36 -48 67 -87 68 -85 2 2
  -10 93 -27 202 -40 265 -41 338 -5 398 17 30 43 56 74 74 42 25 56 28 127 26
  44 0 140 -11 214 -22 73 -12 135 -20 137 -18 2 2 -8 20 -22 41 -48 71 -124
  234 -164 353 -55 166 -81 324 -82 503 0 167 12 269 52 426 24 96 100 298 130
  344 8 12 29 -4 109 -84 l100 -99 6 34 c12 69 155 1084 155 1101 0 13 -7 18
  -27 17 -16 -1 -269 -37 -563 -80z"
        />
        <path
          fill="orange"
          d="M4530 3998 c0 -7 11 -83 25 -168 34 -211 39 -287 26 -337 -14 -53
  -52 -98 -105 -126 -54 -28 -161 -28 -354 2 -79 13 -145 21 -148 19 -2 -2 13
  -35 33 -73 185 -349 233 -795 127 -1184 -29 -107 -50 -163 -101 -274 l-37 -78
  -97 97 c-54 54 -100 96 -102 93 -6 -7 -165 -1134 -161 -1138 4 -5 652 83 949
  129 105 16 192 29 195 29 2 1 -39 45 -92 98 l-97 98 61 100 c250 409 372 875
  355 1359 -13 366 -90 674 -252 1003 -63 129 -205 363 -220 363 -3 0 -5 -6 -5
  -12z"
        />
        <path
          fill="red"
          d="M830 1467 c0 -20 160 -1130 163 -1133 2 -2 45 39 97 91 52 52 98 95
  102 95 3 0 42 -22 86 -49 255 -159 554 -271 872 -328 131 -24 169 -26 405 -27
  227 0 277 3 394 23 406 70 775 228 1093 469 48 36 86 67 85 68 -2 2 -113 -12
  -248 -32 -141 -20 -259 -32 -279 -28 -90 16 -163 87 -176 171 -6 40 24 307 43
  392 l6 24 -44 -27 c-177 -109 -400 -193 -600 -227 -107 -18 -150 -21 -319 -16
  -262 7 -431 46 -649 148 l-85 40 99 99 c91 92 97 99 74 104 -35 8 -1081 156
  -1101 156 -10 0 -18 -6 -18 -13z"
        />
      </g>
    </svg>
  );
};

const FSIconRenderer = ({
  icon,
  iconColor,
  marginR = "8px",
  iconTitle = "",
  customStyles,
  onClickFun = () => {},
  className = "",
  iconClassName = "material-symbols-outlined"
}) => {
  let style = {};

  if (iconColor) {
    style["color"] = iconColor;
  }

  if (customStyles) {
    style = { ...style, ...customStyles };
  }

  if (icon) {
    return (
      <span
        className={"material-symbols-outlined data_icons " + className}
        onClick={onClickFun}
        title={iconTitle}
        style={{ marginRight: marginR, ...style }}
      >
        {icon.split(" ")[0]}
      </span>
    );
  } else {
    return undefined;
  }
};

export {
  UserIconSvg,
  BellIconSvg,
  ChatIconSvg,
  MenuIconSvg,
  ListIconSvg,
  CloseIconSvg,
  DragIndicatorIconSvg,
  ExpandLessIconSvg,
  ExpandMoreIconSvg,
  ArrowUpwardIconSvg,
  ArrowDownwardIconSvg,
  LockIconSvg,
  ChackBoxSvg,
  ChevronLeftIconSvg,
  CircularProgressSvg,
  LocationIconSvg,
  FileDownloadIconSvg,
  FSIconRenderer
};
