import React, { useState } from "react";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../zinoIcon";

const Collapse = ({ styleClassName, sectionTitle, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`${styleClassName} collapse_menu_outer`}>
      <div
        className="collapse_menu_item"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600"
          }}
        >
          {sectionTitle}
        </span>
        <div style={{ height: "24px" }}>{open ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}</div>
      </div>
      {open && <div style={{ borderTop: "1px solid #f0f0f1", paddingTop: "10px" }}>{children}</div>}
    </div>
  );
};

export default Collapse;
