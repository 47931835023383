import React, { useEffect, useRef, useState } from "react";
import { FormRenderer, PopupBoxSection, ProcessBarSection } from "../lib";
import { CloseIconSvg } from "../lib/zinoIcon";
import { usePageHandlerContext } from "./PageHandlerContext";
import toast from "react-hot-toast";
import { DetailsViewRenderPopup } from "../lib/detailsviewlib/DetailsViewRender";
import { PopupBoxSectionSide } from "../lib/viewlib/PopupBoxSection/PopupBoxSection";
import PageView from "../components/PageView";
import { ProcessBarBox } from "../lib/viewlib/ProcessBarSection/PopupBoxSection";
import { removeStorageItem } from "../utils/storageFunction";
import { useReactToPrint } from "react-to-print";

const ProcessTaskComponent = () => {
  const { processTask, removeProcessTask, processBar, createSubTask, processTaskListArray } = usePageHandlerContext();
  const [ownerUpdateData, setOwnerUpdateData] = useState({});

  // const apiCallAndGetDataDebounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 500);
  //   };
  // };

  // const apiCallAndGetData2 = async () => {
  //   let gridFieldsArray = document.querySelectorAll(".formGrid_rowBox_head");
  //   let w = 0;
  //   for (let index = 0; index < gridFieldsArray.length; index++) {
  //     const element = gridFieldsArray[index];
  //     w = Math.max(w, element?.clientWidth || 0);
  //   }
  //   let nav = 0;
  //   let navBarArray = document.querySelectorAll(".appFormBox");
  //   for (let index = 0; index < navBarArray.length; index++) {
  //     const element = navBarArray[index];
  //     nav = Math.max(nav, element?.clientWidth || 0);
  //   }

  //   // let root = document.getElementById("root");
  //   // if (w <= window.innerWidth) {
  //   //   root.style.width = "";
  //   // } else {
  //   //   root.style.width = "max-content";
  //   // }
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const apiCallAndGetDataOptimizedFun2 = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData2), []);

  // useEffect(() => {
  //   if (processTask?.type === "form") {
  //     apiCallAndGetDataOptimizedFun2();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [processTask]);

  if (processTaskListArray.length === 0 && processBar && !processTask) {
    return <ProcessBarSection />;
  }
  if (processTaskListArray.length > 0) {
    return (
      <>
        {processBar && !processTask ? <ProcessBarSection /> : ""}
        {processTaskListArray.map((processTaskItem) => {
          if (processTaskItem.type === "detailsview") {
            if (["print_detailsview"].includes(processTaskItem.front_end.action_config.action_in)) {
              return <DetailsViewPrintRender processTask={processTaskItem} removeProcessTask={removeProcessTask} />;
            }
            let size = processTaskItem?.detailsview_config?.detailview_settings?.size;
            let widthSize = "";
            if (size === "large") {
              widthSize = "75vw";
            } else if (size === "small") {
              widthSize = "35vw";
            } else {
              widthSize = "55vw";
            }
            return (
              <>
                <PopupBoxSectionSide
                  closePopup={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                  pClassName="popup_dv_section_overlay"
                  extraCss={{ width: widthSize }}
                >
                  <div style={{ width: widthSize }}>
                    <div className="popupSection_header">
                      <span className="popupSection_header_display">
                        {processTaskItem?.detailsview_config?.name || ""}
                      </span>
                      <span
                        className="popupSection_header_closeBTN"
                        onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                      >
                        <CloseIconSvg />
                      </span>
                    </div>
                    <div className="detailsView_popup_outer">
                      <DetailsViewRenderPopup
                        itemConfig={processTaskItem.detailsview_config}
                        rowData={processTaskItem.front_end.params_config}
                      />
                    </div>
                  </div>
                </PopupBoxSectionSide>
                {processBar && <ProcessBarSection />}
              </>
            );
          } else if (processTaskItem.type === "screen") {
            let layout = processTaskItem?.screen_config?.layout || [];
            return (
              <>
                <PopupBoxSectionSide
                  closePopup={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                  pClassName="popup_dv_section_overlay"
                >
                  <div style={{ width: "64vw" }}>
                    <div className="popupSection_header">
                      <span className="popupSection_header_display">{""}</span>
                      <span
                        className="popupSection_header_closeBTN"
                        onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                      >
                        <CloseIconSvg />
                      </span>
                    </div>
                    <div className="detailsView_popup_outer">
                      <PageView
                        pageConfig={layout}
                        callFrom="outside"
                        jobParamsTokenConfig={processTaskItem?.front_end?.token_data || {}}
                      />
                    </div>
                  </div>
                </PopupBoxSectionSide>
                {processBar && <ProcessBarSection />}
              </>
            );
          } else if (processTaskItem.type === "form") {
            if (
              [
                "perform_activity",
                "perform_activity_bg",
                "register_entity",
                "register_entity_bg",
                "start_state_machine",
                "start_state_machine_bg",
                "edit_entity_fe",
                "edit_entity_plus_fe",
                "submit_edit_entity_bg",
                "get_add_app_user_form",
                "get_edit_app_user_form",
                "edit_app_user_form_bg",
                "perform_activity_ai",
                "do_state_transition"
              ].includes(processTaskItem.front_end.action_config.action_name)
            ) {
              if (
                [
                  "start_state_machine_bg",
                  "register_entity_bg",
                  "perform_activity_bg",
                  "submit_edit_entity_bg",
                  "edit_app_user_form_bg"
                ].includes(processTaskItem.front_end.action_config.action_in)
              ) {
                return <></>;
              }

              const submitSubFormCallback = (respons, formExtraFunAndData) => {
                if (
                  ["get_add_app_user_form", "get_edit_app_user_form"].includes(
                    processTaskItem.front_end.action_config.action_name
                  )
                ) {
                  let instanceId = "";
                  let sections = processTaskItem?.form_config?.sections || [];
                  for (let index = 0; index < sections.length; index++) {
                    const fields = sections[index].fields;
                    for (let j = 0; j < fields.length; j++) {
                      const field = fields[j];
                      if (field?.type === "app-user") {
                        instanceId = respons.data[field.id]["id"];
                        break;
                      }
                    }
                  }

                  let action_config = processTaskItem.front_end.action_config;
                  let params_config = {
                    sm_id: action_config?.sm_id || "",
                    activity_id: processTaskItem.activity_id || "",
                    user_id: instanceId || action_config?.instance_id || "",
                    instance_id: action_config?.instance_id || "",
                    instance_ids: action_config?.instance_ids || [],
                    state_id: action_config?.state_id || "",
                    ...processTaskItem.front_end.params_config,
                    callback_function_after: [
                      () => {
                        removeStorageItem(formExtraFunAndData.local_storage_key, []);
                      }
                    ]
                  };

                  if (processTaskItem.front_end.action_config.action_name === "get_add_app_user_form") {
                    action_config.action_name = "edit_app_user_form_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "get_edit_app_user_form") {
                    action_config.action_name = "edit_app_user_form_bg";
                  }

                  createSubTask(params_config, respons.data, action_config);
                  //
                } else {
                  //
                  let action_config = processTaskItem.front_end.action_config;
                  let params_config = {
                    sm_id: action_config?.sm_id || "",
                    activity_id: processTaskItem.activity_id || "",
                    instance_id: action_config?.instance_id || "",
                    instance_ids: action_config?.instance_ids || [],
                    state_id: action_config?.state_id || "",
                    ...processTaskItem.front_end.params_config,
                    callback_function_after: [
                      () => {
                        removeStorageItem(formExtraFunAndData.local_storage_key, []);
                      }
                    ]
                  };
                  if (processTaskItem.front_end.action_config.action_name === "register_entity") {
                    action_config.action_name = "register_entity_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "start_state_machine") {
                    action_config.action_name = "start_state_machine_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "perform_activity") {
                    action_config.action_name = "perform_activity_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "perform_activity_ai") {
                    action_config.action_name = "perform_activity_bg";
                  } else if (
                    processTaskItem.front_end.action_config.action_name === "edit_entity_fe" ||
                    processTaskItem.front_end.action_config.action_name === "edit_entity_plus_fe"
                  ) {
                    action_config.action_name = "submit_edit_entity_bg";
                  }

                  createSubTask(params_config, respons.data, action_config);
                }
              };

              let form_style = processTaskItem?.formconfig?.form_style || {};
              let formStyleObj = {};
              if (form_style?.width) {
                formStyleObj.width = `${form_style.width}vw`;
              }
              if (form_style?.min_width) {
                formStyleObj.minWidth = `${form_style.min_width}px`;
              }
              if (form_style?.max_width) {
                formStyleObj.maxWidth = `${form_style.max_width}px`;
              }
              const contentStyleObject = {};
              if (processTaskItem?.formconfig?.gridFieldInForm) {
                // formStyleObj.margin = `20px 0 0 0`;
                // formStyleObj.padding = `0`;

                contentStyleObject.width = form_style?.width || "fit-content";

                if (!formStyleObj.minWidth) {
                  contentStyleObject.minWidth = "50vw";
                } else {
                  contentStyleObject.minWidth = formStyleObj.minWidth;
                }
              }

              return (
                <PopupBoxSection
                  closePopup={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                  styleObject={{ ...formStyleObj, ...contentStyleObject }}
                  contentStyleObject={contentStyleObject}
                >
                  <div className="form_popup_outer">
                    <div className="popupSection_header">
                      <span className="popupSection_header_display">{processTaskItem?.form_config?.name || ""}</span>
                      <span
                        className="popupSection_header_closeBTN"
                        onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                      >
                        <CloseIconSvg />
                      </span>
                    </div>
                    <div className="form_popup_body_outer">
                      {processTaskItem?.type === "form" ? (
                        <FormRenderer
                          apidata={{
                            apicalldata: processTaskItem.formconfig
                          }}
                          callbackfunction={{
                            formsubmitgetresponscbfun: submitSubFormCallback
                          }}
                          namespace={processTaskItem.front_end.action_config.subtask_id}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {processBar && <ProcessBarBox />}
                  </div>
                </PopupBoxSection>
              );
            } else if (
              ["get_owner", "set_owner", "set_owner_bg"].includes(processTaskItem.front_end.action_config.action_name)
            ) {
              if (["set_owner_bg"].includes(processTaskItem.front_end.action_config.action_in)) {
                return <></>;
              }
              const submitSubFormCallback = () => {
                let user = {};
                for (let index = 0; index < processTaskItem.users.length; index++) {
                  const element = processTaskItem.users[index];
                  if (element.id === ownerUpdateData.users) {
                    user = element;
                    break;
                  }
                }
                let action_config = processTaskItem.front_end.action_config;
                let params_config = {
                  sm_id: action_config?.sm_id || "",
                  activity_id: ownerUpdateData.activities || "",
                  instance_id: action_config?.instance_id || "",
                  instance_ids: action_config?.instance_ids || [],
                  state_id: action_config?.state_id || "",
                  owner_uid: ownerUpdateData.users || "",
                  activity_owner: user
                };
                action_config.action_name = "set_owner_bg";

                createSubTask(params_config, {}, action_config);
              };

              const changeValue = (event) => {
                let { name, value } = event.target;
                setOwnerUpdateData({ ...ownerUpdateData, [name]: value });
              };

              let form_style = processTaskItem?.formconfig?.form_style || {};
              let formStyleObj = {};
              if (form_style?.width) {
                formStyleObj.width = `${form_style.width}vw`;
              }
              if (form_style?.min_width) {
                formStyleObj.minWidth = `${form_style.min_width}px`;
              }
              if (form_style?.max_width) {
                formStyleObj.maxWidth = `${form_style.max_width}px`;
              }

              return (
                <PopupBoxSection
                  closePopup={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                  styleObject={formStyleObj}
                >
                  <div style={{ width: "100%", position: "relative" }}>
                    <div className="popupSection_header">
                      <span className="popupSection_header_display">{processTaskItem?.form_config?.name || ""}</span>
                      <span
                        className="popupSection_header_closeBTN"
                        onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                      >
                        <CloseIconSvg />
                      </span>
                    </div>
                    <div style={{ padding: "0 5px" }}>
                      <div style={{ width: "85%", margin: "auto" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "5px 0" }}>
                          <label htmlFor="activities">Activity</label>
                          <select
                            name="activities"
                            id="activities"
                            className="zino_inputBox"
                            onChange={(e) => changeValue(e)}
                          >
                            <option value="">Select Activity</option>
                            {processTaskItem.activities.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label htmlFor="users">Users</label>
                          <select name="users" id="users" className="zino_inputBox" onChange={(e) => changeValue(e)}>
                            <option value="">Select User</option>
                            {processTaskItem.users.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "15px" }}>
                          <button className={"zino_btn_primary"} onClick={submitSubFormCallback}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    {processBar && <ProcessBarSection />}
                  </div>
                </PopupBoxSection>
              );
            }
          }

          if (["debug_instance"].includes(processTaskItem.front_end.action_config.action_name)) {
            // etl_data instance_data ts_data
            function copyDataFromTextarea(id) {
              // Get the text field
              var copyText = document.getElementById(id);
              // Select the text field
              copyText.select();
              copyText.setSelectionRange(0, 99999); // For mobile devices

              // Copy the text inside the text field
              navigator.clipboard.writeText(copyText.value);

              toast.success("Copied to clipboard", {
                style: {
                  border: "1px solid green",
                  padding: "16px",
                  color: "green"
                },
                iconTheme: {
                  primary: "green",
                  secondary: "#FFFAEE"
                }
              });
            }
            return (
              <PopupBoxSection closePopup={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}>
                <div style={{ width: "64vw" }}>
                  <div className="popupSection_header">
                    <span className="popupSection_header_display">
                      Instance Id : {processTaskItem?.data?.instance_id || ""}
                    </span>
                    <span
                      className="popupSection_header_closeBTN"
                      onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                    >
                      <CloseIconSvg />
                    </span>
                  </div>
                  <div style={{ padding: "0 5px" }}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "15px",
                          padding: "10px 0"
                        }}
                      >
                        <label>Instance Data</label>
                        <span
                          style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
                          onClick={() => copyDataFromTextarea("instancedata")}
                        >
                          <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
                            content_copy
                          </span>
                        </span>
                      </div>
                      <textarea
                        id="instancedata"
                        style={{ minHeight: "200px" }}
                        value={JSON.stringify(processTaskItem.data?.instance_data || {}, null, 2)}
                        rows={4}
                        cols={60}
                        disabled
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "15px",
                          padding: "10px 0"
                        }}
                      >
                        <label>ETL Data</label>
                        <span
                          style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
                          onClick={() => copyDataFromTextarea("etldata")}
                        >
                          <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
                            content_copy
                          </span>
                        </span>
                      </div>
                      <textarea
                        id="etldata"
                        style={{ minHeight: "200px" }}
                        value={JSON.stringify(processTaskItem.data?.etl_data || {}, null, 2)}
                        rows={4}
                        cols={60}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {processBar && <ProcessBarSection />}
              </PopupBoxSection>
            );
          }

          return <></>;
        })}
      </>
    );
  } else {
    return <></>;
  }
  // if (processBar && !processTask) {
  //   return <ProcessBarSection />;
  // } else if (processTask) {
  //   if (processTask.type === "detailsview") {
  //     if (["print_detailsview"].includes(processTask.front_end.action_config.action_in)) {
  //       return <DetailsViewPrintRender processTask={processTask} removeProcessTask={removeProcessTask} />;
  //     }
  //     let size = processTask?.detailsview_config?.detailview_settings?.size;
  //     let widthSize = "";
  //     if (size === "large") {
  //       widthSize = "75vw";
  //     } else if (size === "small") {
  //       widthSize = "35vw";
  //     } else {
  //       widthSize = "55vw";
  //     }
  //     return (
  //       <>
  //         <PopupBoxSectionSide
  //           closePopup={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //           pClassName="popup_dv_section_overlay"
  //           extraCss={{ width: widthSize }}
  //         >
  //           <div style={{ width: widthSize }}>
  //             <div className="popupSection_header">
  //               <span className="popupSection_header_display">{processTask?.detailsview_config?.name || ""}</span>
  //               <span
  //                 className="popupSection_header_closeBTN"
  //                 onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //               >
  //                 <CloseIconSvg />
  //               </span>
  //             </div>
  //             <div className="detailsView_popup_outer">
  //               <DetailsViewRenderPopup
  //                 itemConfig={processTask.detailsview_config}
  //                 rowData={processTask.front_end.params_config}
  //               />
  //             </div>
  //           </div>
  //         </PopupBoxSectionSide>
  //         {processBar && <ProcessBarSection />}
  //       </>
  //     );
  //   } else if (processTask.type === "screen") {
  //     let layout = processTask?.screen_config?.layout || [];
  //     return (
  //       <>
  //         <PopupBoxSectionSide
  //           closePopup={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //           pClassName="popup_dv_section_overlay"
  //         >
  //           <div style={{ width: "64vw" }}>
  //             <div className="popupSection_header">
  //               <span className="popupSection_header_display">{""}</span>
  //               <span
  //                 className="popupSection_header_closeBTN"
  //                 onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //               >
  //                 <CloseIconSvg />
  //               </span>
  //             </div>
  //             <div className="detailsView_popup_outer">
  //               <PageView
  //                 pageConfig={layout}
  //                 callFrom="outside"
  //                 jobParamsTokenConfig={processTask?.front_end?.token_data || {}}
  //               />
  //             </div>
  //           </div>
  //         </PopupBoxSectionSide>
  //         {processBar && <ProcessBarSection />}
  //       </>
  //     );
  //   } else if (processTask.type === "form") {
  //     if (
  //       [
  //         "perform_activity",
  //         "perform_activity_bg",
  //         "register_entity",
  //         "register_entity_bg",
  //         "start_state_machine",
  //         "start_state_machine_bg",
  //         "edit_entity_fe",
  //         "edit_entity_plus_fe",
  //         "submit_edit_entity_bg",
  //         "get_add_app_user_form",
  //         "get_edit_app_user_form",
  //         "edit_app_user_form_bg",
  //         "perform_activity_ai",
  //         "do_state_transition"
  //       ].includes(processTask.front_end.action_config.action_name)
  //     ) {
  //       if (
  //         [
  //           "start_state_machine_bg",
  //           "register_entity_bg",
  //           "perform_activity_bg",
  //           "submit_edit_entity_bg",
  //           "edit_app_user_form_bg"
  //         ].includes(processTask.front_end.action_config.action_in)
  //       ) {
  //         return <></>;
  //       }

  //       const submitSubFormCallback = (respons, formExtraFunAndData) => {
  //         if (
  //           ["get_add_app_user_form", "get_edit_app_user_form"].includes(
  //             processTask.front_end.action_config.action_name
  //           )
  //         ) {
  //           let instanceId = "";
  //           let sections = processTask?.form_config?.sections || [];
  //           for (let index = 0; index < sections.length; index++) {
  //             const fields = sections[index].fields;
  //             for (let j = 0; j < fields.length; j++) {
  //               const field = fields[j];
  //               if (field?.type === "app-user") {
  //                 instanceId = respons.data[field.id]["id"];
  //                 break;
  //               }
  //             }
  //           }

  //           let action_config = processTask.front_end.action_config;
  //           let params_config = {
  //             sm_id: action_config?.sm_id || "",
  //             activity_id: processTask.activity_id || "",
  //             user_id: instanceId || action_config?.instance_id || "",
  //             instance_id: action_config?.instance_id || "",
  //             instance_ids: action_config?.instance_ids || [],
  //             state_id: action_config?.state_id || "",
  //             ...processTask.front_end.params_config,
  //             callback_function_after: [
  //               () => {
  //                 removeStorageItem(formExtraFunAndData.local_storage_key, []);
  //               }
  //             ]
  //           };

  //           if (processTask.front_end.action_config.action_name === "get_add_app_user_form") {
  //             action_config.action_name = "edit_app_user_form_bg";
  //           } else if (processTask.front_end.action_config.action_name === "get_edit_app_user_form") {
  //             action_config.action_name = "edit_app_user_form_bg";
  //           }

  //           createSubTask(params_config, respons.data, action_config);
  //           //
  //         } else {
  //           //
  //           let action_config = processTask.front_end.action_config;
  //           let params_config = {
  //             sm_id: action_config?.sm_id || "",
  //             activity_id: processTask.activity_id || "",
  //             instance_id: action_config?.instance_id || "",
  //             instance_ids: action_config?.instance_ids || [],
  //             state_id: action_config?.state_id || "",
  //             ...processTask.front_end.params_config,
  //             callback_function_after: [
  //               () => {
  //                 removeStorageItem(formExtraFunAndData.local_storage_key, []);
  //               }
  //             ]
  //           };
  //           if (processTask.front_end.action_config.action_name === "register_entity") {
  //             action_config.action_name = "register_entity_bg";
  //           } else if (processTask.front_end.action_config.action_name === "start_state_machine") {
  //             action_config.action_name = "start_state_machine_bg";
  //           } else if (processTask.front_end.action_config.action_name === "perform_activity") {
  //             action_config.action_name = "perform_activity_bg";
  //           } else if (processTask.front_end.action_config.action_name === "perform_activity_ai") {
  //             action_config.action_name = "perform_activity_bg";
  //           } else if (
  //             processTask.front_end.action_config.action_name === "edit_entity_fe" ||
  //             processTask.front_end.action_config.action_name === "edit_entity_plus_fe"
  //           ) {
  //             action_config.action_name = "submit_edit_entity_bg";
  //           }

  //           createSubTask(params_config, respons.data, action_config);
  //         }
  //       };

  //       let form_style = processTask?.formconfig?.form_style || {};
  //       let formStyleObj = {};
  //       if (form_style?.width) {
  //         formStyleObj.width = `${form_style.width}vw`;
  //       }
  //       if (form_style?.min_width) {
  //         formStyleObj.minWidth = `${form_style.min_width}px`;
  //       }
  //       if (form_style?.max_width) {
  //         formStyleObj.maxWidth = `${form_style.max_width}px`;
  //       }
  //       const contentStyleObject = {};
  //       if (processTask?.formconfig?.gridFieldInForm) {
  //         // formStyleObj.margin = `20px 0 0 0`;
  //         // formStyleObj.padding = `0`;
  //         contentStyleObject.width = `max-content`;
  //         if (!formStyleObj.minWidth) {
  //           contentStyleObject.minWidth = "50vw";
  //         } else {
  //           contentStyleObject.minWidth = formStyleObj.minWidth;
  //         }
  //       }

  //       return (
  //         <PopupBoxSection
  //           closePopup={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //           styleObject={{ ...formStyleObj, ...contentStyleObject }}
  //           contentStyleObject={contentStyleObject}
  //         >
  //           <div className="form_popup_outer">
  //             <div className="popupSection_header">
  //               <span className="popupSection_header_display">{processTask?.form_config?.name || ""}</span>
  //               <span
  //                 className="popupSection_header_closeBTN"
  //                 onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //               >
  //                 <CloseIconSvg />
  //               </span>
  //             </div>
  //             <div className="form_popup_body_outer">
  //               {processTask?.type === "form" ? (
  //                 <FormRenderer
  //                   apidata={{
  //                     apicalldata: processTask.formconfig
  //                   }}
  //                   callbackfunction={{
  //                     formsubmitgetresponscbfun: submitSubFormCallback
  //                   }}
  //                   namespace={processTask.front_end.action_config.subtask_id}
  //                 />
  //               ) : (
  //                 <div></div>
  //               )}
  //             </div>
  //             {processBar && <ProcessBarBox />}
  //           </div>
  //         </PopupBoxSection>
  //       );
  //     } else if (["get_owner", "set_owner", "set_owner_bg"].includes(processTask.front_end.action_config.action_name)) {
  //       if (["set_owner_bg"].includes(processTask.front_end.action_config.action_in)) {
  //         return <></>;
  //       }
  //       const submitSubFormCallback = () => {
  //         let user = {};
  //         for (let index = 0; index < processTask.users.length; index++) {
  //           const element = processTask.users[index];
  //           if (element.id === ownerUpdateData.users) {
  //             user = element;
  //             break;
  //           }
  //         }
  //         let action_config = processTask.front_end.action_config;
  //         let params_config = {
  //           sm_id: action_config?.sm_id || "",
  //           activity_id: ownerUpdateData.activities || "",
  //           instance_id: action_config?.instance_id || "",
  //           instance_ids: action_config?.instance_ids || [],
  //           state_id: action_config?.state_id || "",
  //           owner_uid: ownerUpdateData.users || "",
  //           activity_owner: user
  //         };
  //         action_config.action_name = "set_owner_bg";

  //         createSubTask(params_config, {}, action_config);
  //       };

  //       const changeValue = (event) => {
  //         let { name, value } = event.target;
  //         setOwnerUpdateData({ ...ownerUpdateData, [name]: value });
  //       };

  //       let form_style = processTask?.formconfig?.form_style || {};
  //       let formStyleObj = {};
  //       if (form_style?.width) {
  //         formStyleObj.width = `${form_style.width}vw`;
  //       }
  //       if (form_style?.min_width) {
  //         formStyleObj.minWidth = `${form_style.min_width}px`;
  //       }
  //       if (form_style?.max_width) {
  //         formStyleObj.maxWidth = `${form_style.max_width}px`;
  //       }

  //       return (
  //         <PopupBoxSection
  //           closePopup={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //           styleObject={formStyleObj}
  //         >
  //           <div style={{ width: "100%", position: "relative" }}>
  //             <div className="popupSection_header">
  //               <span className="popupSection_header_display">{processTask?.form_config?.name || ""}</span>
  //               <span
  //                 className="popupSection_header_closeBTN"
  //                 onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //               >
  //                 <CloseIconSvg />
  //               </span>
  //             </div>
  //             <div style={{ padding: "0 5px" }}>
  //               <div style={{ width: "85%", margin: "auto" }}>
  //                 <div style={{ display: "flex", flexDirection: "column", padding: "5px 0" }}>
  //                   <label htmlFor="activities">Activity</label>
  //                   <select
  //                     name="activities"
  //                     id="activities"
  //                     className="zino_inputBox"
  //                     onChange={(e) => changeValue(e)}
  //                   >
  //                     <option value="">Select Activity</option>
  //                     {processTask.activities.map((option) => (
  //                       <option key={option.id} value={option.id}>
  //                         {option.name}
  //                       </option>
  //                     ))}
  //                   </select>
  //                 </div>
  //                 <div>
  //                   <label htmlFor="users">Users</label>
  //                   <select name="users" id="users" className="zino_inputBox" onChange={(e) => changeValue(e)}>
  //                     <option value="">Select User</option>
  //                     {processTask.users.map((option) => (
  //                       <option key={option.id} value={option.id}>
  //                         {option.name}
  //                       </option>
  //                     ))}
  //                   </select>
  //                 </div>
  //                 <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "15px" }}>
  //                   <button className={"zino_btn_primary"} onClick={submitSubFormCallback}>
  //                     Submit
  //                   </button>
  //                 </div>
  //               </div>
  //             </div>
  //             {processBar && <ProcessBarSection />}
  //           </div>
  //         </PopupBoxSection>
  //       );
  //     }
  //   }

  //   if (["debug_instance"].includes(processTask.front_end.action_config.action_name)) {
  //     // etl_data instance_data ts_data
  //     function copyDataFromTextarea(id) {
  //       // Get the text field
  //       var copyText = document.getElementById(id);
  //       // Select the text field
  //       copyText.select();
  //       copyText.setSelectionRange(0, 99999); // For mobile devices

  //       // Copy the text inside the text field
  //       navigator.clipboard.writeText(copyText.value);

  //       toast.success("Copied to clipboard", {
  //         style: {
  //           border: "1px solid green",
  //           padding: "16px",
  //           color: "green"
  //         },
  //         iconTheme: {
  //           primary: "green",
  //           secondary: "#FFFAEE"
  //         }
  //       });
  //     }
  //     return (
  //       <PopupBoxSection closePopup={() => removeProcessTask(processTask?.front_end.action_config.task_id)}>
  //         <div style={{ width: "64vw" }}>
  //           <div className="popupSection_header">
  //             <span className="popupSection_header_display">Instance Id : {processTask?.data?.instance_id || ""}</span>
  //             <span
  //               className="popupSection_header_closeBTN"
  //               onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //             >
  //               <CloseIconSvg />
  //             </span>
  //           </div>
  //           <div style={{ padding: "0 5px" }}>
  //             <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "flex-start",
  //                   gap: "15px",
  //                   padding: "10px 0"
  //                 }}
  //               >
  //                 <label>Instance Data</label>
  //                 <span
  //                   style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
  //                   onClick={() => copyDataFromTextarea("instancedata")}
  //                 >
  //                   <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
  //                     content_copy
  //                   </span>
  //                 </span>
  //               </div>
  //               <textarea
  //                 id="instancedata"
  //                 style={{ minHeight: "200px" }}
  //                 value={JSON.stringify(processTask.data?.instance_data || {}, null, 2)}
  //                 rows={4}
  //                 cols={60}
  //                 disabled
  //               />
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   justifyContent: "flex-start",
  //                   gap: "15px",
  //                   padding: "10px 0"
  //                 }}
  //               >
  //                 <label>ETL Data</label>
  //                 <span
  //                   style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
  //                   onClick={() => copyDataFromTextarea("etldata")}
  //                 >
  //                   <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
  //                     content_copy
  //                   </span>
  //                 </span>
  //               </div>
  //               <textarea
  //                 id="etldata"
  //                 style={{ minHeight: "200px" }}
  //                 value={JSON.stringify(processTask.data?.etl_data || {}, null, 2)}
  //                 rows={4}
  //                 cols={60}
  //                 disabled
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         {processBar && <ProcessBarSection />}
  //       </PopupBoxSection>
  //     );
  //   }
  // }
};

export default ProcessTaskComponent;

const DetailsViewPrintRender = ({ processTask, removeProcessTask }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    copyStyles: true,
    removeAfterPrint: true,
    content: () => {
      return componentRef?.current;
    },
    onAfterPrint: () => {
      removeProcessTask(processTask?.front_end.action_config.task_id);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      handlePrint();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let w = window.innerWidth;

  return (
    <div style={{ position: "fixed", left: `-${w + 100}px`, top: 0 }}>
      <div className="detailsView_popup_outer" ref={componentRef}>
        <DetailsViewRenderPopup
          itemConfig={processTask.detailsview_config}
          rowData={processTask.front_end.params_config}
        />
      </div>
    </div>
  );
};
