import React from "react";
import FGTextField from "./field/FGTextField";
import FGLookupField from "./field/FGLookupField";
import FGSelectField from "./field/FGSelectField";
import FGAppUserField from "./field/FGAppUserField";
import FGLearningTextField from "./field/FGLearningTextField";
import FGFileField from "./field/FGFileField";

const FormGrid = ({
  gridCollItem,
  currentConfigKey,
  namespace,
  selectBoxOption,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  switch (gridCollItem.type) {
    case "lookup":
      return (
        <FGLookupField
          gridCollItem={gridCollItem}
          currentConfigKey={currentConfigKey}
          namespace={namespace}
          stateObject={stateObject}
          stateFunctionObject={stateFunctionObject}
          commonFunctionObject={commonFunctionObject}
          gridFlagStateObject={gridFlagStateObject}
        />
      );

    case "app-user":
      return (
        <FGAppUserField
          gridCollItem={gridCollItem}
          currentConfigKey={currentConfigKey}
          namespace={namespace}
          stateObject={stateObject}
          stateFunctionObject={stateFunctionObject}
          commonFunctionObject={commonFunctionObject}
          gridFlagStateObject={gridFlagStateObject}
        />
      );

    case "textfield":
    case "number":
    case "email":
    case "password":
    case "date":
    case "time":
    case "datetime":
    case "disabled":
      if (gridCollItem.type === "disabled") {
        gridCollItem.disable = true;
      }

      return (
        <FGTextField
          namespace={namespace}
          gridCollItem={gridCollItem}
          currentConfigKey={currentConfigKey}
          stateObject={stateObject}
          stateFunctionObject={stateFunctionObject}
          commonFunctionObject={commonFunctionObject}
          gridFlagStateObject={gridFlagStateObject}
        />
      );

    case "file":
    case "image":
      return (
        <FGFileField
          namespace={namespace}
          gridCollItem={gridCollItem}
          currentConfigKey={currentConfigKey}
          optionList={selectBoxOption?.[gridCollItem.id] || []}
          stateObject={stateObject}
          stateFunctionObject={stateFunctionObject}
          commonFunctionObject={commonFunctionObject}
          gridFlagStateObject={gridFlagStateObject}
        />
      );

    case "select":
      return (
        <FGSelectField
          namespace={namespace}
          gridCollItem={gridCollItem}
          currentConfigKey={currentConfigKey}
          optionList={selectBoxOption?.[gridCollItem.id] || []}
          stateObject={stateObject}
          stateFunctionObject={stateFunctionObject}
          commonFunctionObject={commonFunctionObject}
          gridFlagStateObject={gridFlagStateObject}
        />
      );

    case "learning-text":
      return (
        <FGLearningTextField
          namespace={namespace}
          gridCollItem={gridCollItem}
          currentConfigKey={currentConfigKey}
          stateObject={stateObject}
          stateFunctionObject={stateFunctionObject}
          commonFunctionObject={commonFunctionObject}
          gridFlagStateObject={gridFlagStateObject}
        />
      );

    default:
      return <></>;
  }
};

export default FormGrid;
