import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { getApiCallLocalPath } from "../../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../../utils/storageFunction";
import { checkDataUniqueOrNot, getDataById, validateGridCollItem } from "../utils/storeFunction";
import { commonFunction } from "../../utils/commonFunction";
import { FSIconRenderer } from "../../../zinoIcon";

const FGLookupField = ({
  namespace,
  gridCollItem,
  currentConfigKey,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  const storeData = useSelector((state) => state.formviewstore);
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  const [outputOption, setOutputOption] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || {}
  );

  const [inputOptionError, setInputOptionError] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []
  );

  const [inputOption, setInputOption] = useState([]);
  const [inputOptionIds, setInputOptionIds] = useState([]);
  const [termText, setTermText] = useState("");
  const [loading, setLoading] = useState(false);

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async (term, search_iId = null) => {
    setLoading(true);
    try {
      setInputOption([]);
      setInputOptionIds([]);

      let field_Data = {
        field_id: gridCollItem.id
      };
      if (formConfigData.id) field_Data["form_id"] = formConfigData.id;
      if (formConfigData.sm_id) field_Data["sm_id"] = formConfigData.sm_id;
      if (formConfigData.activity_id) field_Data["activity_id"] = formConfigData.activity_id;
      if (formConfigData.instance_id) field_Data["instance_id"] = formConfigData.instance_id;
      // if (formConfigData.entity_id) field_Data["entity_id"] = formConfigData.entity_id;
      // if (formConfigData.sm_activity_id) field_Data["activity_id"] = formConfigData.sm_activity_id;

      let { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          Params: {
            search_query: {
              search_term: term || "",
              search_fields: [],
              facet_by: [],
              page: 1,
              per_page: 10,
              instance_id: search_iId || null
            },
            table_id: gridCollItem.table_id
          },
          data: field_Data,
          function_name: "perform_search"
        },
        { headers: getApiCallHeadersData() }
      );

      let document = data?.data?.documents || [];
      let arrayIds = [];
      for (let index = 0; index < document.length; index++) {
        const lookupOption = document[index];
        arrayIds.push(`${currentConfigKey}_${lookupOption.id}_lookup_option`);
      }
      setInputOptionIds(arrayIds);
      setInputOption(document);

      return document;
    } catch (error) {
      setInputOption([]);
      setInputOptionIds([]);
      return null;
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (stateObject.gridComponentFormElements) {
      setOutputOption(getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElements]);

  useLayoutEffect(() => {
    setInputOptionError(getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElementsError]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  useEffect(() => {
    if (gridCollItem.show_default_option) {
      if (Object.keys(outputOption).length > 0) {
        let display_fields = gridCollItem?.display_fields || [];
        let access_fields = gridCollItem?.access_fields || [];
        let keyArray = [];
        for (let index = 0; index < display_fields.length; index++) {
          const element = display_fields[index];
          if (!keyArray.includes(element.id)) {
            keyArray.push(element.id);
          }
        }
        for (let index = 0; index < access_fields.length; index++) {
          const element = access_fields[index];
          if (!keyArray.includes(element.id)) {
            keyArray.push(element.id);
          }
        }

        let flag = false;
        for (let index = 0; index < keyArray.length; index++) {
          const idIs = keyArray[index];
          let idArray = idIs.split(".");
          let val = "";
          let data = outputOption;
          for (let index = 0; index < idArray.length; index++) {
            const element = idArray[index];
            if (index === idArray.length - 1 && data[element]) {
              val = data[element];
            } else if (data[element]) {
              data = data[element];
            } else if (typeof data[element] === "undefined") {
              val = undefined;
              break;
            }
          }
          if (typeof val === "undefined") {
            flag = true;
            break;
          }
        }

        if (flag) {
          apiCallAndGetData("", outputOption?.instance_id || outputOption?.id);
        } else {
          let arrayIds = [`${currentConfigKey}_${outputOption?.instance_id || outputOption?.id}_lookup_option`];
          setInputOptionIds(arrayIds);
          setInputOption([outputOption]);
        }
      } else {
        apiCallAndGetData("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionKeydownFunction = (event, data = {}) => {
    let currentCollItemId = event.target.id;
    let nextCollItemId = "";

    switch (event.key) {
      case "ArrowUp":
        // if (inputOptionIds.length === 0) {
        //   commonFunctionObject.keyboardKeydownFun(event);
        // }
        if (currentCollItemId === `${currentConfigKey}_lookup_option`) {
          nextCollItemId = currentConfigKey;
        } else if (currentCollItemId === inputOptionIds[0]) {
          nextCollItemId = `${currentConfigKey}_lookup_option`;
        } else {
          let index = inputOptionIds.indexOf(currentCollItemId);
          if (index !== -1) {
            nextCollItemId = inputOptionIds?.[index - 1] || "";
          }
        }
        event.preventDefault();
        break;

      case "ArrowDown":
        if (currentCollItemId === currentConfigKey) {
          nextCollItemId = `${currentConfigKey}_lookup_option`;
        } else if (currentCollItemId === `${currentConfigKey}_lookup_option`) {
          nextCollItemId = inputOptionIds[0];
        } else {
          let index = inputOptionIds.indexOf(currentCollItemId);
          if (index !== -1) {
            nextCollItemId = inputOptionIds?.[index + 1] || "";
          }
        }
        event.preventDefault();
        break;

      case "Tab":
        if (event.shiftKey) {
          commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey, "left");
          event.preventDefault();
        } else {
          commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey, "right");
          event.preventDefault();
        }

        // const endWithPattern = new RegExp(`^.*${"_lookup_option"}$`, "i");
        // if (endWithPattern.test(currentCollItemId)) {
        // } else {
        // }
        break;

      case "ArrowLeft":
        // if (!termText && inputOptionIds.length === 0) {
        //   commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey);
        //   event.preventDefault();
        // }
        break;

      case "ArrowRight":
        // if (!termText && inputOptionIds.length === 0) {
        //   commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey);
        //   event.preventDefault();
        // }
        break;

      case "Enter":
        validate(event, data, true);
        break;

      default:
        break;
    }

    if (nextCollItemId) {
      let nextItem = document.getElementById(nextCollItemId);
      nextItem.focus();
    }
  };

  const validate = async (e, value, addNewRowFlag) => {
    if (gridCollItem.unique) {
      const uniqueDataFlag = checkDataUniqueOrNot(
        stateObject.gridComponentFormElements,
        gridCollItem.id,
        value || "",
        gridFlagStateObject.gridRowIndex
      );
      if (uniqueDataFlag) {
        value = "";
      }
    }

    setOutputOption(value);

    await validateGridCollItem(
      e,
      namespace,
      currentConfigKey,
      stateObject,
      value || "",
      gridCollItem,
      commonFunction,
      gridFlagStateObject,
      addNewRowFlag,
      stateFunctionObject,
      commonFunctionObject,
      true
    );
  };

  let display_fields = gridCollItem.display_fields;
  if (stateObject.gridFocusedElement === currentConfigKey) {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type="text"
          id={currentConfigKey}
          className={"formGrid_coll_item_input_focus"}
          onChange={(e) => {
            setTermText(e.target.value);
            apiCallAndGetDataOptimizedFun(e.target.value);
          }}
          value={termText}
          onKeyDown={optionKeydownFunction}
          disabled={gridCollItem.disable}
          autoComplete="off"
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
        />

        <div id={currentConfigKey + "_optionlist"} className="formGrid_coll_lookup_option_outbox">
          <input
            type="text"
            id={currentConfigKey + "_lookup_option"}
            className={"formGrid_coll_lookup_option"}
            onKeyDown={optionKeydownFunction}
            value={inputOption.length > 0 ? "Select Option" : "No Data"}
            readOnly={true}
          />
          {loading && (
            <div className="zino_renderer_loader_box" style={{ height: "32px" }}>
              <span className="zino_renderer_line_loader" style={{ top: "-20px" }}></span>
            </div>
          )}
          {inputOption.map((lookupOption) => {
            lookupOption["sm_id"] = gridCollItem.table_id;
            lookupOption["instance_id"] = lookupOption.id;
            let value = "";
            for (let index = 0; index < display_fields.length; index++) {
              const element = display_fields[index];
              let idIs = element.id;
              let idArray = idIs.split(".");
              let val = "";
              let data = lookupOption;
              for (let index = 0; index < idArray.length; index++) {
                const element = idArray[index];
                if (index === idArray.length - 1 && data[element]) {
                  val = data[element];
                } else if (data[element]) {
                  data = data[element];
                }
              }
              if (val) {
                if (value) value = value + " " + val;
                else value = val;
              }
            }
            return (
              <input
                type="text"
                id={currentConfigKey + "_" + lookupOption.id + "_lookup_option"}
                key={currentConfigKey + "_" + lookupOption.id + "_lookup_option"}
                className={"formGrid_coll_lookup_option"}
                onClick={(e) => validate(e, lookupOption, false)}
                onKeyDown={(e) => optionKeydownFunction(e, lookupOption)}
                value={value}
                readOnly={true}
                title={value}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    let value = "";
    for (let index = 0; index < display_fields.length; index++) {
      const element = display_fields[index];
      let idIs = element.id;
      let idArray = idIs.split(".");
      let val = "";
      let data = outputOption;
      for (let index = 0; index < idArray.length; index++) {
        const element = idArray[index];
        if (index === idArray.length - 1 && data[element]) {
          val = data[element];
        } else if (data[element]) {
          data = data[element];
        }
      }
      if (val) {
        if (value) value = value + " " + val;
        else value = val;
      }
    }
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type="text"
          id={currentConfigKey}
          className={"formGrid_coll_item_input"}
          onFocus={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          onClick={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          value={value}
          title={value}
          readOnly={true}
          disabled={gridCollItem.disable}
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
        />
        {inputOptionError.length > 0 && (
          <span className="formGrid_coll_item_input_icon">
            <FSIconRenderer icon={"error"} iconColor={"red"} marginR="0" iconTitle={inputOptionError.join(" , ")} />
          </span>
        )}
      </div>
    );
  }
};

export default FGLookupField;
