import React, { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsFunction } from "../../redux/appview/appviewaction";
import { JobActionRenderer, PopupBoxSection } from "../../lib/index";
import { CloseIconSvg } from "../../lib/zinoIcon";

const NotificationItem = ({ closePopup, showPopup }) => {
  const dispatch = useDispatch();
  const { notificationsArray = [] } = useSelector((state) => state.appviewstore);
  const { user } = useSelector((state) => state.userviewstore);

  useLayoutEffect(() => {
    if (showPopup) {
      dispatch(getNotificationsFunction(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  return (
    <PopupBoxSection closePopup={closePopup} showPopup={showPopup}>
      <div className="popupSection_header">
        <span className="popupSection_header_display">Notifications</span>
        <span className="popupSection_header_closeBTN" onClick={() => closePopup(false)}>
          <CloseIconSvg />
        </span>
      </div>
      {notificationsArray?.map((notification, notificationIndex) => {
        return (
          <div
            key={notificationIndex}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              padding: "8px 32px",
              margin: "8px",
              borderBottom: "1px dotted #e5e5e5"
            }}
          >
            <div
              style={{
                display: "inline-block",
                whiteSpace: "pre-line",
                fontSize: "16px",
                marginRight: "12px"
              }}
            >
              {notification.text}
            </div>
            <div>
              {notification.status === "pending" && (
                <JobActionRenderer
                  style={{ className: "zino_btn_outline_primary" }}
                  itemConfig={{
                    action_end: {
                      hide: [],
                      refresh: [],
                      show: []
                    },
                    action_start: {
                      hide: [],
                      refresh: [],
                      show: []
                    },
                    icon: "",
                    id: "",
                    job_config_id: "",
                    job_id: "656f319c75d34144f449c95a",
                    job_template: "perform_activity",
                    name: "Click",
                    params: {
                      activity_id: notification.activity_id,
                      sm_id: notification.sm_id
                    },
                    type: "button"
                  }}
                  jobParamsConfig={{ instance_id: notification.sm_instance_id }}
                />
              )}
            </div>
          </div>
        );
      })}
    </PopupBoxSection>
  );
};

export default NotificationItem;
