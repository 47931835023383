import React from "react";
import { useSelector } from "react-redux";
import { gridCommonFunction } from "../../utils/gridCommonFunction";
import CardItems from "./sectionrendererlib/card/CardItems";

const KanbanCard = ({ layout, layoutData, namespace, sm_id }) => {
  const { dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const layoutIndex = dashboardAppConfig.page.findIndex((obj) => obj.element_config.id === namespace);
  const layoutCopy = dashboardAppConfig.page[layoutIndex].element_config.config.data.elements;
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < layoutCopy.length; index++) {
    let gridElement = layoutCopy[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  return (
    <div style={styleDataForGrid} className="cardDefault">
      {layoutCopy?.map((element, index) => {
        const { element_config } = element;
        return <CardItems key={index} colItem={element_config} dataConfig={layoutData} sm_id={sm_id} />;
      })}
    </div>
  );
};

export default KanbanCard;
