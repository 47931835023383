const convertNumbetToTime = (number, format = "am/pm") => {
  let time;
  let dateNow;
  if (isNaN(number)) {
    const startWithPattern = new RegExp(`^${"2000-01-"}.*$`, "i");
    if (startWithPattern.test(number)) {
      dateNow = new Date(number);
    } else {
      dateNow = new Date("2000-01-01T" + number);
    }
  } else {
    if (parseInt(number) < 86401) {
      let totalMinutes = Math.floor(number / 60);
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      dateNow = new Date("2000-01-01T" + hours + ":" + minutes);
    } else if (parseInt(number) > 9999999999) {
      dateNow = new Date(parseInt(number));
    } else {
      dateNow = new Date(parseInt(number) * 1000);
    }
  }

  let hours = dateNow.getHours();
  let minutes = dateNow.getMinutes();
  if (format === "am/pm") {
    if (hours >= 12) {
      hours = hours - 12;
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      time = hours + ":" + minutes + " PM";
    } else {
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      time = hours + ":" + minutes + " AM";
    }
  } else {
    let hours = dateNow.getHours();
    let minutes = dateNow.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    time = hours + ":" + minutes;
  }
  return time;
  // return dateNow.toLocaleTimeString();
};

const convertNumbetToDate = (
  number,
  format = "date",
  monthFormat = "string",
  dateFormat = ["yyyy", "mm", "dd"],
  spaceStyle = "-"
) => {
  let date = "";
  let dateNow;
  if (isNaN(number)) {
    dateNow = new Date(number);
  } else {
    if (parseInt(number) > 9999999999) {
      dateNow = new Date(parseInt(number));
    } else {
      dateNow = new Date(parseInt(number) * 1000);
    }
  }
  let day = dateNow.getDate();
  let monthIndex = dateNow.getMonth();
  let year = dateNow.getFullYear();

  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let month = monthIndex;
  if (monthFormat === "string") {
    month = monthNames[monthIndex];
  } else {
    month = month + 1;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
  }
  for (let i = 0; i < dateFormat.length; i++) {
    const element = dateFormat[i];
    if (i > 0) {
      date += spaceStyle;
    }
    if (element === "yyyy") {
      date += year;
    } else if (element === "mm") {
      date += month;
    } else if (element === "dd") {
      date += day;
    }
  }

  if (format === "datetime") {
    // date += " " + dateNow.toLocaleTimeString();
    let hours = dateNow.getHours();
    let minutes = dateNow.getMinutes();
    if (hours >= 12) {
      hours = hours - 12;
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      date += " " + hours + ":" + minutes + " PM";
    } else {
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      date += " " + hours + ":" + minutes + " AM";
    }
  }
  return date;
};

const stringToJSONChange = (value) => {
  try {
    if (!value || !value.trim()) {
      return {};
    }
    return JSON.parse(value);
  } catch (error) {
    return {};
  }
};

export { convertNumbetToTime, convertNumbetToDate, stringToJSONChange };
