import React from "react";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import { FSIconRenderer } from "../../../zinoIcon";

const KanbanButton = ({ itemConfig, itemType, reqData = {}, sm_id }) => {
  const { createNewTask } = usePageHandlerContext();
  const handleJobCallFunction = () => {
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig.action_start,
      action_end: itemConfig?.action_end
        ? itemConfig.action_end
        : { refresh: itemConfig.refresh_block ? [itemConfig.namespace] : [] },
      callback_function: [],
      callback_function_after: []
    };

    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
      sm_id: itemConfig.data_id || itemConfig?.action_data?.sm_id || sm_id,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    if (itemType === "single") {
      action_config["instance_id"] = reqData.id;
    } else if (itemType === "multi") {
      action_config["instance_ids"] = [reqData.id];
    }

    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData = {};
    } else {
      reqData = { ...reqData, sm_id: action_config.sm_id };
    }
    createNewTask(element_config, action_config, reqData);
  };
  let class_name = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      class_name = Object.values(itemConfig.className).join(" ");
    } else {
      class_name = itemConfig.className;
    }
  }

  return (
    <button
      className={`zino_btn_primary ${class_name}`}
      onClick={handleJobCallFunction}
      style={itemConfig.inline_style || {}}
    >
      <FSIconRenderer icon={itemConfig?.icon || ""} />
      {itemConfig?.name}
    </button>
  );
};

export default KanbanButton;
