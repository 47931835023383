import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "../../lib/tabviewlib/TabViewRenderer.css";

import MasterDataItem from "../MasterDataItem";
import { getApiCallLocalPath, getZinoDomainAppData, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData, getStorageItem } from "../../utils/storageFunction";
import { PopupBoxSection } from "../../lib";
import { CloseIconSvg } from "../../lib/zinoIcon";
import { MASTER_DATA_TABS } from "./Constants";

export const TABS = {
  learningText: "learning_text",
  custom: "custom",
  keyValue: "key_value",
  grid: "grid",
  colorConfig: "color_config"
};

const MasterDataPage = () => {
  const navigate = useNavigate();
  const [masterData, setMasterData] = useState([]);
  const [fileData, setFileData] = useState();
  const [isOpenExForm, setIsOpenExForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS.learningText);

  const getAllMasterData = async () => {
    try {
      let { data } = await axios.get(`${getApiCallLocalPath()}api/v1/masterdata`, { headers: getApiCallHeadersData() });

      setMasterData(data.data || []);
    } catch (error) {
      setMasterData([]);
      toast.error(error.response?.data?.message, toastErrorMessageStyle());
    }
  };

  const downloadAllMasterData = async () => {
    try {
      const response = await axios.get(`${getApiCallLocalPath()}api/v1/masterdata/download`, {
        headers: getApiCallHeadersData(),
        responseType: "blob"
      });
      toast.success("masterdata downloaded");
      const fileName = "masterdata.zip";
      saveAs(response.data, fileName);
    } catch (error) {
      toast.error(error?.message || "masterdata not downloaded");
    } finally {
    }
  };
  const uploadAllMasterData = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileData);

      await axios.post(`${getApiCallLocalPath()}api/v1/masterdata/upload`, formData, {
        headers: { ...getApiCallHeadersData(), "Content-Type": "multipart/form-data" }
      });
      toast.success("masterdata uploaded");
    } catch (error) {
      toast.error(error?.message || "masterdata not uploaded");
    } finally {
    }
  };

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || ${getStorageItem("Zino_app_name")}`;
    let appName = getStorageItem("Zino_app_name");
    if (appName) {
      getAllMasterData();
    } else {
      navigate(getStorageItem("Zino_app_user_go_to") || "/noaccess");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let selectedMasterData = null;

  if (masterData.length) {
    selectedMasterData = masterData?.filter((item) => item.type === selectedTab);
  }

  return (
    <section style={{ margin: "15px", height: "100%", overflowY: "auto" }}>
      <div style={{ padding: "0" }}>
        <div className="app_config_master_data">
          <span className="app_collapse_sub_heading">Master Data</span>
          <div style={{ display: "flex", gap: "12px" }}>
            <button className="zino_btn_outline_primary" onClick={downloadAllMasterData}>
              Download all MasterData
            </button>
            <button className="zino_btn_outline_primary" onClick={() => setIsOpenExForm(true)}>
              Upload all MasterData
            </button>
          </div>
        </div>
      </div>

      <div className="tabview_tabhead" style={{ fontWeight: "bold" }}>
        <div
          className={
            "tabview_tabhead_item" +
            (selectedTab === MASTER_DATA_TABS.learningText ? " tabview_tabhead_item_active " : "")
          }
          onClick={() => setSelectedTab(TABS.learningText)}
        >
          Learning Text
        </div>
        <div
          className={
            "tabview_tabhead_item" + (selectedTab === MASTER_DATA_TABS.custom ? " tabview_tabhead_item_active " : "")
          }
          onClick={() => setSelectedTab(TABS.custom)}
        >
          Custom
        </div>
        <div
          className={
            "tabview_tabhead_item" + (selectedTab === MASTER_DATA_TABS.keyValue ? " tabview_tabhead_item_active " : "")
          }
          onClick={() => setSelectedTab(TABS.keyValue)}
        >
          Key Value
        </div>
        <div
          className={
            "tabview_tabhead_item" + (selectedTab === MASTER_DATA_TABS.grid ? " tabview_tabhead_item_active " : "")
          }
          onClick={() => setSelectedTab(TABS.grid)}
        >
          Grid
        </div>
        <div
          className={
            "tabview_tabhead_item" +
            (selectedTab === MASTER_DATA_TABS.colorConfig ? " tabview_tabhead_item_active " : "")
          }
          onClick={() => setSelectedTab(TABS.colorConfig)}
        >
          Color Config
        </div>
      </div>

      <PopupBoxSection showPopup={isOpenExForm} className="slide-left-to-right">
        <div style={{ width: "50vw" }}>
          <div className="popupSection_header">
            <span className="popupSection_header_closeBTN" onClick={() => setIsOpenExForm(false)}>
              <CloseIconSvg />
            </span>
            <span className="popupSection_header_display"></span>
          </div>
          <div style={{ padding: "0 5px" }}>
            <div className="choose_file_outer">
              <div className="icon">
                <svg
                  fill={"#000000"}
                  height="48px"
                  width="48px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512.001 512.001"
                >
                  <g>
                    <g>
                      <path d="M344.381,143.771C254.765,56.017,102.37,103.776,79.825,227.7c-31.849,4.598-59.138,25.445-72.018,55.076 c-0.016,0.035-0.032,0.07-0.047,0.107c-26.687,61.602,18.784,130.232,85.51,130.232h282.267 c75.246,0,136.463-61.216,136.463-136.462C512,189.241,430.314,123.682,344.381,143.771z M375.537,381.12H93.271 c-69.246,0-84.534-98.263-18.714-119.456c14.753-4.65,43.01-7.348,74.38,21.892c6.464,6.024,16.586,5.667,22.61-0.794 c6.024-6.464,5.668-16.586-0.794-22.61c-17.93-16.712-38.071-27.33-58.484-31.453c22.034-99.077,147.374-131.851,215.247-56.305 c4.189,4.661,10.714,6.451,16.693,4.57c67.272-21.117,135.795,29.374,135.795,99.69 C480.005,334.256,433.141,381.12,375.537,381.12z" />
                    </g>
                  </g>
                </svg>
              </div>

              <input
                type="file"
                className="ff_file_field_button"
                multiple={false}
                onChange={(e) => {
                  setFileData(e.target.files[0]);
                }}
                style={{ width: "200px" }}
                accept=".zip"
              />
            </div>
          </div>
          <button className="zino_btn_primary" style={{ margin: "15px auto" }} onClick={() => uploadAllMasterData()}>
            Upload
          </button>
        </div>
      </PopupBoxSection>

      {selectedMasterData ? (
        <table className="recordview_table">
          <tbody>
            {selectedMasterData.map((masterData) => (
              <tr key={masterData.id}>
                <MasterDataItem master_data={masterData} type={selectedTab} />
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        "No data found!"
      )}
    </section>
  );
};

export default MasterDataPage;
