import React from "react";
import "./Tilesview.css";
import NumberFormater from "../components/NumberFormater";
import { FSIconRenderer } from "../zinoIcon";
import { stringToJSONChange } from "../utils/commonFunction";

const Tilesview = ({ tilesArray, tilesData }) => {
  return (
    <div className="pageView_item_outer">
      <div className="tilesview-outerbox">
        {tilesArray.map((tile) => {
          let tileData = tilesData?.[tile?.id] ?? "";

          let class_name = "";
          if (tile.className) {
            if (typeof tile.className === "object") {
              class_name = Object.values(tile.className).join(" ");
            } else {
              class_name = tile.className;
            }
          }
          return (
            <div
              key={tile.id}
              style={{ ...(stringToJSONChange(tile?.custom_css) || {}) }}
              className={`tilesview-itembox tilesview-1 ${class_name} ${tile?.custom_classname || ""}`}
              id={`${tile?.custom_id || ""}`}
            >
              {tile.icon && (
                <div className="tilesview-icon">
                  <FSIconRenderer icon={tile.icon} iconTitle="Remove" marginR="0px" />
                </div>
              )}
              {tile.title && <div className="tilesview-title">{tile.title}</div>}
              {tileData !== "" && (
                <div className="tilesview-data">
                  <NumberFormater number={tileData} type={tile.format_type} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tilesview;
