import React, { useLayoutEffect } from "react";
import "./KanbanRenderer.css";
import KanbanBoard from "./components/KanbanBoard";
import { getKanbanData } from "../../redux/kanbanview/kanbanviewaction";
import { useDispatch } from "react-redux";

const KanbanRenderer = ({ configData }) => {
  let namespace = configData?.id;

  const dispatch = useDispatch();
  const layout = JSON.parse(JSON.stringify(configData.config.data.elements));

  useLayoutEffect(() => {
    dispatch(getKanbanData({ namespace: namespace }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namespace]);
  if (namespace) {
    return (
      <KanbanBoard
        statesList={configData.config.data.states}
        transitionsList={configData.config.data.transitions}
        namespace={namespace}
        sm_id={configData.config.table_id}
        layout={layout}
      />
    );
  } else {
    return <div>Wait....</div>;
  }
};

export default KanbanRenderer;
