import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { setSortOrder, setSortOrderBy } from "../../../../redux/recordview/recordviewaction";
import { useDispatch, useSelector } from "react-redux";
import TableToolbar from "./TableToolbar";
import TableHeadField from "./TableHeadField";
import TableDataViewerField from "./TableDataViewerField";
import { commonFunction } from "../../utils/commonFunction";
import TableFooter from "./TableFooter";
import { ChackBoxSvg } from "../../../zinoIcon";
import DetailsViewItem from "../../../detailsviewlib/DetailsViewItem";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../../utils/storageFunction";
import toast from "react-hot-toast";
import { setHideComponentKeyInConfig } from "../../../../utils/updateConfigFunction";
import { getUpdatedCacheProcessData, usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import PageView from "../../../../components/PageView";

export default function TableField({
  namespace,
  tableData,
  tableDataHeader,
  tableDataViewer,
  recordviewDataConfig,
  setColumnsList,
  chackboxflag,
  table_id,
  catalogFlag,
  recordviewTableData,
  functionObject
}) {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.recordviewstore);
  let sortOrder = storeData?.[`${namespace}_sortOrder`] || "asc";
  let sortOrderBy = storeData?.[`${namespace}_sortOrderBy`] || "";
  let tablePageNo = storeData?.[`${namespace}_tablePageNo`];
  let tableRowsPerPage = storeData?.[`${namespace}_tableRowsPerPage`];
  let recordviewDataConfigLoadingFlag = storeData?.[`${namespace}_recordviewDataConfigLoadingFlag`] || false;

  const { createNewTask } = usePageHandlerContext();

  const rv_layout_type = recordviewDataConfig?.layout_type || "table_view";

  const [selectedRowList, setSelectedRowList] = useState([]);
  const refFlag = useRef(false);
  useLayoutEffect(() => {
    setSelectedRowList([]);
  }, [sortOrderBy, sortOrder, tablePageNo, tableRowsPerPage]);

  useLayoutEffect(() => {
    if (!refFlag.current) {
      refFlag.current = true;
      return;
    }

    if (namespace === "namespace") {
      return;
    } else {
      if (sortOrderBy) commonFunction.callGetRecordviewTableDataBySort(namespace, `${sortOrderBy}:${sortOrder}`);
      else commonFunction.callGetRecordviewTableDataBySort(namespace, "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrderBy, sortOrder]);

  const handleRequestSort = (event, property) => {
    if (namespace === "namespace") {
      return;
    }
    const isAsc = sortOrderBy === property && sortOrder === "asc";
    dispatch(setSortOrder(namespace, isAsc ? "desc" : "asc"));
    if (sortOrderBy === property && sortOrder === "desc") dispatch(setSortOrderBy(namespace, ""));
    else dispatch(setSortOrderBy(namespace, property));
  };

  const handleSelectAllClick = (flag) => {
    if (namespace === "namespace") {
      return;
    }
    if (flag) {
      let newSelected = [];
      let index = tablePageNo * tableRowsPerPage;
      let lastIndex = Math.min(index + tableRowsPerPage, tableData.length);
      for (let i = index; i < lastIndex; i++) {
        const element = tableData[i];
        newSelected.push(element.id);
      }
      setSelectedRowList(newSelected);
    } else {
      setSelectedRowList([]);
    }
  };

  const handleClick = (checked, name) => {
    if (namespace === "namespace") {
      return;
    }

    const selectedRowListIndex = selectedRowList.indexOf(name);
    let newSelected = [];

    if (selectedRowListIndex === -1) {
      newSelected = newSelected.concat(selectedRowList, name);
    } else if (selectedRowListIndex === 0) {
      newSelected = newSelected.concat(selectedRowList.slice(1));
    } else if (selectedRowListIndex === selectedRowList.length - 1) {
      newSelected = newSelected.concat(selectedRowList.slice(0, -1));
    } else if (selectedRowListIndex > 0) {
      newSelected = newSelected.concat(
        selectedRowList.slice(0, selectedRowListIndex),
        selectedRowList.slice(selectedRowListIndex + 1)
      );
    }

    setSelectedRowList(newSelected);
  };

  const handleChangePage = (newPage) => {
    if (namespace === "namespace") {
      return;
    }
    commonFunction.callGetRecordviewTableDataByPage(namespace, "page", newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    if (namespace === "namespace") {
      return;
    }
    commonFunction.callGetRecordviewTableDataByPage(namespace, "page_size", parseInt(value, 10));
  };

  const isSelected = (name) => selectedRowList.indexOf(name) !== -1;

  // let emptyRows = tableRowsPerPage - tableData.length;
  let emptyRows = 0;
  if (tableData.length < 5) {
    emptyRows = 5 - tableData.length;
  }

  let indeterminateFlag = false;
  let checkedFlag = false;
  let notSelectedRowListCount = tableRowsPerPage - ((1 + tablePageNo) * tableRowsPerPage - tableData.length);
  if (selectedRowList.length === 0) {
  } else if (Math.min(notSelectedRowListCount, tableRowsPerPage) === selectedRowList.length) checkedFlag = true;
  else if (selectedRowList.length > 0) indeterminateFlag = true;

  let recordviewTableboxOverflowboxStyle = {};
  if (
    recordviewDataConfig.multi_select_custom_actions.length === 0 &&
    recordviewDataConfig.onscreen_filters.length === 0
  ) {
    recordviewTableboxOverflowboxStyle = {
      // borderTop: "none",
      // borderRadius: "0 0 8px 8px"
    };
  }

  const rowOnClickActionCall = async (reqData) => {
    let itemConfig = recordviewDataConfig.on_row_action;

    if (!itemConfig?.job_template) {
      return;
    }
    let element_config = {
      element_id: recordviewDataConfig.id,
      action_start: {},
      action_end: {},
      callback_function: [],
      callback_function_after: []
    };

    let action_config = {
      action_in: itemConfig?.job_template,
      navigate_to: itemConfig?.params?.name || "",
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template,
      sm_id: itemConfig?.params?.sm_id || itemConfig?.params?.entity_id || recordviewDataConfig.table_id,
      activity_id: itemConfig?.params?.activity_id || itemConfig?.params?.activity_id_init,
      params: itemConfig?.params || {}
    };

    action_config["instance_id"] = reqData.instance_id || reqData.id || reqData._id || null;

    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData = {};
    } else if (["perform_activity_ai"].includes(action_config.action_name)) {
    } else if (["perform_record_ai"].includes(action_config.action_name)) {
    } else {
      reqData = { ...reqData, sm_id: recordviewDataConfig.table_id };
    }

    createNewTask(element_config, action_config, reqData);
  };

  return (
    <div className="recordview_table_outer" style={recordviewTableboxOverflowboxStyle}>
      {recordviewDataConfig.hide_header ? (
        ""
      ) : (
        <>
          {namespace !== "namespace" && !["catalog_view"].includes(rv_layout_type) && (
            <TableToolbar
              numSelected={selectedRowList.length}
              selectedRowList={selectedRowList}
              setSelectedRowList={setSelectedRowList}
              namespace={namespace}
              recordviewTableData={recordviewTableData}
              recordviewDataConfig={recordviewDataConfig}
              multiSelectCustomActions={recordviewDataConfig.multi_select_custom_actions}
              table_id={table_id}
              tableDataHeader={tableDataHeader}
              functionObject={functionObject}
            />
          )}
        </>
      )}

      {catalogFlag ? (
        recordviewDataConfig?.catalog?.elements.length > 0 && (
          <>
            {tableData.length === 0 && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  padding: "16px"
                }}
              >
                No Records Found
              </div>
            )}
            {/* <CatalogViewWithScreen
              recordviewDataConfig={recordviewDataConfig}
              tableData={tableData}
              checkbox={chackboxflag}
              handleCheckboxClick={handleClick}
              selectedItems={selectedRowList}
            /> */}
            <div
              style={
                recordviewDataConfigLoadingFlag
                  ? { overflow: "hidden", opacity: "0.5", pointerEvents: "none", position: "relative" }
                  : {}
              }
            >
              {recordviewDataConfigLoadingFlag && (
                <div
                  className="popup_loading_overlay"
                  style={{ position: "absolute", height: "100%", width: "100%", background: "transparent" }}
                >
                  <div className="popup_loading_box"></div>
                </div>
              )}
              <CatalogView
                recordviewDataConfig={recordviewDataConfig}
                tableData={tableData}
                checkbox={chackboxflag}
                handleCheckboxClick={handleClick}
                selectedItems={selectedRowList}
                functionObject={functionObject}
                rv_layout_type={rv_layout_type}
              />
            </div>
            {namespace !== "namespace" && !["catalog_view"].includes(rv_layout_type) && (
              <TableFooter
                rowsPerPageOptions={recordviewDataConfig?.page_size || [tableRowsPerPage || 25]}
                dataCount={recordviewTableData.total_documents}
                rowsPerPage={tableRowsPerPage}
                page={tablePageNo}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </>
        )
      ) : (
        <>
          {tableData.length === 0 && (
            <div
              style={{
                width: "100%",
                position: "absolute",
                textAlign: "center",
                fontSize: "16px",
                padding: "16px",
                bottom: "100px",
                zIndex: "1"
              }}
            >
              No Records Found
            </div>
          )}
          <div
            className="recordview_table_wraper"
            style={recordviewDataConfigLoadingFlag ? { overflow: "hidden", opacity: "0.5", pointerEvents: "none" } : {}}
          >
            <table className="recordview_table">
              <TableHeadField
                indeterminateFlag={indeterminateFlag}
                checkedFlag={checkedFlag}
                sortOrder={sortOrder}
                sortOrderBy={sortOrderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                tableDataHeader={tableDataHeader}
                setColumnsList={setColumnsList}
                chackboxflag={chackboxflag}
              />

              {tableDataViewer.length > 0 ? (
                <tbody>
                  {tableData.map((row, index) => {
                    // if data more than row par page size
                    if (index >= tableRowsPerPage) return null;

                    const isItemSelected = isSelected(row.id || row._id);

                    return (
                      <tr
                        onClick={() => {
                          rowOnClickActionCall(row);
                        }}
                        key={index}
                        className={isItemSelected ? "table_row_selected" : ""}
                      >
                        {chackboxflag && (
                          <td className="table_checkbox">
                            <span>
                              <ChackBoxSvg
                                fill="var(--primary-color)"
                                checked={isItemSelected}
                                onSelectAllClick={(checked) => handleClick(checked, row.id || row._id)}
                              />
                            </span>
                          </td>
                        )}
                        {tableDataViewer.map((itemIs, index) => (
                          <TableDataViewerField
                            key={index}
                            itemIs={itemIs}
                            row={row}
                            index={index}
                            namespace={namespace}
                            chackboxflag={chackboxflag}
                            table_id={table_id}
                            functionObject={functionObject}
                          />
                        ))}
                      </tr>
                    );
                  })}
                  {emptyRows > 0 && (
                    <tr
                      style={{
                        height: `${50 * emptyRows}px`
                      }}
                    ></tr>
                  )}
                </tbody>
              ) : (
                <tbody
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <tr style={{ display: "flex" }}>
                    <td
                      style={{
                        border: "0",
                        display: "grid",
                        placeContent: "center"
                      }}
                    >
                      Add Table Field
                    </td>
                  </tr>
                </tbody>
              )}
            </table>

            {recordviewDataConfigLoadingFlag && (
              <div
                className="popup_loading_overlay"
                style={{ position: "absolute", height: "100%", width: "100%", background: "transparent" }}
              >
                <div className="popup_loading_box"></div>
              </div>
            )}
          </div>

          {namespace !== "namespace" && (
            <TableFooter
              rowsPerPageOptions={recordviewDataConfig?.page_size || [tableRowsPerPage || 25]}
              dataCount={recordviewTableData.total_documents}
              rowsPerPage={tableRowsPerPage}
              page={tablePageNo}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </div>
  );
}

const CatalogView = ({
  recordviewDataConfig,
  tableData,
  checkbox,
  handleCheckboxClick,
  selectedItems,
  functionObject,
  rv_layout_type
}) => {
  let catalogConfig = recordviewDataConfig.catalog;

  const [selectEmbeddedDetailviewItem, setselectEmbeddedDetailviewItem] = useState();
  const [selectEmbeddedDetailviewData, setselectEmbeddedDetailviewData] = useState();
  const [selectEmbeddedDetailviewConfig, setselectEmbeddedDetailviewConfig] = useState();
  const [embeddedDetailviewDataLoad, setEmbeddedDetailviewDataLoad] = useState(false);

  let catalog_in_row = 3;
  let openEmbeddedDetailview = false;
  if (catalogConfig?.catalog_in_row) {
    catalog_in_row = catalogConfig?.catalog_in_row;
  }
  if (["embedded"].includes(rv_layout_type)) {
    catalog_in_row = 1;
    openEmbeddedDetailview = true;
  }

  const apiCallFunction = async (processData) => {
    if (!processData || !catalogConfig?.embedded_detailview?.detailview_id) return;
    setselectEmbeddedDetailviewItem(processData.id);
    try {
      setEmbeddedDetailviewDataLoad(true);
      let configObject = {
        data: {},
        function_name: "show_detailsview_popup",
        params: {
          detailsview_id: catalogConfig.embedded_detailview.detailview_id,
          front_end: {
            params_config: {
              sm_id: recordviewDataConfig.table_id,
              instance_id: processData.id,
              detailsview_id: catalogConfig.embedded_detailview.detailview_id
            },
            action_config: {
              action_in: "show_detailsview_popup",
              action_name: "show_detailsview_popup",
              sm_id: recordviewDataConfig.table_id,
              params: {
                detailsview_id: catalogConfig.embedded_detailview.detailview_id
              },
              instance_id: processData.id
            }
          }
        }
      };

      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });

      data = data?.data?.detailsview_config?.data;

      if (data?.detailsview_data) {
        let detailsview_data = data?.detailsview_data;
        detailsview_data.timeline_data?.reverse();

        setselectEmbeddedDetailviewData(detailsview_data);
      }
      setselectEmbeddedDetailviewConfig({
        elements: data?.elements || [],
        data_fields: data?.data_fields || [],
        activity_cards: data?.activity_cards || {},
        sm_id: recordviewDataConfig.table_id,
        instance_id: processData.id
      });
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setEmbeddedDetailviewDataLoad(false);
    }
  };

  useEffect(() => {
    if (catalogConfig?.embedded_detailview?.detailview_id && !selectEmbeddedDetailviewData) {
      apiCallFunction(tableData?.[0] || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  if (openEmbeddedDetailview) {
    return (
      <div className={"embedded_detailview_outer_box"}>
        <div className="embedded_detailview_items">
          {tableData.map((row, index) => {
            let styleObj = { cursor: "pointer" };
            if (selectEmbeddedDetailviewItem !== row.id) {
              styleObj["opacity"] = "0.3";
            }
            return (
              <div
                key={index}
                onClick={() => {
                  apiCallFunction(row);
                }}
                style={styleObj}
              >
                <DetailsViewItem
                  itemConfig={recordviewDataConfig.catalog.elements}
                  activityCardsConfig={{}}
                  detailsViewDataFormData={row || {}}
                  detailsViewDataTimeline={[]}
                  detailsViewDataActivityCard={[]}
                  fieldLabelFlag={false}
                  detailsViewItemConfig={recordviewDataConfig.catalog}
                  printThis={false}
                  jobParamsConfig={{
                    sm_id: recordviewDataConfig.table_id,
                    instance_id: row.id || row._id || null
                  }}
                  functionObject={functionObject}
                />
              </div>
            );
          })}
        </div>
        <div className="embedded_detailview_items">
          {embeddedDetailviewDataLoad ? (
            <div className="zino_renderer_loader_box" style={{ height: "100%" }}>
              <span className="zino_renderer_loader"></span>
            </div>
          ) : selectEmbeddedDetailviewData && selectEmbeddedDetailviewConfig ? (
            <DetailsViewItem
              itemConfig={selectEmbeddedDetailviewConfig?.elements}
              activityCardsConfig={selectEmbeddedDetailviewConfig?.activity_cards}
              fieldLabelFlag={false}
              detailsViewItemConfig={selectEmbeddedDetailviewConfig}
              printThis={false}
              detailsViewDataFormData={selectEmbeddedDetailviewData?.form_data || {}}
              detailsViewDataTimeline={selectEmbeddedDetailviewData?.timeline_data || []}
              detailsViewDataActivityCard={selectEmbeddedDetailviewData?.activity_card_data || []}
              detailsViewDataActivitiesHistory={selectEmbeddedDetailviewData?.allowed_activities_history || {}}
              jobParamsConfig={{
                sm_id: selectEmbeddedDetailviewConfig.sm_id,
                instance_id: selectEmbeddedDetailviewConfig.instance_id
              }}
              functionObject={functionObject}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${catalog_in_row}, 1fr)`
        }}
      >
        {tableData.map((row, index) => {
          return (
            <DetailsViewItem
              key={index}
              itemConfig={recordviewDataConfig.catalog.elements}
              activityCardsConfig={{}}
              detailsViewDataFormData={row || {}}
              detailsViewDataTimeline={[]}
              detailsViewDataActivityCard={[]}
              fieldLabelFlag={false}
              detailsViewItemConfig={recordviewDataConfig.catalog}
              printThis={false}
              jobParamsConfig={{
                sm_id: recordviewDataConfig.table_id,
                instance_id: row.id || row._id || null
              }}
              checkbox={checkbox}
              handleCheckboxClick={handleCheckboxClick}
              selectedItems={selectedItems}
            />
          );
        })}
      </div>
    );
  }
};

// eslint-disable-next-line no-unused-vars
const CatalogViewWithScreen = ({ recordviewDataConfig, tableData, checkbox, handleCheckboxClick, selectedItems }) => {
  let catalogConfig = recordviewDataConfig.catalog;

  const [selectEmbeddedDetailviewItem, setselectEmbeddedDetailviewItem] = useState();
  const [selectEmbeddedDetailviewData, setselectEmbeddedDetailviewData] = useState();
  const [embeddedDetailviewDataLoad, setEmbeddedDetailviewDataLoad] = useState(false);

  let catalog_in_row = 3;
  let openEmbeddedDetailview = false;
  if (catalogConfig?.catalog_in_row) {
    catalog_in_row = catalogConfig?.catalog_in_row;
  }
  if (catalogConfig?.embedded_detailview?.detailview_id) {
    catalog_in_row = 1;
    openEmbeddedDetailview = true;
  }

  const apiCallFunction = async (processData) => {
    if (!processData || !catalogConfig?.embedded_detailview?.detailview_id) return;
    setselectEmbeddedDetailviewItem(processData.id);
    try {
      setEmbeddedDetailviewDataLoad(true);
      let action_config = {
        params: {
          variable_mapping: []
        }
      };

      const token_data = getUpdatedCacheProcessData(
        { ...processData, sm_id: recordviewDataConfig.table_id, instance_id: processData.id },
        action_config
      );
      let apiPayload = {
        data: "",
        function_name: "get_screen",
        params: {
          device: "desktop",
          name: "S 1 DV",
          token_data: token_data
        }
      };

      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, apiPayload, {
        headers: getApiCallHeadersData()
      });
      data = data.data;
      data.layout = await setHideComponentKeyInConfig(data.layout);

      let res = {
        type: "screen",
        screen_config: data,
        token_data: token_data
      };
      setselectEmbeddedDetailviewData(res);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setEmbeddedDetailviewDataLoad(false);
    }
  };

  useEffect(() => {
    if (catalogConfig?.embedded_detailview?.detailview_id && !selectEmbeddedDetailviewData) {
      apiCallFunction(tableData?.[0] || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  if (openEmbeddedDetailview) {
    return (
      <div className={"embedded_detailview_outer_box"}>
        <div>
          {tableData.map((row, index) => {
            let styleObj = { cursor: "pointer" };
            if (selectEmbeddedDetailviewItem !== row.id) {
              styleObj["opacity"] = "0.3";
            }
            return (
              <div
                key={index}
                onClick={() => {
                  apiCallFunction(row);
                }}
                style={styleObj}
              >
                <DetailsViewItem
                  itemConfig={recordviewDataConfig.catalog.elements}
                  activityCardsConfig={{}}
                  detailsViewDataFormData={row || {}}
                  detailsViewDataTimeline={[]}
                  detailsViewDataActivityCard={[]}
                  fieldLabelFlag={false}
                  detailsViewItemConfig={recordviewDataConfig.catalog}
                  printThis={false}
                  jobParamsConfig={{
                    sm_id: recordviewDataConfig.table_id,
                    instance_id: row.id || row._id || null
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          {embeddedDetailviewDataLoad ? (
            <div className="zino_renderer_loader_box" style={{ height: "100%" }}>
              <span className="zino_renderer_loader"></span>
            </div>
          ) : selectEmbeddedDetailviewData ? (
            <PageView
              pageConfig={selectEmbeddedDetailviewData?.screen_config?.layout || []}
              callFrom="outside"
              jobParamsTokenConfig={selectEmbeddedDetailviewData?.token_data || {}}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${catalog_in_row}, 1fr)`
        }}
      >
        {tableData.map((row, index) => {
          return (
            <DetailsViewItem
              key={index}
              itemConfig={recordviewDataConfig.catalog.elements}
              activityCardsConfig={{}}
              detailsViewDataFormData={row || {}}
              detailsViewDataTimeline={[]}
              detailsViewDataActivityCard={[]}
              fieldLabelFlag={false}
              detailsViewItemConfig={recordviewDataConfig.catalog}
              printThis={false}
              jobParamsConfig={{
                sm_id: recordviewDataConfig.table_id,
                instance_id: row.id || row._id || null
              }}
              checkbox={checkbox}
              handleCheckboxClick={handleCheckboxClick}
              selectedItems={selectedItems}
            />
          );
        })}
      </div>
    );
  }
};
