import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import UpdateExcel from "../components/admin/UpdateExcel";
import MasterDataPage from "../components/admin/MasterDataPage";
import LocationRenderer from "../components/admin/LocationRenderer";
import DownloadsPage from "../components/admin/DownloadsPage";
import DownloadAssets from "../components/admin/Assets";
import DisplayMetrics from "../components/admin/Metrics";
import { getStorageItem } from "../utils/storageFunction";
import { useNavigate, useParams } from "react-router-dom";
import AppPDFFilesList from "../components/admin/AppPDFFilesList";
import ActionButtons from "../components/admin/ActionButtons";
import { getApiCallLocalPath, getZinoDomainAppData } from "../utils/apiCallFunction";
import { adminNavbarConfig } from "../config/adminConfig";
import UserManagement from "./Users/UserManagement";
import UserGroup from "./Users/UserGroup";
import OrgStructure from "./Users/OrgStructure";
import DownloadExportFilesPage from "../components/admin/DownloadExportFilesPage";

const AppConfigPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    if (params.id) {
      setSelectedTab(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || ${getStorageItem("Zino_app_name")}`;
    let appName = getStorageItem("Zino_app_name");
    if (!appName) {
      navigate(getStorageItem("Zino_app_user_go_to") || "/noaccess");
    }
    let appIsPublic = getStorageItem("Zino_app_is_public");
    if (!appIsPublic || appIsPublic.toString() !== "true") {
      navigate(getStorageItem("Zino_app_user_go_to") || "/noaccess");
    }

    const cssRoot = document.getElementById("cssroot");
    if (!cssRoot.innerHTML) {
      let theme_id = getStorageItem("Zino_app_theme_id");
      const styleFlie = document.createElement("link");
      styleFlie.href = `${getApiCallLocalPath()}api/v1/assets/${theme_id}.css`;
      styleFlie.rel = "stylesheet";
      cssRoot.innerHTML = "";
      cssRoot.appendChild(styleFlie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabContent = () => {
    switch (selectedTab) {
      case "downloads":
        return <DownloadsPage />;
      case "excel":
        return <UpdateExcel />;
      case "export-files":
        return <DownloadExportFilesPage />;
      case "masterdata":
        return <MasterDataPage />;
      case "location":
        return <LocationRenderer />;
      case "assets":
        return <DownloadAssets />;
      case "metrics":
        return <DisplayMetrics />;
      case "pdflist":
        return <AppPDFFilesList />;
      case "action-buttons":
        return <ActionButtons />;
      case "user":
        return <UserManagement />;
      case "group":
        return <UserGroup />;
      case "org":
        return <OrgStructure />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Navbar headerConfig={adminNavbarConfig()} />
      <div className={"tabview_renderer_outerbox app_config_tabview_renderer_outerbox"}>
        <div className="app_config_tabview_body">{renderTabContent()}</div>
      </div>
    </>
  );
};

export default AppConfigPage;
