import React, { useEffect, useState } from "react";
import "./Navbar.css";
import MenuItemPage from "./menuview/MenuItemPage";
import MenuItemDropdown from "./menuview/MenuItemDropdown";
import { useNavigate, useParams } from "react-router";
import NotificationItem from "./menuview/NotificationItem";
import { BellIconSvg, CloseIconSvg, MenuIconSvg, UserIconSvg } from "../lib/zinoIcon";
import { useDispatch, useSelector } from "react-redux";
import { callUserLogoutFunction } from "../redux/userview/userviewaction";
import { getApiCallHeadersData, getStorageItem } from "../utils/storageFunction";
import axios from "axios";
import toast from "react-hot-toast";
import Cart from "../lib/Cart/Cart";
import Chat from "./chatbox/Chat";

function Navbar({ headerConfig, rootType = "private" }) {
  const { pagename } = useParams();
  const navigate = useNavigate();
  const [notificationsPopupFlag, setNotificationsPopupFlag] = useState(false);
  const [leftMenuPopupFlag, setLeftMenuPopupFlag] = useState(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);

  useEffect(() => {
    setLeftMenuPopupFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagename]);

  const handleOpenNotifications = (flag) => {
    setNotificationsPopupFlag(flag);
  };

  const handleChangePasswordPopup = () => {
    setShowChangePasswordPopup(true);
  };

  if (headerConfig?.style?.nav_position === "left") {
    if (headerConfig?.style?.nav_fixed) {
      return (
        <>
          <div
            className={`mobileview_top_menu_popup mobileview_top_menu_popupopen`}
            style={{
              position: "sticky",
              top: "0",
              left: "0",
              zIndex: "10"
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mobileview_top_menu_close" style={{ justifyContent: "center" }}>
              <div
                onClick={() =>
                  navigate(headerConfig?.logo?.lastpage || `/${headerConfig?.menu?.[0]?.display || "applist"}`)
                }
                className="zinologo"
              >
                {headerConfig?.logo?.logosrc ? (
                  <img
                    src={headerConfig.logo.logosrc}
                    alt={headerConfig.logo.logostr || ""}
                    style={{
                      height: `${headerConfig?.["logo"]?.["logosize"] || 40}px`
                    }}
                  />
                ) : (
                  headerConfig?.logo?.logotext && (
                    <div
                      className="zinologo_text"
                      style={{
                        fontSize: `${headerConfig?.["logo"]?.["logotextsize"] || 20}px`,
                        alignItems: headerConfig?.["logo"]?.["logoalign"] || "center",
                        color: headerConfig?.["logo"]?.["logotextcolor"]
                      }}
                    >
                      {headerConfig.logo.logotext}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="navBar_menuItems_outer">
              {/* style={{ height: "calc(100vh - 206px)", overflow: "hidden auto" }} */}
              <div className="top_menu_outerBox top_menu">
                {headerConfig?.menu.map((menuItem, index) => {
                  if (menuItem.type === "page") {
                    return <MenuItemPage key={index} menuItem={menuItem} />;
                  } else if (menuItem.type === "dropdown") {
                    return <MenuItemDropdown key={index} menuItem={menuItem} styleObj={{ width: "100%" }} />;
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>

            <div className="mobileview_top_menu_footer">
              <div>
                <Cart />
              </div>

              <div className="notification_container" onClick={() => handleOpenNotifications(true)}>
                <div title="Open notifications" className="nav_icon_outer">
                  <BellIconSvg fill={headerConfig?.notifications_count > 0 ? "#10182B" : "#667085"} />
                </div>
                <span
                  className="navbar_notification"
                  style={{
                    color: headerConfig?.notifications_count > 0 ? "#10182B" : ""
                  }}
                >
                  Notifications
                  <span
                    className="nav_icon_count"
                    style={{
                      marginLeft: "10px",
                      borderBottom: "2px solid red",
                      padding: "0 10px",
                      color: "red",
                      display: headerConfig?.notifications_count > 0 ? "" : "none"
                    }}
                  >
                    {headerConfig?.notifications_count || 0}
                  </span>
                </span>
                <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
              </div>
              <Chat nav_pos="left" />

              <div className="profile_dropdown">
                <div title="Open profile" className="nav_icon_outer">
                  <div className="navbar_user_icon">
                    <UserIconSvg />
                  </div>
                </div>
                {}
                <span className="navbar_user_profile">Profile</span>
                {rootType === "public" ? (
                  <UserPopupPublic />
                ) : (
                  <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
                )}
              </div>
            </div>
          </div>
          {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
        </>
      );
    } else {
      return (
        <>
          <nav
            className="app_navbar"
            style={{
              flexDirection: "column",
              height: "100vh",
              padding: "16px",
              position: "sticky",
              top: "0"
            }}
          >
            <div
              title="Open menu"
              className="nav_icon_outer"
              onClick={() => {
                setLeftMenuPopupFlag(true);
              }}
            >
              <MenuIconSvg />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px"
              }}
              className="leftMenu_bottom_icon"
            >
              <div>
                <Cart />
              </div>

              <div className="notification_container">
                <div
                  title="Open notifications"
                  className="nav_icon_outer"
                  onClick={() => handleOpenNotifications(true)}
                >
                  <span
                    style={{
                      padding: headerConfig?.notifications_count > 9 ? "3px" : "",
                      display: headerConfig?.notifications_count > 0 ? "" : "none"
                    }}
                  >
                    {headerConfig?.notifications_count > 9 ? "9+" : headerConfig?.notifications_count || 0}
                  </span>
                  <BellIconSvg fill={headerConfig?.notifications_count > 0 ? "#10182B" : "#667085"} />
                </div>
                <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
              </div>

              <div className="profile_dropdown_container">
                <div className="profile_dropdown">
                  <div title="Open profile" className="nav_icon_outer">
                    <UserIconSvg />
                  </div>
                  {rootType === "public" ? (
                    <UserPopupPublic />
                  ) : (
                    <UserPopupPrivate
                      handleChangePasswordPopup={handleChangePasswordPopup}
                      styleObject={{ top: "-245px", left: "-12px" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </nav>
          {leftMenuPopupFlag &&
            BuildPageListFunction(
              leftMenuPopupFlag,
              setLeftMenuPopupFlag,
              headerConfig,
              headerConfig?.menu,
              navigate,
              (rootType = { rootType }),
              notificationsPopupFlag,
              handleOpenNotifications,
              handleChangePasswordPopup
            )}
          {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
        </>
      );
    }
  } else if (headerConfig?.style?.nav_position === "top-left" || headerConfig?.style?.nav_position === "top-right") {
    const isTopLeft = headerConfig?.style?.nav_position === "top-left";
    const alignStyle = isTopLeft ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" };

    return (
      <>
        <nav
          className="app_navbar top_navbar"
          style={{
            position: headerConfig?.style?.nav_fixed ? "sticky" : "relative",
            ...alignStyle
          }}
        >
          <div className="app_navbar_logo_wrap">
            {isTopLeft && (
              <div className="mobileview_top_menu">
                <div
                  title="Open menu"
                  className="nav_icon_outer"
                  onClick={() => {
                    setLeftMenuPopupFlag(true);
                  }}
                >
                  <MenuIconSvg />
                </div>
              </div>
            )}
            <div
              onClick={() =>
                navigate(headerConfig?.logo?.lastpage || `/${headerConfig?.menu?.[0]?.display || "applist"}`)
              }
              className="zinologo"
            >
              {headerConfig?.logo?.logosrc ? (
                <img
                  style={{
                    height: `${headerConfig?.["logo"]?.["logosize"] || 40}px`
                  }}
                  src={headerConfig.logo.logosrc}
                  alt={headerConfig.logo.logostr || ""}
                />
              ) : (
                headerConfig?.logo?.logotext && (
                  <div
                    className="zinologo_text"
                    style={{
                      fontSize: `${headerConfig?.["logo"]?.["logotextsize"] || 20}px`,
                      alignItems: headerConfig?.["logo"]?.["logoalign"] || "center",
                      color: headerConfig?.["logo"]?.["logotextcolor"]
                    }}
                  >
                    {headerConfig.logo.logotext}
                  </div>
                )
              )}
            </div>
            {!isTopLeft && (
              <div className="mobileview_top_menu">
                <div
                  title="Open menu"
                  className="nav_icon_outer"
                  onClick={() => {
                    setLeftMenuPopupFlag(true);
                  }}
                >
                  <MenuIconSvg />
                </div>
              </div>
            )}
          </div>

          <div
            className="nav_menu_alignment"
            style={{
              justifyContent: headerConfig?.style?.nav_position === "top-left" ? "space-between" : "",
              width: headerConfig?.style?.nav_position === "top-left" ? "100%" : "auto",
              marginLeft: headerConfig?.style?.nav_position === "top-right" ? "auto" : "20px"
            }}
          >
            <div className="top_menu_outerBox top_menu desktopview_top_menu">
              {headerConfig?.menu?.map((menuItem, index) => {
                if (menuItem.type === "page") {
                  return <MenuItemPage key={index} menuItem={menuItem} />;
                } else if (menuItem.type === "dropdown") {
                  return <MenuItemDropdown key={index} menuItem={menuItem} />;
                } else {
                  return null;
                }
              })}
            </div>

            <div className="nav_icons">
              <div>
                <Cart />
              </div>
              <div className="notification_container">
                <div
                  title="Open notifications"
                  className="nav_icon_outer"
                  onClick={() => handleOpenNotifications(true)}
                >
                  <span
                    style={{
                      padding: headerConfig?.notifications_count > 9 ? "3px" : "",
                      display: headerConfig?.notifications_count > 0 ? "" : "none"
                    }}
                  >
                    {headerConfig?.notifications_count > 9 ? "9+" : headerConfig?.notifications_count || 0}
                  </span>
                  <BellIconSvg fill={headerConfig?.notifications_count > 0 ? "#10182B" : "#667085"} />
                </div>
                <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
              </div>
              <Chat nav_pos="top" />

              <div className="profile_dropdown_container">
                <div className="profile_dropdown">
                  <div title="Open profile" className="nav_icon_outer">
                    <UserIconSvg />
                  </div>
                  {rootType === "public" ? (
                    <UserPopupPublic />
                  ) : (
                    <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
        {leftMenuPopupFlag &&
          BuildPageListFunction(
            leftMenuPopupFlag,
            setLeftMenuPopupFlag,
            headerConfig,
            headerConfig?.menu,
            navigate,
            (rootType = { rootType }),
            notificationsPopupFlag,
            handleOpenNotifications,
            handleChangePasswordPopup
          )}
        {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
      </>
    );
  } else {
    return (
      <>
        <nav
          className="app_navbar"
          style={{
            position: "sticky",
            justifyContent: "flex-end"
          }}
        >
          <div className="profile_dropdown_container">
            <div className="profile_dropdown">
              <div title="Open profile" className="nav_icon_outer">
                <UserIconSvg />
              </div>
              {rootType === "public" ? (
                <UserPopupPublic />
              ) : (
                <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
              )}
            </div>
          </div>
        </nav>
        {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
      </>
    );
  }
}

export default Navbar;

const UserPopupPrivate = ({ handleChangePasswordPopup, styleObject }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userviewstore);

  useEffect(() => {
    if (!user.loginFlag) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogout = () => {
    dispatch(callUserLogoutFunction());
  };

  let isPublicFlag = true;

  let appIsPublic = getStorageItem("Zino_app_is_public");
  if (!appIsPublic || appIsPublic?.toString() !== "true") {
    isPublicFlag = false;
  }

  return (
    <div className="profile_popover" style={styleObject}>
      <div title={user?.name}>
        <span>{user?.name}</span>
      </div>
      <div title={user?.email}>
        <span>{user?.email}</span>
      </div>
      <div className="cursor-pointer" onClick={() => navigate("/applist")}>
        <span>Apps List</span>
      </div>
      {isPublicFlag && (
        <div className="cursor-pointer" onClick={() => navigate("/zadmin/metrics")}>
          <span>Admin Page</span>
        </div>
      )}
      <div className="cursor-pointer" onClick={handleChangePasswordPopup}>
        <span>Change Password</span>
      </div>
      <div className="cursor-pointer" onClick={handleLogout}>
        <span>Log out</span>
      </div>
      <div>
        <span>Version : {process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  );
};

const UserPopupPublic = () => {
  const navigate = useNavigate();

  return (
    <div className="profile_popover">
      <div title={"Guest User"}>
        <span>Guest</span>
      </div>
      <div className="cursor-pointer" onClick={() => navigate("/public/applist")}>
        <span>Apps List</span>
      </div>
      <div className="cursor-pointer" onClick={() => navigate("/login")}>
        <span>Login</span>
      </div>
      <div>
        <span>Version : {process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  );
};

const BuildPageListFunction = (
  leftMenuPopupFlag,
  setLeftMenuPopupFlag,
  headerConfig,
  menuList = [],
  navigate,
  rootType,
  notificationsPopupFlag,
  handleOpenNotifications,
  handleChangePasswordPopup
) => {
  return (
    <div
      style={{
        visibility: leftMenuPopupFlag ? "visible" : "hidden",
        opacity: leftMenuPopupFlag ? "1" : "0"
      }}
      className={`mobileview_top_menu_overlay ${leftMenuPopupFlag ? "popupBoxSectionIsOpen" : ""}`}
      onClick={() => setLeftMenuPopupFlag(false)}
    >
      <div
        style={{
          visibility: leftMenuPopupFlag ? "visible" : "hidden",
          opacity: leftMenuPopupFlag ? "1" : "0"
        }}
        className={`mobileview_top_menu_popup ${leftMenuPopupFlag ? "mobileview_top_menu_popupopen" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mobileview_top_menu_close">
          <div
            onClick={() =>
              navigate(headerConfig?.logo?.lastpage || `/${headerConfig?.menu?.[0]?.display || "applist"}`)
            }
            className="zinologo"
          >
            {headerConfig?.logo?.logosrc ? (
              <img
                style={{
                  height: `${headerConfig?.["logo"]?.["logosize"] || 40}px`
                }}
                src={headerConfig.logo.logosrc}
                alt={headerConfig.logo.logostr || ""}
              />
            ) : (
              headerConfig?.logo?.logotext && (
                <div
                  className="zinologo_text"
                  style={{
                    fontSize: `${headerConfig?.["logo"]?.["logotextsize"] || 20}px`,
                    alignItems: headerConfig?.["logo"]?.["logoalign"] || "center",
                    color: headerConfig?.["logo"]?.["logotextcolor"]
                  }}
                >
                  {headerConfig.logo.logotext}
                </div>
              )
            )}
          </div>
          <div
            title="Close menu"
            className="nav_icon_outer"
            onClick={() => {
              setLeftMenuPopupFlag(false);
            }}
          >
            <CloseIconSvg />
          </div>
        </div>
        <div style={{ height: "calc(100vh - 206px)", overflow: "hidden auto" }}>
          <div className="top_menu_outerBox top_menu">
            {menuList.map((menuItem, index) => {
              if (menuItem.type === "page") {
                return <MenuItemPage key={index} menuItem={menuItem} />;
              } else if (menuItem.type === "dropdown") {
                return <MenuItemDropdown key={index} menuItem={menuItem} styleObj={{ width: "100%" }} />;
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="mobileview_top_menu_footer">
          <div className="notification_container" onClick={() => handleOpenNotifications(true)}>
            <div title="Open notifications" className="nav_icon_outer">
              <BellIconSvg fill={headerConfig?.notifications_count > 0 ? "#10182B" : "#667085"} />
            </div>
            <span
              style={{
                color: headerConfig?.notifications_count > 0 ? "#10182B" : ""
              }}
            >
              Notifications
              <span
                style={{
                  marginLeft: "10px",
                  borderBottom: "2px solid red",
                  padding: "0 10px",
                  color: "red",
                  display: headerConfig?.notifications_count > 0 ? "" : "none"
                }}
              >
                {headerConfig?.notifications_count || 0}
              </span>
            </span>
            <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
          </div>

          <div className="profile_dropdown">
            <div title="Open profile" className="nav_icon_outer">
              <UserIconSvg />
            </div>
            <span>Profile</span>
            {rootType === "public" ? (
              <UserPopupPublic />
            ) : (
              <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChangePasswordPopup = ({ setShowChangePasswordPopup }) => {
  const [existingPassword, setExistingPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [errorState, setErrorState] = useState({
    existing: false,
    new: false,
    reEnter: false
  });
  const handleErrors = () => {
    let tempObj = {};
    tempObj = {
      existing: existingPassword?.length < 4,
      new: newPassword?.length < 4,
      reEnter: reEnterNewPassword !== newPassword
    };
    setErrorState({
      existing: existingPassword?.length < 4,
      new: newPassword?.length < 4,
      reEnter: reEnterNewPassword !== newPassword
    });
    return tempObj;
  };

  const handleChangePassword = async () => {
    const tempObj = handleErrors();
    if (!tempObj?.existing && !tempObj?.new && !tempObj?.reEnter) {
      try {
        await axios.put(
          `${process.env.REACT_APP_AUTH_HOST}/api/v1/loginPassword`,
          { existing_password: existingPassword, new_password: newPassword },
          { headers: getApiCallHeadersData() }
        );
        toast.success("Change Password done successfully");
        setShowChangePasswordPopup(false);
      } catch (error) {
        setErrorState({
          existing: error?.response?.data?.message,
          new: false,
          reEnter: false
        });
      }
    }
  };

  const handleChange =
    (type) =>
    ({ target: { value } }) => {
      switch (type) {
        case "existing":
          setExistingPassword(value);
          break;
        case "new":
          setNewPassword(value);
          break;
        case "reEnter":
          setReEnterNewPassword(value);
          break;
        default:
          break;
      }
    };

  return (
    <div className="password_outer_container">
      <div className="password_inner_container">
        <div className="password_header_container">
          <p>Change Password</p>
          <span className="material-symbols-outlined" onClick={() => setShowChangePasswordPopup(false)}>
            close
          </span>
        </div>
        <div className="password_body_container">
          <div className="password_input_container">
            <label className="password_input_label">Existing Password</label>
            <input
              className="zino_inputBox"
              placeholder="Enter existing password"
              value={existingPassword}
              onChange={handleChange("existing")}
            />
            {errorState?.existing && (
              <p className="errorMessage">
                {errorState?.existing === true ? "Please enter valid Password" : errorState?.existing}
              </p>
            )}
          </div>
          <div className="password_input_container">
            <label className="password_input_label">New Password</label>
            <input
              className="zino_inputBox"
              placeholder="Enter new password"
              value={newPassword}
              onChange={handleChange("new")}
            />
            {errorState?.new && <p className="errorMessage">Password contains atleast 4 characters</p>}
          </div>
          <div className="password_input_container">
            <label className="password_input_label">Re-enter new Password</label>
            <input
              className="zino_inputBox"
              placeholder="Re-enter new password"
              value={reEnterNewPassword}
              onChange={handleChange("reEnter")}
            />
            {errorState?.reEnter && <p className="errorMessage">Passwords mismatch!</p>}
          </div>
          <button className="zino_btn_primary" onClick={handleChangePassword}>
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
};
