import React, { useEffect, useState } from "react";
import TableField from "./table/TableField";
import ManageColumns from "./table/ManageColumns";
import FilterSearchFieldLV from "./filter/FilterSearchFieldLV";
import { commonFunction } from "../utils/commonFunction";
import { FSIconRenderer } from "../../zinoIcon";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData, getStorageItem, setStorageItem } from "../../../utils/storageFunction";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const Table = ({ namespace, recordviewTableData, recordviewDataConfig, functionObject = {} }) => {
  const storeData = useSelector((state) => state.recordviewstore);
  let recordviewTableFPayloadData = storeData?.[`${namespace}_recordviewTableFPayloadData`];
  const rv_layout_type = recordviewDataConfig?.layout_type || "table_view";

  // eslint-disable-next-line no-unused-vars
  const [allColumnsList, setAllColumnsList] = useState(recordviewDataConfig.all_columns);
  const [allSelectedColumnsList, setAllSelectedColumnsList] = useState(recordviewDataConfig.columns);
  const [tableDataHeader, setTableDataHeader] = useState();
  let chackbox_flag = recordviewDataConfig.multi_select_custom_actions.length > 0;

  const [recordviewTableManageColumnFlag, setRecordviewTableManageColumnFlag] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [catalogFlag, setCatalogFlag] = useState(false);

  useEffect(() => {
    let newData = commonFunction.changeDataHeaderArray(allSelectedColumnsList);
    setTableDataHeader(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedColumnsList]);

  useEffect(() => {
    setAllSelectedColumnsList(recordviewDataConfig.columns);
  }, [recordviewDataConfig.columns]);

  let pagename = window.location.pathname;
  useEffect(() => {
    let flag = getStorageItem(`${pagename}_${namespace}_catalogFlag`);

    if (typeof flag === "undefined" || flag === null) {
      flag = "false";
      if (
        recordviewDataConfig?.default_catalog_view ||
        ["catalog_and_table_view", "catalog_view", "embedded"].includes(rv_layout_type)
      ) {
        flag = "true";
      }
    }
    if (["table_view"].includes(rv_layout_type)) {
      flag = "false";
    }
    if (["catalog_view", "embedded"].includes(rv_layout_type)) {
      flag = "true";
    }
    setCatalogFlag(flag === "true");
    setStorageItem(`${pagename}_${namespace}_catalogFlag`, flag === "true");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCatalogFunction = (flag) => {
    setStorageItem(`${pagename}_${namespace}_catalogFlag`, flag);
    setCatalogFlag(flag);
  };

  const exportRecordviewAsExcle = async () => {
    try {
      let { data } = await axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/dynamic`,
        data: {
          data: {},
          function_name: "save_exportJobs",
          params: {
            filetype: "csv",
            jobtype: "export_csv_job",
            recordsview_id: namespace,
            search_query: {
              filter_by: recordviewTableFPayloadData.filter_by
            },
            front_end: {
              params_config: {},
              action_config: {}
            }
          }
        },
        headers: getApiCallHeadersData()
      });
      if (!data.error) {
        toast.success(data.message, {
          style: {
            border: "1px solid green",
            padding: "16px",
            color: "green"
          },
          iconTheme: {
            primary: "green",
            secondary: "#FFFAEE"
          }
        });
      } else {
        throw new Error("Failed to export excle.");
      }
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  if (tableDataHeader) {
    let tableData = recordviewTableData.documents;
    if (!recordviewDataConfig?.catalog?.elements) {
      if (!recordviewDataConfig?.catalog) {
        recordviewDataConfig.catalog = {};
      } else {
        recordviewDataConfig.catalog.elements = [];
      }
    }
    return (
      <div className="recordviewTable_outerBox  pageView_item_outer">
        {recordviewDataConfig.hide_header ? (
          ""
        ) : (
          <>
            {namespace === "namespace" ? (
              ""
            ) : (
              <>
                <div className="recordviewTable_top_field">
                  <div className="recordview_table_title">{recordviewDataConfig.title}</div>
                  <div className="recordview_table_manage_btns">
                    {recordviewDataConfig.search || false ? (
                      <FilterSearchFieldLV namespace={namespace} tableDataHeaderArray={allSelectedColumnsList} />
                    ) : (
                      <div></div>
                    )}
                    {!["catalog_view"].includes(rv_layout_type) && (
                      <>
                        <div className="recordview_table_settingoption">
                          <div className="recordview_table_option_more_filters"></div>
                          <button
                            className="manage_column_btn"
                            onClick={() => setRecordviewTableManageColumnFlag(true)}
                          >
                            <FSIconRenderer icon={"list"} marginR="0" />
                          </button>
                        </div>
                        {["table_and_catalog_view", "catalog_and_table_view"].includes(rv_layout_type) ? (
                          <div className="recordview_table_settingoption">
                            <button className="manage_column_btn" onClick={() => setCatalogFunction(!catalogFlag)}>
                              <FSIconRenderer icon={catalogFlag ? "table_rows_narrow" : "cards"} marginR="0" />
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {recordviewDataConfig.allow_download && (
                      <div className="recordview_table_settingoption">
                        <button className="manage_column_btn" onClick={exportRecordviewAsExcle}>
                          <FSIconRenderer icon={"download"} marginR="0" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {recordviewTableManageColumnFlag && (
                  <ManageColumns
                    namespace={namespace}
                    columnsList={allColumnsList}
                    defaultcolumnsList={allSelectedColumnsList}
                    setColumnsList={setAllSelectedColumnsList}
                    closePopup={setRecordviewTableManageColumnFlag}
                    openPopupBox={recordviewTableManageColumnFlag}
                  />
                )}
              </>
            )}
          </>
        )}

        <TableField
          namespace={namespace}
          tableData={tableData}
          tableDataHeader={tableDataHeader}
          tableDataViewer={allSelectedColumnsList}
          recordviewDataConfig={recordviewDataConfig}
          setColumnsList={setAllSelectedColumnsList}
          chackboxflag={chackbox_flag}
          table_id={recordviewDataConfig.table_id}
          catalogFlag={catalogFlag}
          recordviewTableData={recordviewTableData}
          functionObject={functionObject}
        />
      </div>
    );
  } else {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }
};

export default Table;
