import React, { useRef, useLayoutEffect, useEffect, useState } from "react";

import { commonFunction } from "../utils/commonFunction";

import { setFormPageErrorColor, setFormPageNumber } from "../../../redux/formview/formviewaction";
import { useDispatch, useSelector } from "react-redux";
import { gridCommonFunction } from "../../utils/gridCommonFunction";

import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import FieldView from "./FieldView";
import { DetailsViewRenderForm } from "../../detailsviewlib/DetailsViewRender";
import { ProcessBarBox } from "../../viewlib/ProcessBarSection/PopupBoxSection";
import axios from "axios";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import DetailsViewItem from "../../detailsviewlib/DetailsViewItem";

const CustomFormRenderer = ({ config, namespace, callbackfunction, detailsviewConfig, detailsviewData }) => {
  let { formsubmitgetresponscbfun } = callbackfunction;

  const [buttonGroupConfig, setButtonGroupConfig] = useState(null);
  // get data from redux
  const storeData = useSelector((state) => state.formviewstore);
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};
  const formData = storeData[`${namespace}_formData`] || {};
  const formPageNumber = storeData[`${namespace}_formPageNumber`] || 0;
  const formErrorColor = storeData[`${namespace}_formErrorColor`] || {};

  const dispatch = useDispatch();

  const formRef = useRef();

  // set default color in navbar if color not add
  useLayoutEffect(() => {
    if (!formErrorColor[config.sections[formPageNumber].id]) {
      dispatch(
        setFormPageErrorColor(namespace, {
          ...formErrorColor,
          [config.sections[formPageNumber].id]: config.progress_bar_color_codes.filled_incomplete
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPageNumber]);

  // getting form data and counting error and change navbar color
  useEffect(() => {
    let sectionKey = config.sections[formPageNumber].id;
    if (formData[sectionKey]) {
      let errorColor;
      if (formData[sectionKey]?.errorCount === 0) {
        errorColor = {
          ...formErrorColor,
          [sectionKey]: config.progress_bar_color_codes.filled_valid
        };
      } else {
        errorColor = {
          ...formErrorColor,
          [sectionKey]: config.progress_bar_color_codes.filled_invalid
        };
      }
      dispatch(setFormPageErrorColor(namespace, errorColor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  // submit function
  // eslint-disable-next-line no-unused-vars
  const [submitLoad, setSubmitLoad] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoad(true);
    let isValid = await commonFunction.checkAllForm(namespace);

    if (isValid) {
      toast.error("Form not valid", toastErrorMessageStyle());
    } else {
      // Handle form submission logic

      let imageUploadErrorFlag = await commonFunction.uploadImageInDatabase(namespace);
      if (imageUploadErrorFlag) {
        let fullformData = commonFunction.getKeyCompleteDataFromForm(namespace, "value");
        let formDataObj = commonFunction.getOnlyDataFromObject(fullformData, namespace, true);
        let onSubmitCustomFun = await commonFunction.callCustomOnSubmitFunction(formDataObj, namespace);
        if (onSubmitCustomFun?.error) {
          toast.error("Submit function not valid", toastErrorMessageStyle());
        } else {
          formDataObj = onSubmitCustomFun?.form_data || formDataObj;
          // formDataObj = await commonFunction.callCustomOnSubmitFunction(formDataObj, namespace);
          let data = {
            data: formDataObj
          };
          let functionAndExtraDataObject = {
            local_storage_key: formConfigData.local_storage_key,
            namespace: namespace
          };
          formsubmitgetresponscbfun(data, functionAndExtraDataObject);
          // removeStorageItem(formConfigData.local_storage_key);
        }
      }
    }
    setSubmitLoad(false);
  };

  const handleGroupSubmit = async (e, buttonId, buttonValue) => {
    e.preventDefault();
    setSubmitLoad(true);
    let isValid = await commonFunction.checkAllForm(namespace);
    if (isValid) {
      toast.error("Form not valid", toastErrorMessageStyle());
    } else {
      // Handle form submission logic

      let imageUploadErrorFlag = await commonFunction.uploadImageInDatabase(namespace);
      if (imageUploadErrorFlag) {
        let fullformData = commonFunction.getKeyCompleteDataFromForm(namespace, "value");
        let formDataObj = commonFunction.getOnlyDataFromObject(fullformData, namespace, true);

        formDataObj[buttonId] = buttonValue;
        let onSubmitCustomFun = await commonFunction.callCustomOnSubmitFunction(formDataObj, namespace);
        if (onSubmitCustomFun?.error) {
          toast.error("Submit function not valid", toastErrorMessageStyle());
        } else {
          formDataObj = onSubmitCustomFun?.form_data || formDataObj;
          // formDataObj = await commonFunction.callCustomOnSubmitFunction(formDataObj, namespace);
          let data = {
            data: formDataObj
          };
          let functionAndExtraDataObject = {
            local_storage_key: formConfigData.local_storage_key,
            namespace: namespace
          };
          formsubmitgetresponscbfun(data, functionAndExtraDataObject);
          // removeStorageItem(formConfigData.local_storage_key);
        }
      }
    }
    setSubmitLoad(false);
  };

  // onClick button and nav-item go in next and back page
  const handleChangePage = async (page) => {
    if (page < 0 || page === formPageNumber || page === config.sections.length) {
      return null;
    } else {
      await commonFunction.checkCurrentForm(namespace, formPageNumber);
      dispatch(setFormPageNumber(namespace, page));
    }
  };

  // create nav-item html
  let sectionNameList = [];
  if (config.sections.length > 1 && config.progress_bar) {
    config.sections.forEach((section, index) => {
      if (index !== 0) sectionNameList.push(<hr key={index + config.sections.length} />);

      sectionNameList.push(
        <div
          key={index}
          onClick={
            config.progress_bar_clickable ? () => handleChangePage(index) : () => handleChangePage(formPageNumber)
          }
        >
          <div
            className="formHeaderTopBox"
            style={{
              backgroundColor: `${formErrorColor[section.id] || config.progress_bar_color_codes.empty}`
            }}
          >
            {formErrorColor[section.id] === config.progress_bar_color_codes.filled_valid
              ? filled_valid
              : formErrorColor[section.id] === config.progress_bar_color_codes.filled_invalid
              ? filled_invalid
              : formErrorColor[section.id] === config.progress_bar_color_codes.filled_incomplete
              ? filled_incomplete
              : filled_empty}
          </div>
          <span className={section.id === config.sections[formPageNumber].id ? "active" : ""}>
            {section.progress_bar_title}
          </span>
        </div>
      );
    });
  }

  let styleObject = {};
  if (submitLoad) {
    styleObject = { pointerEvents: "none" };
  }

  return (
    <div style={styleObject}>
      <div className="appFormBox" id={"dev_" + config?.id + "_id"}>
        {sectionNameList.length > 0 && (
          <>
            <div className="formNavBarBox">{sectionNameList}</div>
            <hr className="formHeaderHrTag" />
          </>
        )}

        <div className="formBody">
          <DetailsViewRenderFormRender
            detailsviewConfig={detailsviewConfig}
            detailsviewData={detailsviewData}
            namespace={namespace}
          />

          <div ref={formRef} id="formContainerWidth">
            {config.sections[formPageNumber].components.map((sectionRow, index) => {
              let rowItemElements = sectionRow.elements;
              let sectionRowGridArray = [];
              for (let index = 0; index < rowItemElements.length; index++) {
                let element = rowItemElements[index].grid;
                sectionRowGridArray.push({ ...element, static: true });
              }
              let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(sectionRowGridArray);
              return (
                <React.Fragment key={sectionRow.row_id}>
                  {sectionRow.title && (
                    <>
                      <p className="section_row_title">{sectionRow.title}</p>
                      <hr />
                    </>
                  )}
                  <div style={styleDataForGrid}>
                    {rowItemElements.map((colItem, colItemIndex) => {
                      let dataObject = colItem.field;
                      let currentConfigKey = `${config.sections[formPageNumber].id}:${dataObject?.id || colItemIndex}`;
                      let styleObject = {
                        gridArea: `zino-${colItem.element_id}`
                      };
                      // if (dataObject.show) {
                      if (colItem.element_type === "button-group") {
                        if (!buttonGroupConfig) setButtonGroupConfig(dataObject);
                        return null;
                      } else {
                        return (
                          <FieldView
                            key={colItem.element_id}
                            colItem={colItem}
                            dataObject={dataObject}
                            styleObject={styleObject}
                            currentConfigKey={currentConfigKey}
                            namespace={namespace}
                          />
                        );
                      }
                      // } else {
                      //   return null;
                      // }
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          <div className="form_popup_submit_btn">
            {formPageNumber === 0 ? (
              <div></div>
            ) : (
              <button
                className="zino_btn_primary zino_btn_lg"
                id={"previousbutton"}
                type={"button"}
                onClick={() => {
                  handleChangePage(formPageNumber - 1);
                }}
                variant="contained"
                style={{ minWidth: "150px" }}
              >
                Previous
              </button>
            )}
            {formPageNumber < config.sections.length - 1 ? (
              <button
                className="zino_btn_primary zino_btn_lg"
                id={"nextbutton"}
                type={"button"}
                onClick={() => {
                  handleChangePage(formPageNumber + 1);
                }}
                variant="contained"
                style={{ minWidth: "150px" }}
              >
                Next
              </button>
            ) : buttonGroupConfig ? (
              <>
                {formConfigData?.reset_button?.show_reset && (
                  <button
                    className="zino_btn_primary zino_btn_lg"
                    id={"nextbutton"}
                    type={"button"}
                    onClick={() => commonFunction.resetCurrForm(namespace)}
                    variant="contained"
                    style={{ minWidth: "150px", marginRight: "8px" }}
                  >
                    {formConfigData?.reset_button?.reset_label || "Reset"}
                  </button>
                )}
                <ButtonGroupItems buttonGroupConfig={buttonGroupConfig} handleGroupSubmit={handleGroupSubmit} />
              </>
            ) : (
              <>
                {formConfigData?.reset_button?.show_reset && (
                  <button
                    className="zino_btn_primary zino_btn_lg"
                    id={"nextbutton"}
                    type={"button"}
                    onClick={() => commonFunction.resetCurrForm(namespace)}
                    variant="contained"
                    style={{ minWidth: "150px", marginRight: "8px" }}
                  >
                    {formConfigData?.reset_button?.reset_label || "Reset"}
                  </button>
                )}
                <button
                  className="zino_btn_primary zino_btn_lg"
                  id={"nextbutton"}
                  type={"button"}
                  onClick={handleSubmit}
                  variant="contained"
                  style={{ minWidth: "150px" }}
                >
                  {formConfigData?.submit_button || "Submit"}
                </button>
              </>
            )}
          </div>
        </div>
        {submitLoad && <ProcessBarBox />}
      </div>
    </div>
  );
};

export default CustomFormRenderer;

const ButtonGroupItems = ({ buttonGroupConfig, handleGroupSubmit }) => {
  const [buttonData, setButtonData] = useState(null);
  const callGroupSubmit = (e, data) => {
    e.preventDefault();
    if (buttonGroupConfig.confirmation_box) {
      setButtonData(data);
    } else {
      handleGroupSubmit(e, buttonGroupConfig.id, data.value);
    }
  };
  if (buttonData) {
    let styleIs = { minWidth: "150px", border: "none" };

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "12px", flexWrap: "wrap" }}>
        <p>
          Please confirm you want to do{" "}
          <b style={{ textDecoration: "underline", color: buttonData.color }}>{buttonData.label}</b> action
        </p>
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "12px", flexWrap: "wrap" }}
        >
          <button
            className="zino_btn_primary zino_btn_lg"
            id={"nextbutton"}
            type={"button"}
            onClick={(e) => setButtonData(null)}
            variant="contained"
            style={{ ...styleIs, background: "red" }}
          >
            NO
          </button>
          <button
            className="zino_btn_primary zino_btn_lg"
            id={"nextbutton"}
            type={"button"}
            onClick={(e) => handleGroupSubmit(e, buttonGroupConfig.id, buttonData.value)}
            variant="contained"
            style={{ ...styleIs, background: "green" }}
          >
            YES
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "12px", flexWrap: "wrap" }}>
        {buttonGroupConfig.data.values.map((buttonOption, buttonIndex) => {
          let styleIs = { minWidth: "150px" };
          if (buttonOption.color) {
            styleIs.backgroundColor = buttonOption.color;
            styleIs.border = "none";
          }
          return (
            <button
              key={buttonIndex}
              className="zino_btn_primary zino_btn_lg"
              id={"nextbutton"}
              type={"button"}
              onClick={(e) => callGroupSubmit(e, buttonOption)}
              // onClick={(e) =>
              //   buttonOption.confirmation_box
              //     ? callGroupSubmit(e, buttonOption)
              //     : handleGroupSubmit(e, buttonGroupConfig.id, buttonOption.value)
              // }
              variant="contained"
              style={styleIs}
            >
              {buttonOption.label}
            </button>
          );
        })}
      </div>
    );
  }
};

const filled_empty = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.77 22.75H4.73C2.72 22.75 1.75 21.82 1.75 19.9V4.1C1.75 2.18 2.73 1.25 4.73 1.25H8.77C10.78 1.25 11.75 2.18 11.75 4.1V19.9C11.75 21.82 10.77 22.75 8.77 22.75ZM4.73 2.75C3.46 2.75 3.25 3.09 3.25 4.1V19.9C3.25 20.91 3.46 21.25 4.73 21.25H8.77C10.04 21.25 10.25 20.91 10.25 19.9V4.1C10.25 3.09 10.04 2.75 8.77 2.75H4.73V2.75Z"
      fill="white"
    />
    <path
      d="M20.27 11.25H16.23C14.22 11.25 13.25 10.36 13.25 8.52V3.98C13.25 2.14 14.23 1.25 16.23 1.25H20.27C22.28 1.25 23.25 2.14 23.25 3.98V8.51C23.25 10.36 22.27 11.25 20.27 11.25ZM16.23 2.75C14.89 2.75 14.75 3.13 14.75 3.98V8.51C14.75 9.37 14.89 9.74 16.23 9.74H20.27C21.61 9.74 21.75 9.36 21.75 8.51V3.98C21.75 3.12 21.61 2.75 20.27 2.75H16.23Z"
      fill="white"
    />
    <path
      d="M20.27 22.75H16.23C14.22 22.75 13.25 21.77 13.25 19.77V15.73C13.25 13.72 14.23 12.75 16.23 12.75H20.27C22.28 12.75 23.25 13.73 23.25 15.73V19.77C23.25 21.77 22.27 22.75 20.27 22.75ZM16.23 14.25C15.05 14.25 14.75 14.55 14.75 15.73V19.77C14.75 20.95 15.05 21.25 16.23 21.25H20.27C21.45 21.25 21.75 20.95 21.75 19.77V15.73C21.75 14.55 21.45 14.25 20.27 14.25H16.23Z"
      fill="white"
    />
  </svg>
);
const filled_valid = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99393 17.9999C9.69378 17.9999 9.40864 17.866 9.19854 17.6316L4.95141 12.8935C4.5162 12.408 4.5162 11.6044 4.95141 11.1188C5.38663 10.6333 6.10699 10.6333 6.54221 11.1188L9.99393 14.9696L17.7078 6.36405C18.143 5.87853 18.8634 5.87853 19.2986 6.36405C19.7338 6.84958 19.7338 7.6532 19.2986 8.13873L10.7893 17.6316C10.5792 17.866 10.2941 17.9999 9.99393 17.9999Z"
      fill="white"
    />
  </svg>
);
const filled_invalid = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 22.75C6.57 22.75 1.75 17.93 1.75 12C1.75 6.07 6.57 1.25 12.5 1.25C18.43 1.25 23.25 6.07 23.25 12C23.25 17.93 18.43 22.75 12.5 22.75ZM12.5 2.75C7.4 2.75 3.25 6.9 3.25 12C3.25 17.1 7.4 21.25 12.5 21.25C17.6 21.25 21.75 17.1 21.75 12C21.75 6.9 17.6 2.75 12.5 2.75Z"
      fill="white"
    />
    <path
      d="M9.67 15.58C9.48 15.58 9.29 15.51 9.14 15.36C8.85 15.07 8.85 14.59 9.14 14.3L14.8 8.63999C15.09 8.34999 15.57 8.34999 15.86 8.63999C16.15 8.92999 16.15 9.40998 15.86 9.69998L10.2 15.36C10.06 15.51 9.86 15.58 9.67 15.58Z"
      fill="white"
    />
    <path
      d="M15.33 15.58C15.14 15.58 14.95 15.51 14.8 15.36L9.14 9.69998C8.85 9.40998 8.85 8.92999 9.14 8.63999C9.43 8.34999 9.91 8.34999 10.2 8.63999L15.86 14.3C16.15 14.59 16.15 15.07 15.86 15.36C15.71 15.51 15.52 15.58 15.33 15.58Z"
      fill="white"
    />
  </svg>
);
const filled_incomplete = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.05 22.42C14.72 22.42 14.41 22.2 14.32 21.86C14.21 21.46 14.45 21.05 14.86 20.94C18.92 19.87 21.75 16.19 21.75 11.99C21.75 6.89 17.6 2.74 12.5 2.74C8.17 2.74 5.33 5.27 4 6.8H6.94C7.35 6.8 7.69 7.14 7.69 7.55C7.69 7.96 7.36 8.31 6.94 8.31H2.51C2.46 8.31 2.37 8.3 2.3 8.28C2.21 8.25 2.13 8.21 2.06 8.16C1.97 8.1 1.9 8.02 1.85 7.93C1.8 7.84 1.76 7.73 1.75 7.62C1.75 7.59 1.75 7.57 1.75 7.54V3C1.75 2.59 2.09 2.25 2.5 2.25C2.91 2.25 3.25 2.59 3.25 3V5.39C4.88 3.64 7.95 1.25 12.5 1.25C18.43 1.25 23.25 6.07 23.25 12C23.25 16.88 19.96 21.16 15.24 22.4C15.18 22.41 15.11 22.42 15.05 22.42Z"
      fill="white"
    />
    <path
      d="M11.79 22.73C11.77 22.73 11.75 22.72 11.74 22.72C10.66 22.65 9.6 22.41 8.6 22.02C8.31 21.91 8.11 21.62 8.12 21.31C8.12 21.22 8.14 21.13 8.17 21.05C8.32 20.67 8.77 20.48 9.14 20.62C10.01 20.96 10.92 21.16 11.84 21.23C12.23 21.25 12.54 21.59 12.54 21.99L12.53 22.03C12.51 22.42 12.18 22.73 11.79 22.73ZM6.28 20.58C6.11 20.58 5.95 20.52 5.81 20.42C4.97 19.74 4.23 18.95 3.63 18.07C3.54 17.94 3.49 17.8 3.49 17.65C3.49 17.4 3.61 17.17 3.82 17.03C4.15 16.8 4.63 16.89 4.86 17.21C4.86 17.22 4.86 17.22 4.86 17.22C4.87 17.23 4.88 17.25 4.89 17.26C5.41 18.01 6.04 18.68 6.75 19.24C6.92 19.38 7.03 19.59 7.03 19.82C7.03 19.99 6.98 20.16 6.87 20.3C6.72 20.48 6.51 20.58 6.28 20.58ZM2.94 15.7C2.61 15.7 2.32 15.49 2.23 15.18C1.91 14.15 1.75 13.08 1.75 12V11.99C1.76 11.58 2.09 11.25 2.5 11.25C2.91 11.25 3.25 11.59 3.25 12C3.25 12.94 3.39 13.86 3.66 14.73C3.68 14.81 3.69 14.88 3.69 14.96C3.69 15.28 3.48 15.57 3.16 15.67C3.09 15.69 3.02 15.7 2.94 15.7Z"
      fill="white"
    />
  </svg>
);

const DetailsViewRenderFormRender = ({ detailsviewConfig, detailsviewData, namespace }) => {
  if (detailsviewConfig?.detailview_id) {
    return <DetailsViewRenderFormRenderApi detailsviewConfig={detailsviewConfig} namespace={namespace} />;
  } else if (detailsviewConfig?.elements && detailsviewConfig.elements.length > 0) {
    return (
      <div>
        <DetailsViewRenderForm config={detailsviewConfig} detailsViewData={detailsviewData} />
      </div>
    );
  } else {
    return <></>;
  }
};
const DetailsViewRenderFormRenderApi = ({ detailsviewConfig, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};
  let detailview_id = detailsviewConfig?.detailview_id;

  const [recordviewDataConfig, setdetailsviewDataIs] = useState({});
  const [detailsviewDataFlag, setdetailsviewDataFlag] = useState(false);

  const getDetailsviewConfigAndData = async () => {
    // API Call and get recordview data
    try {
      setdetailsviewDataFlag(true);
      let { data } = await axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/dynamic`,
        data: {
          data: {},
          function_name: "show_detailsview_popup",
          params: {
            detailsview_id: detailview_id,
            instance_id: formConfigData?.instance_id || "",
            front_end: {
              action_config: {},
              params_config: {}
            }
          }
        },
        headers: getApiCallHeadersData()
      });
      data = data.data;

      let viewData = data?.detailsview_config?.data || {};
      if (data?.detailsview_config?.detailview_settings) {
        viewData["detailview_settings"] = data?.detailsview_config?.detailview_settings;
      }
      setdetailsviewDataIs(viewData);
    } catch (error) {
      setdetailsviewDataIs({});
      // toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setdetailsviewDataFlag(false);
    }
  };

  useEffect(() => {
    getDetailsviewConfigAndData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (detailsviewDataFlag) {
    return (
      <div className="zino_renderer_loader_box" style={{ height: "150px" }}>
        <span className="zino_renderer_loader"></span>
      </div>
    );
  } else {
    if (Object.keys(recordviewDataConfig || {}).length > 0) {
      return (
        <DetailsViewItem
          itemConfig={recordviewDataConfig?.elements}
          activityCardsConfig={recordviewDataConfig?.activity_cards}
          detailsViewDataFormData={
            recordviewDataConfig?.detailsview_data?.form_data || recordviewDataConfig?.form_data || {}
          }
          detailsViewDataTimeline={
            recordviewDataConfig?.detailsview_data?.timeline_data || recordviewDataConfig?.timeline_data || []
          }
          detailsViewDataActivityCard={
            recordviewDataConfig?.detailsview_data?.activity_card_data || recordviewDataConfig?.activity_card_data || []
          }
          detailsViewDataActivitiesHistory={
            recordviewDataConfig?.detailsview_data?.allowed_activities_history ||
            recordviewDataConfig?.allowed_activities_history ||
            {}
          }
          detailsViewDataOrg={recordviewDataConfig}
          fieldLabelFlag={false}
          detailsViewItemConfig={{}}
          jobParamsConfig={{
            sm_id: formConfigData.sm_id,
            instance_id: formConfigData.instance_id
          }}
          functionObject={{}}
          // printThis={callFrom !== "outside"}
          printThis={false}
          callFrom="outside"
        />
      );
    } else {
      return <></>;
    }
  }
};
