import { useState, useLayoutEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";

import "react-phone-input-2/lib/material.css";

import { commonFunction } from "../utils/commonFunction";

const FFPhone = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [phoneInput, setPhoneInput] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || {
      dial_code: component.default_country_code || "",
      phone: ""
    }
  );
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];

    setErrors(dataPack);

    if (defValFlag) {
      let value = commonFunction.getKeyValueFromForm(namespace, currentConfigKey);

      if (value !== undefined) {
        setPhoneInput(value);
      }
    } else {
      setDefValFlag(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  const validate = async (phone, data) => {
    const number = phone.replace(data.dialCode, "");
    const phData = { dial_code: data.dialCode, phone: number };

    await commonFunction.callChackFiledFunction(namespace, phData, currentConfigKey, component);
  };

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <label className="form_inputBox">
        <PhoneInput
          inputStyle={{
            display: "flex",
            width: "100%",
            border: errors.length ? "1px solid red" : ""
          }}
          disabled={component.disabled}
          value={(phoneInput?.dial_code || "") + (phoneInput?.phone || "")}
          onChange={(phone, data) => validate(phone, data)}
          specialLabel=""
        />
        <span className="placeholder phone_number_label">{component.label}</span>
        {errors.length > 0 && (
          <>
            <div className="invalid_feedback">
              {errors.map((error, index) => (
                <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                  {error}
                </p>
              ))}
            </div>
          </>
        )}
      </label>
    </div>
  );
};

export default FFPhone;
