import React, { useEffect, useRef, useState } from "react";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import { DateRangePicker } from "react-date-range";
import { commonFunction } from "../../utils/commonFunction";
import { convertNumbetToDate } from "../../../utils/commonFunction";

const DateRangePickerField = ({ filter, namespace }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  });
  const [dateRangeValueDate, setDateRangeValueDate] = useState(null);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleReset = () => {
    let date = { startDate: new Date(), endDate: new Date(), key: "selection" };
    setDateRangeValue(date);
    setPopupShow(false);
    setDateRangeValueDate(null);
    callToApi(false, date);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDateRangeValue(selection);

    // 86400 One Day
    let startDate = new Date(selection.startDate);
    let endDate = new Date(selection.endDate);
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    setDateRangeValueDate({ startDate: startDate, endDate: endDate });
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!popupShow && dateRangeValueDate) {
      callToApi(dateRangeValueDate, dateRangeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupShow]);

  const callToApi = (flag, dateIs) => {
    if (flag) {
      let startDate = new Date(dateIs.startDate);
      let endDate = new Date(dateIs.endDate);
      startDate = Math.floor(startDate.getTime() / 1000);
      endDate = Math.floor(endDate.getTime() / 1000) + 86400;

      commonFunction.callGetMapviewTableDataByFilter(namespace, [
        filter.id,
        "filter_by",
        {
          id: filter.id,
          operator: "custom_date_range",
          human_date: "",
          custom_date_range: [
            convertNumbetToDate(startDate, "date", "number"),
            convertNumbetToDate(endDate, "date", "number")
          ]
        }
      ]);
    } else {
      commonFunction.callGetMapviewTableDataByFilter(namespace, [filter.id, "filter_by", null]);
    }
  };

  return (
    <div className="mapview_filter_field_outerBox" ref={isClickOutside}>
      <span className="mapview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
        <span className="title">{filter.display}</span>
        {dateRangeValueDate ? (
          <span>
            {dateRangeValueDate.startDate} - {dateRangeValueDate.endDate}
          </span>
        ) : (
          <span className="exp">DD MM YYYY - DD MM YYYY</span>
        )}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="mapview_filter_field">
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[dateRangeValue]}
            direction="horizontal"
          />
          <button className="zino_btn_outline_primary" size="small" onClick={handleReset}>
            Reset
          </button>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerField;
