import React, { useLayoutEffect, useState, useCallback, useEffect } from "react";

import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import axios from "axios";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import { useFormHandlerContext } from "../FormContext";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
import { LookupDropdown } from "../html/LookupInputTag";
import { convertNumbetToDate, convertNumbetToTime } from "../../utils/commonFunction";

function FFLookupField({ component, currentConfigKey, namespace }) {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  const { openFillNewLookupForm, formExData, setFormExData, isOpenExForm } = useFormHandlerContext();

  const [outputOption, setOutputOption] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ""
  );

  const [inputOption, setInputOption] = useState([]);

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async (term, search_iId = null) => {
    try {
      let field_Data = {
        field_id: component.id
      };
      if (formConfigData.id) field_Data["form_id"] = formConfigData.id;
      if (formConfigData.sm_id) field_Data["sm_id"] = formConfigData.sm_id;
      if (formConfigData.activity_id) field_Data["activity_id"] = formConfigData.activity_id;
      if (formConfigData.instance_id) field_Data["instance_id"] = formConfigData.instance_id;
      // if (formConfigData.entity_id) field_Data["entity_id"] = formConfigData.entity_id;
      // if (formConfigData.sm_activity_id) field_Data["activity_id"] = formConfigData.sm_activity_id;

      let { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          Params: {
            search_query: {
              search_term: term || "",
              search_fields: [],
              facet_by: [],
              page: 1,
              per_page: 10,
              instance_id: search_iId || null
            },
            table_id: component.table_id
          },
          data: field_Data,
          function_name: "perform_search"
        },
        { headers: getApiCallHeadersData() }
      );

      let document = data?.data?.documents || [];
      setInputOption(document);
      return document;
    } catch (error) {
      setInputOption([]);
      return [];
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  useEffect(() => {
    if (component.show_default_option) {
      apiCallAndGetDataOptimizedFun("", outputOption?.id);
      // apiCallAndGetDataDebounce(apiCallAndGetData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    if (outputOption && inputOption.length > 0) {
      let flag = Object.keys(outputOption).length < 3;
      if (flag) {
        for (let index = 0; index < inputOption.length; index++) {
          const element = inputOption[index];
          if (element?.id === outputOption?.instance_id) {
            validate(element);
            break;
          }
        }
      }
    } else if (outputOption && inputOption.length === 0 && !component.show_default_option) {
      apiCallAndGetData("", outputOption?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputOption, inputOption]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setOutputOption(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    setOutputOption(value);

    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
    // setInputOption([]);
  };

  const getOptionLabelFun = (option) => {
    if (!option) return;
    let label = [];
    for (let index = 0; index < component.display_fields.length; index++) {
      const element = component.display_fields[index];
      let ans = [];
      getDataFromDataObjectByKey(option, ans, element.id);
      if (["datetime", "date", "time"].includes(element.field_type)) {
        for (let ihai = 0; ihai < ans.length; ihai++) {
          const dateItem = ans[ihai];
          if (element.field_type === "time") {
            ans[ihai] = convertNumbetToTime(dateItem);
          } else {
            ans[ihai] = convertNumbetToDate(dateItem, element.field_type, "string", ["dd", "mm", "yyyy"]);
          }
        }
      }
      label = [...label, ...ans];
    }

    // return component.display_fields
    //   .map((field) => {
    //     return option[field.id];
    //   })
    //   .join(" | ");
    return label.join(" , ");
  };

  const openFormHandler = () => {
    openFillNewLookupForm({
      table_id: component.table_id,
      function_name: "register_entity",
      field_id: currentConfigKey
    });
  };

  const callFun = async (lookupData) => {
    await commonFunction.callChackFiledFunction(namespace, lookupData, currentConfigKey, component, true);
  };

  useEffect(() => {
    if (isOpenExForm || !formExData || formExData["field_id"] !== currentConfigKey) return;
    let lookupData = formExData["form_response"];
    if (lookupData) {
      callFun(lookupData);
      setInputOption([lookupData]);
      setOutputOption(lookupData);
    }
    setFormExData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenExForm]);

  const mapLookup = () => {
    if (!inputOption.length > 0) return inputOption;

    const lookupItems = inputOption.map((lookup, index) => {
      lookup["sm_id"] = component.table_id;
      lookup["instance_id"] = lookup.id;
      const label = getOptionLabelFun(lookup);

      const displayObj = {
        name: label,
        id: lookup.id,
        data: lookup
      };

      return displayObj;
    });
    return lookupItems;
  };

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <div className="w_100">
        <LookupDropdown
          options={mapLookup()}
          isSearchable={true}
          selectedVal={outputOption?.["id"] || outputOption || null}
          keyName="id"
          handleChange={(value) => {
            validate(value);
          }}
          isLookup={true}
          label={component.label}
          apiCallAndGetDataOptimizedFun={apiCallAndGetDataOptimizedFun}
          disabled={component.disable}
        />
      </div>
      {component.allow_add_entity && (
        <div className="form_field_outer_box" style={{ width: "100%", alignItems: "flex-end", marginTop: "8px" }}>
          <span style={{ fontSize: "14px" }}>
            Not Found?{" "}
            <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={openFormHandler}>
              Add New
            </span>
          </span>
        </div>
      )}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id || component._id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default FFLookupField;
