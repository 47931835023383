import axios from "axios";
import toast from "react-hot-toast";
import { setHideComponentKeyInConfig } from "../../utils/updateConfigFunction";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import {
  desktopVersionCheck,
  getApiCallHeadersData,
  getStorageItem,
  setStorageItem
} from "../../utils/storageFunction";
import moment from "moment";
import { injectCSS } from "../../utils/loadfiles";

export const DASHBOARD_CONFIG_APP_DATA = "DASHBOARD_CONFIG_APP_DATA";
export const DASHBOARD_CONFIG_ORG_DATA = "DASHBOARD_CONFIG_ORG_DATA";
export const DASHBOARD_APPS_LIST_DATA = "DASHBOARD_APPS_LIST_DATA";
export const WORKFLOW_APP_OBJECT_DATA = "WORKFLOW_APP_OBJECT_DATA";
export const ACTION_JOB_RES_DATA = "ACTION_JOB_RES_DATA";
export const ERROR_SHOW = "ERROR_SHOW";
export const NOTIFICATIONS_ARRAY = "NOTIFICATIONS_ARRAY";
export const DASHBOARD_PUBLIC_APPS_LIST_DATA = "DASHBOARD_PUBLIC_APPS_LIST_DATA";

export const setWorkflowAppObjectData = (payload) => ({
  type: WORKFLOW_APP_OBJECT_DATA,
  payload
});

export const setDashboardAppConfig = (payload) => ({
  type: DASHBOARD_CONFIG_APP_DATA,
  payload
});

export const setDashboardAppsList = (payload) => ({
  type: DASHBOARD_APPS_LIST_DATA,
  payload
});

export const setDashboardPublicAppsList = (payload) => ({
  type: DASHBOARD_PUBLIC_APPS_LIST_DATA,
  payload
});

export const setNotificationsFunction = (payload) => ({
  type: NOTIFICATIONS_ARRAY,
  payload
});

export const setDashboardOrganizationConfig = (payload) => ({
  type: DASHBOARD_CONFIG_ORG_DATA,
  payload
});

export const setActionJobResData = (payload) => ({
  type: ACTION_JOB_RES_DATA,
  payload
});

export const setErrorShow = (payload) => ({
  type: ERROR_SHOW,
  payload
});

export const getAppsListFunction = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_AUTH_HOST}/api/v1/applist`, {
      headers: getApiCallHeadersData()
    });
    dispatch(setDashboardAppsList(data?.app_list || []));
  } catch (error) {
    toast.error(
      error.response?.data?.message || error.response?.data.error || "App not found",
      toastErrorMessageStyle()
    );
    // window.open("/page404", "_self");
    // window.history.back();
  }
};
export const getAppsListForPublicScreensFunction = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_AUTH_HOST}/api/v1/guest/applist`, {
      // headers: getApiCallHeadersData()
    });
    dispatch(setDashboardPublicAppsList(data?.app_list || []));
  } catch (error) {
    toast.error(
      error.response?.data?.message || error.response?.data.error || "App not found",
      toastErrorMessageStyle()
    );
    // window.open("/page404", "_self");
    // window.history.back();
  }
};

export const getDashboardConfigFunction = (page) => async (dispatch) => {
  try {
    let cacheresponse = await getCacheResponse();

    dispatch(setDashboardAppConfig({}));

    let apiPayload = {
      data: "",
      function_name: "get_screen",
      params: {
        device: "desktop",
        name: page
      }
    };

    if (Object.keys(cacheresponse?.value || {}).length > 0) {
      apiPayload.params["token_data"] = cacheresponse?.value || {};
    }

    let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, apiPayload, {
      headers: getApiCallHeadersData()
    });

    desktopVersionCheck(data.version.desktop);

    data = data.data;
    data.layout = await setHideComponentKeyInConfig(data.layout);

    // Load CSS
    const cssRoot = document.getElementById("cssroot");
    const themeId = getStorageItem("Zino_app_theme_id");
    if (!cssRoot.innerHTML || data.theme_id !== themeId) {
      const styleFlie = document.createElement("link");
      styleFlie.href = `${getApiCallLocalPath()}api/v1/assets/${data.theme_id}.css`;
      styleFlie.rel = "stylesheet";
      cssRoot.innerHTML = "";
      cssRoot.appendChild(styleFlie);
      setStorageItem("Zino_app_theme_id", data.theme_id);
    }

    let c0s1s0R2o2o3t = document.getElementById("load_extra_css");
    c0s1s0R2o2o3t.innerHTML = "";
    let j2s0R2o5o0t = document.getElementById("load_extra_js");
    j2s0R2o5o0t.innerHTML = "";

    let css = data?.custom_css || "";
    if (css.trim()) {
      injectCSS(css);
    }

    if (data?.header?.logo) {
      let logo = data?.header?.logo;
      if (!logo.logotext && !logo.logosrc) {
        logo = {
          logotextsize: "24",
          logotextweight: "800",
          logosize: "",
          logosrc: "",
          logotext: "HOME",
          logotextcolor: "#f94b01"
        };
      }
      logo["lastpage"] = `/${page}`;
      setStorageItem("Zino_app_data", JSON.stringify(logo));
    } else {
      setStorageItem(
        "Zino_app_data",
        JSON.stringify({
          logotextsize: "24",
          logotextweight: "800",
          logosize: "",
          logosrc: "",
          logotext: "HOME",
          logotextcolor: "#f94b01",
          lastpage: `/${page}`
        })
      );
    }

    dispatch(
      setDashboardAppConfig({
        header: data?.header || {},
        page: data?.layout || [],
        custom_css: data.custom_css || "",
        custom_js: data.custom_js || "",
        is_access: data.is_access,
        page_name: data?.page_name || "applist",
        screen_settings: data?.screen_settings || {},
        cacheresponse: cacheresponse || {}
      })
    );
  } catch (error) {
    toast.error(
      error.response?.data?.message || error.response?.data.error || "App not found",
      toastErrorMessageStyle()
    );
    dispatch(setDashboardAppConfig({}));

    window.open(getStorageItem("Zino_app_user_go_to") || "/noaccess", "_self");
    // window.history.back();
  }
};

const getCacheResponse = async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (!token) {
      return {};
    }
    let cacheresponse = await axios.get(`${getApiCallLocalPath()}api/v1/cache/${token}`, {
      headers: getApiCallHeadersData()
    });

    if (cacheresponse?.data?.data) {
      cacheresponse = cacheresponse.data.data;
    } else {
      cacheresponse = {};
    }
    return cacheresponse;
  } catch (error) {
    window.history.back();
    return {};
  }
};

export const getDashboardConfigForPublicScreensFunction = (page) => async (dispatch) => {
  try {
    dispatch(setDashboardAppConfig({}));
    let { data } = await axios.get(`${getApiCallLocalPath("public")}api/v1/guest/${page}/desktop`);

    desktopVersionCheck(data.version.desktop);

    data = data.data;
    data.layout = await setHideComponentKeyInConfig(data.layout);

    // Load CSS
    const cssRoot = document.getElementById("cssroot");
    if (!cssRoot.innerHTML || page === "home") {
      const styleFlie = document.createElement("link");
      styleFlie.href = `${getApiCallLocalPath()}api/v1/assets/${data.theme_id}.css`;
      styleFlie.rel = "stylesheet";
      cssRoot.innerHTML = "";
      cssRoot.appendChild(styleFlie);
    }

    dispatch(
      setDashboardAppConfig({
        header: data?.header || {},
        page: data?.layout || [],
        custom_css: data.custom_css || "",
        custom_js: data.custom_js || "",
        is_access: data.is_access,
        page_name: data?.page_name || "applist"
      })
    );
  } catch (error) {
    toast.error(
      error.response?.data?.message || error.response?.data.error || "App not found",
      toastErrorMessageStyle()
    );
    dispatch(setDashboardAppConfig({}));
    window.history.back();
  }
};

export const getSubPageConfigFunction = (page) => async (dispatch) => {
  dispatch(getDashboardConfigFunction(page));
};

export const getNotificationsFunction = (user) => async (dispatch) => {
  try {
    let { data } = await axios.post(
      `${getApiCallLocalPath()}api/v1/todo/allitems`,
      {
        user_id: user?.login_user_id || ""
      },
      {
        headers: getApiCallHeadersData()
      }
    );

    if (!data?.data) data = [];
    else data = data.data;

    let notifications = data.map((dataItem) => ({
      text:
        dataItem.sm_name +
        " - " +
        dataItem.activity_name +
        " - " +
        moment(dataItem.created_at).format("DD-MM-YYYY HH:mm"),
      ...dataItem
    }));

    // Add notification api call
    dispatch(setNotificationsFunction(notifications));
  } catch (error) {
    toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
  }
};
