import { useEffect, useMemo, useState } from "react";
import ColumnContainer from "./ColumnContainer";
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./TaskCard";
import { useSelector } from "react-redux";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import toast from "react-hot-toast";

function KanbanBoard({ statesList, transitionsList, sm_id, namespace, layout }) {
  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState(statesList);
  const [transitions, setTransitions] = useState({});
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  const [tasks, setTasks] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const kanbandata = useSelector((state) => state.kanbanviewstore[`${namespace}_kanbanData`]) || {};
  const { createNewTask } = usePageHandlerContext();
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  );

  function handleTasks() {
    let temp = [];
    for (let i = 0; i < kanbandata?.documents?.length; i++) {
      temp.push(kanbandata?.documents[i]);
    }
    if (temp.length !== 0) {
      setTasks(temp);
    }
  }

  function hanldeTransition() {
    let tempTransitions = [...transitionsList];
    let test = tempTransitions.reduce((result, item) => {
      const { from_id, to_id } = item;
      if (!result[from_id]) {
        result[from_id] = [to_id];
      } else {
        result[from_id].push(to_id);
      }
      return result;
    }, {});
    if (kanbandata?.documents?.length !== 0) {
      setTransitions(test);
    }
  }

  async function PerformTransitionCall({ instance_id, state_id }) {
    try {
      const { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          data: {},
          function_name: "perform_transition",
          params: {
            sm_id: sm_id,
            instance_id: instance_id
          }
        },
        { headers: getApiCallHeadersData() }
      );
      const matchingObject = await data.data.find((item) => item.id === state_id);
      if (matchingObject) {
        const activity_id = await matchingObject.source_activity;
        const action_config = {
          action_in: "perform_transition",
          action_name: "perform_activity",
          activity_id: activity_id,
          params: {
            sm_id: sm_id
          },
          sm_id: sm_id,
          state_id: state_id,
          instance_id: instance_id
        };
        const element_config = {
          element_id: namespace,
          action_start: {
            hide: [],
            refresh: [],
            show: []
          },
          action_end: {
            hide: [],
            refresh: [namespace],
            show: []
          }
        };
        await createNewTask(element_config, action_config, {});
      } else {
        toast.error("Transition Not Allowed", toastErrorMessageStyle());
      }
    } catch (error) {
      toast.error("Transition Not Allowed", toastErrorMessageStyle());
    }
  }

  useEffect(() => {
    hanldeTransition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbandata]);

  return (
    <div className="kanbanMainContainer">
      <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver}>
        <div className="kanbanColumnContainer">
          <SortableContext items={columnsId}>
            {columns.map((state) => (
              <div
                key={state.id}
                style={{
                  backgroundColor: `${hoveredColumn === state.id ? "#CCCCCC" : "#FFFFFF"}`,
                  border: "1px solid #DADCE0",
                  padding: "12px",
                  minWidth: "20%"
                }}
              >
                <ColumnContainer
                  column={state}
                  tasks={tasks.filter((task) => task.current_state === state.id)}
                  layout={layout}
                  namespace={namespace}
                  sm_id={sm_id}
                />
              </div>
            ))}
          </SortableContext>
        </div>

        {createPortal(
          <DragOverlay>{activeTask && <TaskCard task={activeTask} namespace={namespace} sm_id={sm_id} />}</DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );

  function onDragStart(event) {
    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  function onDragOver(event) {
    const { over } = event;
    setHoveredColumn(over?.id || null);
  }

  async function onDragEnd(event) {
    // setActiveTask(null);
    setHoveredColumn(null);
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;
    if (activeId === overId) return;
    const ActiveSortableColumn = active.data.current.sortable.containerId;
    const OverSortableColumn = over.data.current.sortable.containerId;
    // Im dropping a Task over another Task
    if (ActiveSortableColumn === OverSortableColumn) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);

        if (tasks[activeIndex].columnId !== tasks[overIndex].columnId) {
          tasks[activeIndex].columnId = tasks[overIndex].columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }
        return arrayMove(tasks, activeIndex, overIndex);
      });
    } else {
      // const activeIndex = tasks.findIndex((t) => t.id === activeId);
      const activeTest = activeTask.current_state;
      if (transitions[activeTest]?.includes(overId)) {
        await PerformTransitionCall({ instance_id: activeId, state_id: overId });
      }
      // setTasks((tasks) => {
      //   tasks[activeIndex].current_state = overId;
      //   tasks[activeIndex].current_state_str = over.data.current.column.name
      //   // tasks[activeIndex]
      //   return arrayMove(tasks, activeIndex, activeIndex);
      // });
    }
  }
}

export default KanbanBoard;
