import React, { useEffect, useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { getApiCallLocalPath } from "../../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../../utils/storageFunction";
import { checkDataUniqueOrNot, getDataById, validateGridCollItem } from "../utils/storeFunction";
import { commonFunction } from "../../utils/commonFunction";
import { FSIconRenderer } from "../../../zinoIcon";

const FGAppUserField = ({
  namespace,
  gridCollItem,
  currentConfigKey,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  const storeData = useSelector((state) => state.formviewstore);
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  const [outputOption, setOutputOption] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || {}
  );
  const [outputOptionError, setOutputOptionError] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []
  );
  const [inputOption, setInputOption] = useState([]);
  const [inputOptionFilterData, setInputOptionFilterData] = useState([]);
  const [inputOptionIds, setInputOptionIds] = useState([]);
  const [termText, setTermText] = useState("");
  const [loading, setLoading] = useState(false);

  const apiCallAndGetData = async (term) => {
    try {
      setLoading(true);
      setInputOption([]);
      setInputOptionIds([]);

      let field_Data = {
        field_id: gridCollItem.id
      };
      if (formConfigData.id) field_Data["form_id"] = formConfigData.id;
      if (formConfigData.sm_id) field_Data["sm_id"] = formConfigData.sm_id;
      if (formConfigData.activity_id) field_Data["activity_id"] = formConfigData.activity_id;
      // if (formConfigData.entity_id) field_Data["entity_id"] = formConfigData.entity_id;
      // if (formConfigData.sm_activity_id) field_Data["activity_id"] = formConfigData.sm_activity_id;

      let { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          Params: gridCollItem?.app_user_filter || {},
          data: field_Data,
          function_name: "get_app_users"
        },
        { headers: getApiCallHeadersData() }
      );

      let document = data?.data?.users || [];
      let arrayIds = [];
      for (let index = 0; index < document.length; index++) {
        const lookupOption = document[index];
        arrayIds.push(`${currentConfigKey}_${lookupOption.id}_lookup_option`);
      }
      setInputOptionIds(arrayIds);
      setInputOption(document);
      return document;
    } catch (error) {
      setInputOption([]);
      setInputOptionIds([]);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stateObject.gridComponentFormElements) {
      setOutputOption(getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElements]);

  useEffect(() => {
    setOutputOptionError(getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElementsError]);

  useEffect(() => {
    let array = [];
    const startWithPattern = new RegExp(`^${termText}.*$`, "i");
    for (let index = 0; index < inputOption.length; index++) {
      const element = inputOption[index];
      if (startWithPattern.test(element?.name)) {
        array.push(element);
      }

      if (array.length === 10) {
        break;
      }
    }

    setInputOptionFilterData(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption, termText]);

  useEffect(() => {
    apiCallAndGetData("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionKeydownFunction = (event, data = {}) => {
    let currentCollItemId = event.target.id;
    let nextCollItemId = "";

    switch (event.key) {
      case "ArrowUp":
        // if (inputOptionIds.length === 0) {
        //   commonFunctionObject.keyboardKeydownFun(event);
        // }
        if (currentCollItemId === `${currentConfigKey}_lookup_option`) {
          nextCollItemId = currentConfigKey;
        } else if (currentCollItemId === inputOptionIds[0]) {
          nextCollItemId = `${currentConfigKey}_lookup_option`;
        } else {
          let index = inputOptionIds.indexOf(currentCollItemId);
          if (index !== -1) {
            nextCollItemId = inputOptionIds?.[index - 1] || "";
          }
        }
        event.preventDefault();
        break;

      case "ArrowDown":
        if (currentCollItemId === currentConfigKey) {
          nextCollItemId = `${currentConfigKey}_lookup_option`;
        } else if (currentCollItemId === `${currentConfigKey}_lookup_option`) {
          nextCollItemId = inputOptionIds[0];
        } else {
          let index = inputOptionIds.indexOf(currentCollItemId);
          if (index !== -1) {
            nextCollItemId = inputOptionIds?.[index + 1] || "";
          }
        }
        event.preventDefault();
        break;

      case "Tab":
        if (event.shiftKey) {
          commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey, "left");
          event.preventDefault();
        } else {
          commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey, "right");
          event.preventDefault();
        }

        // const endWithPattern = new RegExp(`^.*${"_lookup_option"}$`, "i");
        // if (endWithPattern.test(currentCollItemId)) {
        // } else {
        // }
        break;

      case "ArrowLeft":
        // if (!termText && inputOptionIds.length === 0) {
        //   commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey);
        //   event.preventDefault();
        // }
        break;

      case "ArrowRight":
        // if (!termText && inputOptionIds.length === 0) {
        //   commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey);
        //   event.preventDefault();
        // }
        break;

      case "Enter":
        validate(event, data, true);
        break;

      default:
        break;
    }

    if (nextCollItemId) {
      let nextItem = document.getElementById(nextCollItemId);
      nextItem.focus();
    }
  };

  const validate = async (e, value, addNewRowFlag) => {
    if (gridCollItem.unique) {
      const uniqueDataFlag = checkDataUniqueOrNot(
        stateObject.gridComponentFormElements,
        gridCollItem.id,
        value || "",
        gridFlagStateObject.gridRowIndex
      );
      if (uniqueDataFlag) {
        value = {};
      }
    }

    setOutputOption(value);

    await validateGridCollItem(
      e,
      namespace,
      currentConfigKey,
      stateObject,
      value || {},
      gridCollItem,
      commonFunction,
      gridFlagStateObject,
      addNewRowFlag,
      stateFunctionObject,
      commonFunctionObject,
      true
    );
  };

  if (stateObject.gridFocusedElement === currentConfigKey) {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type="text"
          id={currentConfigKey}
          className={"formGrid_coll_item_input_focus"}
          onChange={(e) => {
            setTermText(e.target.value);
          }}
          value={termText}
          onKeyDown={optionKeydownFunction}
          disabled={gridCollItem.disable}
          autoComplete="off"
          style={{ borderColor: outputOptionError.length > 0 && "red" }}
        />

        <div id={currentConfigKey + "_optionlist"} className="formGrid_coll_lookup_option_outbox">
          <input
            type="text"
            id={currentConfigKey + "_lookup_option"}
            className={"formGrid_coll_lookup_option"}
            onKeyDown={optionKeydownFunction}
            value={inputOptionFilterData.length > 0 ? "Select Option" : "No Data"}
            readOnly={true}
          />
          {loading && (
            <div className="zino_renderer_loader_box" style={{ height: "32px" }}>
              <span className="zino_renderer_line_loader" style={{ top: "-20px" }}></span>
            </div>
          )}
          {inputOptionFilterData.map((lookupOption) => {
            return (
              <input
                type="text"
                id={currentConfigKey + "_" + lookupOption.id + "_lookup_option"}
                key={currentConfigKey + "_" + lookupOption.id + "_lookup_option"}
                className={"formGrid_coll_lookup_option"}
                onClick={(e) => validate(e, lookupOption, false)}
                onKeyDown={(e) => optionKeydownFunction(e, lookupOption)}
                value={lookupOption?.name}
                title={lookupOption?.name}
                readOnly={true}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type="text"
          id={currentConfigKey}
          className={"formGrid_coll_item_input"}
          onFocus={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          onClick={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          value={outputOption?.name || ""}
          title={outputOption?.name || ""}
          readOnly={true}
          disabled={gridCollItem.disable}
          style={{ borderColor: outputOptionError.length > 0 && "red" }}
        />
        {outputOptionError.length > 0 && (
          <span className="formGrid_coll_item_input_icon">
            <FSIconRenderer icon={"error"} iconColor={"red"} marginR="0" iconTitle={outputOptionError.join(" , ")} />
          </span>
        )}
      </div>
    );
  }
};

export default FGAppUserField;
