import React, { useRef } from "react";
import { gridCommonFunction } from "../utils/gridCommonFunction";
import {
  JobActionRenderer,
  SectionRenderer,
  TableViewRenderer,
  TabViewRenderer,
  DropdownActionRenderer
} from "../index";
import CardItems from "../sectionrendererlib/card/CardItems";
import TimelineRenderer from "../timeline/TimelineRenderer";
import RecordsetRenderer from "./RecordsetRenderer";
import ActivityCardRender from "./ActivityCardRender";
import { FSIconRenderer } from "../zinoIcon";

import { useReactToPrint } from "react-to-print";
import { JobActionRendererButtonGroup } from "../actionlib/jobactionlib/JobActionRenderer";
import FormComponent from "../../components/appview/FormComponent";
import MailThreadRenderer from "./MailThreadRenderer";
import DynamicHtml, { FileViewer, HtmlRender } from "./DynamicHtml";
import ActivityChartRender from "./ActivityChartRender";
import ActivityRecordsviewRender from "./ActivityRecordsviewRender";
import { stringToJSONChange } from "../utils/commonFunction";
import StateDvProgressBar from "./StateDvProgressBar";

const DetailsViewItem = ({
  itemConfig,
  detailsViewDataFormData,
  detailsViewDataTimeline,
  activityCardsConfig,
  detailsViewDataActivityCard,
  fieldLabelFlag,
  detailsViewItemConfig,
  detailsViewDataActivitiesHistory,
  printThis = true,
  jobParamsConfig,
  checkbox,
  handleCheckboxClick,
  selectedItems,
  detailsViewDataOrg,
  functionObject,
  callFrom = "inside"
}) => {
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < itemConfig.length; index++) {
    let gridElement = itemConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }

  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    copyStyles: true,
    removeAfterPrint: true,
    content: () => componentRef.current,
    pageStyle: () => {
      return detailsViewDataOrg?.detailview_settings?.print_custom_css || "";
    }
  });

  return (
    <>
      <style>
        {`
          @media print {
            .floatIcon {
              display: none;
            }
          }
        `}
      </style>
      <div style={styleDataForGrid} ref={componentRef}>
        {/* <div style={styleDataForGrid} ref={componentRef} id={callFrom === "outside" ? detailsViewItemConfig?.id : ""}> */}
        {rowItemElements.map((colItem, colItemIndexIs) => {
          let styleObject = {
            gridArea: `zino-${colItem?.i || colItem.id}`
          };
          let class_name = "";
          if (colItem.className) {
            if (typeof colItem.className === "object") {
              class_name = Object.values(colItem.className).join(" ");
            } else {
              class_name = colItem.className;
            }
          }
          if (colItem.hide) {
            return undefined;
          } else {
            switch (colItem.type) {
              case "button":
                return (
                  <div className="detailsView_btn" key={colItem.id + colItemIndexIs} style={styleObject}>
                    <JobActionRenderer
                      itemConfig={colItem}
                      jobParamsConfig={jobParamsConfig}
                      reqData={detailsViewDataFormData}
                      functionObject={functionObject}
                    />
                  </div>
                );
              case "button-group":
                return (
                  <div className="detailsView_btn" key={colItem.id + colItemIndexIs} style={styleObject}>
                    <JobActionRendererButtonGroup
                      itemConfig={colItem}
                      jobParamsConfig={jobParamsConfig}
                      reqData={detailsViewDataFormData}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "dropdown":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject} className="detailsView_dropdown">
                    <DropdownActionRenderer
                      itemConfig={colItem}
                      jobParamsConfig={jobParamsConfig}
                      reqData={detailsViewDataFormData}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "section":
                return (
                  <div className="details_block_outer" key={colItem.id + colItemIndexIs} style={{ ...styleObject }}>
                    <div
                      className={"detailsView_section_outer"}
                      // style={{
                      //   height: colItem.height ? `${colItem.height}px` : "100%"
                      // }}
                      style={{
                        height: colItem.height ? `${colItem.height}px` : "100%",
                        ...{ styleObject }
                        // ...(colItem.inline_style || {}),
                        // ...(stringToJSONChange(colItem.custom_css) || {})
                      }}
                    >
                      <SectionRenderer
                        itemConfig={colItem}
                        detailsViewData={detailsViewDataFormData}
                        detailsViewDataTimeline={detailsViewDataTimeline}
                        activityCardsConfig={activityCardsConfig}
                        detailsViewDataActivityCard={detailsViewDataActivityCard}
                        fieldLabelFlag={fieldLabelFlag}
                        detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                        jobParamsConfig={jobParamsConfig}
                        checkbox={checkbox}
                        handleCheckboxClick={handleCheckboxClick}
                        selectedItems={selectedItems}
                        detailsViewDataOrg={detailsViewDataOrg}
                        functionObject={functionObject}
                      />
                    </div>
                  </div>
                );

              case "timeline":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject} className="detailsView_TimeLine">
                    <TimelineRenderer
                      itemConfig={colItem}
                      timelineViewData={detailsViewDataTimeline}
                      activityCardsConfig={activityCardsConfig}
                      detailsViewDataActivityCard={detailsViewDataActivityCard}
                      fieldLabelFlag={false}
                      detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                      jobParamsConfig={jobParamsConfig}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "activitycard":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <ActivityCardRender
                      itemConfig={activityCardsConfig[colItem.id]}
                      activityCardsConfig={activityCardsConfig}
                      detailsViewDataActivityCard={detailsViewDataActivityCard}
                      detailsViewDataTimeline={detailsViewDataTimeline}
                      fieldLabelFlag={fieldLabelFlag}
                      detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                      jobParamsConfig={jobParamsConfig}
                      detailsViewDataOrg={detailsViewDataOrg}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "activity-chart":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <ActivityChartRender
                      itemConfig={colItem}
                      chartDataArray={detailsViewDataOrg?.activity_chart_data || []}
                      jobParamsConfig={jobParamsConfig}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "activity-recordsview":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <ActivityRecordsviewRender
                      itemConfig={colItem}
                      recordsviewDataArray={detailsViewDataOrg?.activity_recordview_data || []}
                      jobParamsConfig={jobParamsConfig}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "recordset":
              case "recordset-list":
              case "grid":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <RecordsetRenderer itemConfig={colItem} detailsViewData={detailsViewDataFormData} />
                  </div>
                );
              case "rich-grid":
                return <div key={colItem.id + colItemIndexIs} style={styleObject}></div>;

              case "tableview":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <TableViewRenderer
                      dataConfig={colItem.config}
                      tableConfig={colItem}
                      detailsViewData={detailsViewDataFormData}
                    />
                  </div>
                );

              case "form":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <FormComponent
                      itemConfig={colItem}
                      extraRowData={{
                        ...(jobParamsConfig || {}),
                        instance_ids:
                          detailsViewDataFormData["id"] || detailsViewDataFormData["_id"]
                            ? [detailsViewDataFormData["id"] || detailsViewDataFormData["_id"]]
                            : null,
                        instance_id: detailsViewDataFormData["id"] || detailsViewDataFormData["_id"] || ""
                      }}
                      functionObject={functionObject}
                      jobParamsTokenConfig={jobParamsConfig}
                    />
                  </div>
                );

              case "tabview":
                return (
                  <div className="dashboard_tabview" key={colItem.id + colItemIndexIs} style={styleObject}>
                    <TabViewRenderer
                      configData={colItem}
                      detailsViewData={detailsViewDataFormData}
                      detailsViewDataTimeline={detailsViewDataTimeline}
                      activityCardsConfig={activityCardsConfig}
                      detailsViewDataActivityCard={detailsViewDataActivityCard}
                      detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                      fieldLabelFlag={fieldLabelFlag}
                      jobParamsConfig={jobParamsConfig}
                      detailsViewDataOrg={detailsViewDataOrg}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "mail_thread":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <MailThreadRenderer
                      configData={colItem}
                      jobParamsConfig={jobParamsConfig}
                      detailsViewData={detailsViewDataFormData}
                    />
                  </div>
                );

              case "html":
                return (
                  <HtmlRender
                    key={colItem.id + colItemIndexIs}
                    colItem={colItem}
                    styleObject={styleObject}
                    jobParamsConfig={jobParamsConfig}
                  />
                );
              case "dynamic-html":
                return (
                  <DynamicHtml
                    class_name={`dashboard_title ${class_name}`}
                    key={colItem.id + colItemIndexIs}
                    colItem={colItem}
                    styleObject={styleObject}
                    detailsViewDataFormData={detailsViewDataFormData}
                    jobParamsConfig={jobParamsConfig}
                  />
                );

              case "file_viewer":
                return (
                  <FileViewer
                    class_name={`dashboard_title ${class_name}`}
                    key={colItem.id + colItemIndexIs}
                    colItem={colItem}
                    styleObject={styleObject}
                    detailsViewDataFormData={detailsViewDataFormData}
                    jobParamsConfig={jobParamsConfig}
                  />
                );

              case "variable":
                return (
                  <div
                    key={colItem.id + colItemIndexIs}
                    style={{
                      ...styleObject,
                      ...(colItem.inline_style || {}),
                      ...(stringToJSONChange(colItem.custom_css) || {})
                    }}
                    id={`${colItem?.custom_id || ""}`}
                    className={`${colItem?.custom_classname || ""}`}
                  >
                    <p>{detailsViewDataFormData?.[colItem.id] || "-"}</p>
                  </div>
                );
              case "label":
                return (
                  <div
                    className={`${class_name} ${colItem?.custom_classname || ""} block_label`}
                    key={colItem.id + colItemIndexIs}
                    id={`${colItem?.custom_id || ""}`}
                    style={{
                      ...styleObject,
                      display: "flex",
                      alignItems: "center",
                      ...(colItem.inline_style || {}),
                      ...(stringToJSONChange(colItem.custom_css) || {})
                    }}
                  >
                    <FSIconRenderer icon={colItem?.icon || ""} />
                    {colItem.name}
                  </div>
                );

              case "image":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <img
                      src={colItem.source}
                      alt={colItem.name}
                      id={`${colItem?.custom_id || ""}`}
                      className={`${class_name} ${colItem?.custom_classname || ""} DetailsView_app_image`}
                      style={{ ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
                    />
                  </div>
                );

              case "empty":
                return <div key={colItem.id + colItemIndexIs} style={styleObject}></div>;

              case "field":
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    <CardItems
                      colItem={colItem}
                      dataConfig={detailsViewDataFormData}
                      layoutDataLineHeight={40}
                      fieldLabelFlag={fieldLabelFlag}
                      jobParamsConfig={jobParamsConfig}
                      functionObject={functionObject}
                    />
                  </div>
                );

              case "spacer":
                return (
                  <div
                    key={colItem.id + colItemIndexIs}
                    style={{
                      ...styleObject,
                      height: `${colItem?.height || 40}px`,
                      ...(colItem.inline_style || {}),
                      ...(stringToJSONChange(colItem.custom_css) || {})
                    }}
                    id={`${colItem?.custom_id || ""}`}
                    className={`${colItem?.custom_classname || ""}`}
                  ></div>
                );

              case "separator":
                return (
                  <div
                    key={colItem.id + colItemIndexIs}
                    style={{
                      ...styleObject
                    }}
                  >
                    <hr
                      style={{
                        ...(colItem.inline_style || {}),
                        ...(stringToJSONChange(colItem.custom_css) || {})
                      }}
                      id={`${colItem?.custom_id || ""}`}
                      className={`${colItem?.custom_classname || ""}`}
                    />
                  </div>
                );

              case "progress-bar":
                return (
                  <div
                    key={colItem.id + colItemIndexIs}
                    style={{
                      ...styleObject
                    }}
                  >
                    <StateDvProgressBar colItem={colItem} dataConfig={detailsViewDataFormData} />
                  </div>
                );

              default:
                return (
                  <div key={colItem.id + colItemIndexIs} style={styleObject}>
                    {/* {colItem.type} */}
                  </div>
                );
            }
          }
        })}
      </div>
      {printThis && (
        <div className="floatIcon" onClick={handlePrint}>
          <span className="material-symbols-outlined">print</span>
        </div>
      )}
    </>
  );
};

export default DetailsViewItem;
