import React from "react";
import "./ProcessBarSection.css";

const ProcessBarSection = () => {
  return (
    <div className="popup_loading_overlay popupBoxSectionIsOpen">
      <div className="popup_loading_box"></div>
    </div>
  );
};

const ProcessBarBox = () => {
  return (
    <div className="popup_loading_overlay_box">
      <div className="popup_loading_box"></div>
    </div>
  );
};

export default ProcessBarSection;

export { ProcessBarBox };
