import React, { useLayoutEffect, useState } from "react";
import { getDataById, validateGridCollItem } from "../utils/storeFunction";
import { commonFunction } from "../../utils/commonFunction";
import { form_images_store } from "../../utils/store";
import { FSIconRenderer } from "../../../zinoIcon";
import { getApiCallLocalPath } from "../../../../utils/apiCallFunction";

const FGFileField = ({
  namespace,
  gridCollItem,
  currentConfigKey,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  const [inputOption, setInputOption] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || []
  );

  const optionKeydownFunction = (event) => {
    // if (event.type === "blur") {
    // validate(event, false, event.target.files);
    // } else if (event.type === "keydown") {
    switch (event.key) {
      case "ArrowUp":
      case "ArrowDown":
      case "Tab":
      case "ArrowLeft":
      case "ArrowRight":
        // validate(event, false, event.target.files);
        commonFunctionObject.keyboardKeydownFun(event);
        break;
      case "Enter":
        event.preventDefault();
        validate(event, true, inputOption);

        // if (gridFlagStateObject.addByEnterDataFlag) {
        //   let updatedRowData = JSON.parse(JSON.stringify(stateObject.gridComponentFormElements));
        //   let rowData = getDataById(currentConfigKey, updatedRowData, 2, 1);
        //   let x = stateObject.gridComponentFormElementsIds.length - 1;
        //   let inY = stateObject.gridComponentFormElementsDisable.lastIndexOf(false);
        //   if (currentConfigKey === stateObject.gridComponentFormElementsIds[x][inY]) {
        //     let lastDataObject = rowData;
        //     let newDataObject = {};

        //     for (let index = 0; index < stateObject.rowItemElements.length; index++) {
        //       const element = stateObject.rowItemElements[index];
        //       if (
        //         (element?.copy_previous_value && lastDataObject?.[element.id]) ||
        //         (element?.prefill_current && lastDataObject?.[element.id])
        //       ) {
        //         newDataObject[element.id] = lastDataObject[element.id];
        //       }
        //     }

        //     updatedRowData.push(newDataObject);
        //   }
        //   stateFunctionObject.setGridComponentFormElements(updatedRowData);
        // }

        break;

      default:
        break;
    }
    // }
    // commonFunctionObject.keyboardKeydownFun(event);
  };

  const validate = async (e, addNewRowFlag, value) => {
    setInputOption(value || []);

    await validateGridCollItem(
      e,
      namespace,
      currentConfigKey,
      stateObject,
      value || [],
      gridCollItem,
      commonFunction,
      gridFlagStateObject,
      addNewRowFlag,
      stateFunctionObject,
      commonFunctionObject,
      false
    );
  };

  const onSelectFile = async (e) => {
    if (gridCollItem.disable) {
      return;
    }

    let files = e.target.files;
    files = Object.values(files);

    const imagesArray = [...inputOption];

    // loop through selected files and add each image to the imagesArray
    let ifErrorIs = [];
    for (let i = 0; i < files.length; i++) {
      let selectedFile = files[i];
      let errorM = "";

      if (
        gridCollItem.fileMaxSize * 1024 * 1024 < selectedFile.size ||
        gridCollItem.fileMinSize * 1024 * 1024 > selectedFile.size
      ) {
        ifErrorIs.push(`File size should be between ${gridCollItem.fileMinSize}MB to ${gridCollItem.fileMaxSize}MB`);
        errorM = `File size should be between ${gridCollItem.fileMinSize}MB to ${gridCollItem.fileMaxSize}MB`;
      }
      let file = {
        url: "true",
        file: selectedFile,
        error: errorM,
        file_name: selectedFile.name,
        file_id: currentConfigKey + Date.now() + selectedFile.name
      };
      if (!errorM) {
        // uploadFile(selectedFile, file.file_id);
        imagesArray.push(file);
      }
    }

    // if (gridCollItem.fileMaxRange < imagesArray.length) {
    //   ifErrorIs.push(`Only ${gridCollItem.fileMaxRange} files allowed`);
    // } else if (gridCollItem.fileMinRange > imagesArray.length) {
    //   if (gridCollItem.validate.required) {
    //     ifErrorIs.push(`Select minimum ${gridCollItem.fileMinRange} files`);
    //   }
    // }
    // let dataObject = {
    //   value: imagesArray,
    //   errors: ifErrorIs
    // };

    validate(e, false, imagesArray);
  };
  useLayoutEffect(() => {
    if (stateObject.gridCollItemFormElements) {
      setInputOption(getDataById(currentConfigKey, stateObject.gridCollItemFormElements, 2) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridCollItemFormElements]);

  let imageArray = inputOption || [];

  if (stateObject.gridFocusedElement === currentConfigKey) {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type={"file"}
          id={currentConfigKey}
          className={"formGrid_coll_item_input_focus"}
          onKeyDown={optionKeydownFunction}
          disabled={gridCollItem.disable}
          autoComplete="off"
          accept={gridCollItem.type === "image" ? "image/*" : ""}
          onChange={onSelectFile}
          multiple={false}
        />
        {imageArray.length > 0 && (
          <span className="formGrid_coll_item_input_icon grid_file">
            <span></span>
            {imageArray?.[0]?.url === "true" ? (
              <ReadFile file={imageArray?.[0]?.file} dataObject={imageArray?.[0]} />
            ) : imageArray?.[0]?.url ? (
              <img
                style={{ height: "30px" }}
                src={imageArray?.[0]?.url}
                alt=""
                onError={(event) => {
                  event.target.src = require("../../../../Images/image-placeholder.png");
                  event.onerror = null;
                }}
              />
            ) : imageArray?.[0]?.name ? (
              <img
                style={{ height: "30px" }}
                src={`${getApiCallLocalPath()}api/v1/assets/fileName/${imageArray?.[0]?.name}`}
                alt={imageArray?.[0]?.name}
                onError={(event) => {
                  event.target.src = require("../../../../Images/image-placeholder.png");
                  event.onerror = null;
                }}
              />
            ) : (
              ""
            )}
            {/* <FSIconRenderer icon={"imagesmode"} marginR="0" iconTitle={imageArray?.[0]?.file_name} /> */}
            <FSIconRenderer
              icon={"delete"}
              iconColor={"red"}
              marginR="0"
              onClickFun={() => {
                validate(false, []);
                setTimeout(() => {
                  stateFunctionObject.setGridFocusedElement(currentConfigKey);
                }, 150);
              }}
            />
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type={"text"}
          id={currentConfigKey}
          className={"formGrid_coll_item_input"}
          onFocus={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          onClick={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          onChange={() => {}}
          readOnly={true}
          title={imageArray?.[0]?.file_name}
          value={imageArray?.[0]?.file_name}
          disabled={gridCollItem.disable}
        />
        {imageArray.length > 0 && (
          <span className="formGrid_coll_item_input_icon grid_file">
            <span></span>
            {imageArray?.[0]?.url === "true" ? (
              <ReadFile file={imageArray?.[0]?.file} dataObject={imageArray?.[0]} />
            ) : imageArray?.[0]?.url ? (
              <img
                style={{ height: "30px" }}
                src={imageArray?.[0]?.url}
                alt=""
                onError={(event) => {
                  event.target.src = require("../../../../Images/image-placeholder.png");
                  event.onerror = null;
                }}
              />
            ) : imageArray?.[0]?.name ? (
              <img
                style={{ height: "30px" }}
                src={`${getApiCallLocalPath()}api/v1/assets/fileName/${imageArray?.[0]?.name}`}
                alt={imageArray?.[0]?.name}
                onError={(event) => {
                  event.target.src = require("../../../../Images/image-placeholder.png");
                  event.onerror = null;
                }}
              />
            ) : (
              ""
            )}
            {/* <FSIconRenderer icon={"imagesmode"} marginR="0" iconTitle={imageArray?.[0]?.file_name} /> */}
            <FSIconRenderer
              icon={"delete"}
              iconColor={"red"}
              marginR="0"
              onClickFun={() => {
                validate(false, []);
                setTimeout(() => {
                  stateFunctionObject.setGridFocusedElement(currentConfigKey);
                }, 150);
              }}
            />
          </span>
        )}
      </div>
    );
  }
};

export default FGFileField;

// const uploadFile = (file, id) => {
//   if (!file.name) return;
//   const reader = new FileReader();
//   reader.readAsDataURL(file);
//   reader.onloadend = async () => {
//     form_images_store[id] = reader.result;
//   };
// };

const ReadFile = ({ file, dataObject }) => {
  const [previewURL, setPreviewURL] = useState("");

  if (file?.name) {
    // if()
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      form_images_store[dataObject.file_id] = reader.result;
      setPreviewURL(reader.result);
    };

    return <img style={{ height: "30px" }} src={previewURL} alt="" />;
  } else {
    if (form_images_store[dataObject.file_id]) {
      return <img style={{ height: "30px" }} src={form_images_store[dataObject.file_id]} alt="" />;
    }
    return <img style={{ height: "30px" }} src={""} alt="" />;
  }
};
