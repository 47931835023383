import React, { useLayoutEffect, useState } from "react";
import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import { ApplearingText } from "../html/LearningText";

const FFLearningTextField = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [outputOption, setOutputOption] = useState({
    value: commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ""
  });

  function removeEmptyLabel(objects) {
    return objects.filter((obj) => {
      if (typeof obj === "string") {
        return obj?.trim() !== "";
      }
      return false;
    });
  }

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  let inputOption = removeEmptyLabel(component.data.values);

  if (component?.sort) {
    inputOption.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setOutputOption({ value: commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "" });
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    if (component.allow_new) {
      setOutputOption({ ...{ value: value } });
      await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
    } else {
      if (inputOption.includes(value)) {
        setOutputOption({ ...{ value: value } });
        await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
      } else {
        setOutputOption({ ...{ value: "" } });
        await commonFunction.callChackFiledFunction(namespace, "", currentConfigKey, component);
      }
    }
  };

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <ApplearingText
        keyName="value"
        id={component.id || component._id}
        options={inputOption}
        isSearchable={false}
        label={component.label}
        disabled={component.disable}
        required={component.validate.required}
        valueObject={outputOption || null}
        handleChange={(value) => {
          validate(value);
        }}
        filterOptions={(options, { inputValue }) => {
          if (!component.show_default_option && inputValue.trim() === "") {
            return [];
          }
          const inputValues = inputValue.trim().toLowerCase().split(" ");
          return options.filter((option) => {
            const labelWords = option.toLowerCase().split(" ");
            const firstWord = labelWords[0];

            return inputValues.every((val, index) => {
              if (index === 0) {
                return firstWord.startsWith(val);
              } else {
                return labelWords.slice(1).some((word) => word.startsWith(val));
              }
            });
          });
        }}
      />
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id || component._id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFLearningTextField;
