import React from "react";
import { getApiCallLocalPath } from "../../../../../utils/apiCallFunction";
import RecordsetRenderer from "../../../../detailsviewlib/RecordsetRenderer";
import SectionRenderer from "../SectionRenderer";
import { FSIconRenderer } from "../../../../zinoIcon";
import { useImagePreviewContext } from "../../../../../applicationcontext/ImagePreviewContext";
import { convertNumbetToDate, convertNumbetToTime, stringToJSONChange } from "../../../../utils/commonFunction";
import KanbanButton from "../../actions/KanbanButton";
import KanbanDropDown from "../../actions/KanbanDropDown";
import { getDataFromDataObjectByKey } from "../../../../../utils/updateConfigFunction";
import IconDecider from "../../../../../utils/IconDecider";
import MapPopup from "../../../../../components/Map/MapPopup";
// import file_save from "../../../Images/downloadfileicon.png";

const CardItems = ({
  colItem,
  dataConfig,
  layoutDataLineHeight,
  activityCardsConfig = {},
  detailsViewDataActivityCard = {},
  detailsViewDataTimeline = {},
  fieldLabelFlag,
  sm_id
}) => {
  const { showPopupImagePreview } = useImagePreviewContext();
  // let height = layoutDataLineHeight * colItem.grid["h"];
  let styleObject = {
    gridArea: `zino-${colItem?.i || colItem.id}`
    // height: `${height}px`
  };

  let class_name = "";
  if (colItem.className) {
    if (typeof colItem.className === "object") {
      class_name = Object.values(colItem.className).join(" ");
    } else {
      class_name = colItem.className;
    }
  }

  let dataIs = [];
  getDataFromDataObjectByKey(dataConfig, dataIs, colItem?.data_key || "");

  //for text dynamic color
  let colorIf = {};
  if (colItem?.colorif) {
    for (let index = 0; index < colItem.colorif.length; index++) {
      const elem = colItem.colorif[index];
      colorIf[elem.value.toLowerCase()] = elem.color;
    }
  }

  let bgColor = "";
  if (typeof dataIs[0] === "string") {
    bgColor = colorIf[dataIs[0].toLowerCase()];
  } else if (typeof dataIs[0] === "number") {
    bgColor = colorIf[dataIs[0]];
  }

  if (["recordset", "recordset-list", "grid"].includes(colItem.field_type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <RecordsetRenderer itemConfig={colItem} detailsViewData={dataConfig} />
      </div>
    );
  } else if (["rich-grid"].includes(colItem.field_type)) {
    return <div key={colItem.id} style={styleObject}></div>;
  } else if (["radio"].includes(colItem.field_type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <span className="value">{dataIs.join(", ")}</span>
      </div>
    );
  }

  if (["textfield", "number", "text", "select", "string"].includes(colItem.data_type)) {
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label" style={{ color: bgColor }}>
          {dataIs.join(", ")}
        </span>
      </div>
    );
  } else if (["textarea"].includes(colItem.data_type)) {
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        {dataIs.map((dataString, dataIndex) => {
          dataString = dataString?.replaceAll(/\n/g, "<br/>");
          return (
            <span
              key={dataIndex}
              className="value card_Text_label"
              dangerouslySetInnerHTML={{ __html: dataString }}
              style={{ color: bgColor }}
            ></span>
          );
        })}
      </div>
    );
  } else if (["object"].includes(colItem.data_type)) {
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        {dataIs.length > 0 && <span className="value card_Text_label">{JSON.stringify(dataIs, null, 2)}</span>}
      </div>
    );
  } else if (["geolocation"].includes(colItem.data_type)) {
    const lat = dataIs?.[0]?.[0];
    const lng = dataIs?.[0]?.[1];
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        {/* <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{dataIs?.join(", ")}</span> */}

        <MapPopup lat={lat} lng={lng} />
      </div>
    );
  } else if (["boolean"].includes(colItem.data_type)) {
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{dataIs?.join(", ")}</span>
      </div>
    );
  } else if (["date", "day", "datetime"].includes(colItem.data_type)) {
    if (dataIs.length > 0) {
      let formattedDate = "";
      for (let index = 0; index < dataIs.length; index++) {
        const dateItem = dataIs[index];
        if (colItem.field_type === "time") {
          if (index !== 0) formattedDate += " , ";
          formattedDate += convertNumbetToTime(dateItem);
        } else {
          if (index !== 0) formattedDate += " ";
          formattedDate += convertNumbetToDate(dateItem, colItem.field_type, "string", ["dd", "mm", "yyyy"]);
        }
      }

      return (
        <div
          className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
          key={colItem.id}
          id={`${colItem?.custom_id || ""}`}
          style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        >
          <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
          {fieldLabelFlag && colItem?.display && (
            <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
          )}
          <span className="value card_Text_label">{formattedDate}</span>
        </div>
      );
    } else {
      return (
        <div
          className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
          key={colItem.id}
          id={`${colItem?.custom_id || ""}`}
          style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        >
          <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
          {fieldLabelFlag && colItem?.display && (
            <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
          )}
        </div>
      );
    }
  } else if (["image", "file"].includes(colItem.data_type)) {
    let imageFile = [];
    for (let s = 0; s < dataIs.length; s++) {
      const element = dataIs[s];
      if (typeof element === "object") {
        imageFile = [...imageFile, ...element];
      } else {
        imageFile.push(element);
      }
    }
    return (
      <div
        className={`overflow ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{
          ...styleObject,
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "20px",
          ...(colItem.inline_style || {}),
          ...(stringToJSONChange(colItem.custom_css) || {})
        }}
      >
        {imageFile?.map((image_src, image_index) => {
          if (typeof image_src === "object") {
            if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
              return (
                <img
                  key={image_index}
                  // style={{ height: "75px", width: "auto", cursor: "zoom-in" }}
                  style={{ height: "auto", width: "100%", cursor: "zoom-in" }}
                  src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                  alt={image_src}
                  onClick={() => showPopupImagePreview(image_src)}
                />
              );
            } else if (image_src.url) {
              return undefined;
            } else {
              return (
                <a
                  key={image_index}
                  rel="noreferrer"
                  href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                  target="_blank"
                >
                  <IconDecider fileName={image_src.name} />
                </a>
              );
            }
          } else {
            const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
            const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
            const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");

            if (
              endWithPNGPattern.test(image_src) ||
              endWithJPGPattern.test(image_src) ||
              endWithJPEGPattern.test(image_src)
            ) {
              return (
                <img
                  key={image_index}
                  style={{ height: "auto", width: "100%" }}
                  // style={{ height: "75px", width: "auto" }}
                  src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                  alt={image_src}
                />
              );
            } else {
              return (
                <a
                  key={image_index}
                  rel="noreferrer"
                  href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                  target="_blank"
                >
                  {colItem.display} : <IconDecider fileName={image_src.name} />
                </a>
              );
            }
          }
        })}
      </div>
    );
  } else if (["video"].includes(colItem.data_type)) {
    return (
      <div key={colItem.id} style={{ ...styleObject, aspectRatio: "16/9", height: "auto" }}>
        <iframe
          style={{
            height: "100%",
            width: "100%",
            border: "none",
            outline: "none"
          }}
          src={dataIs}
          title={colItem.display}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (["icon"].includes(colItem.data_type)) {
    return (
      <div
        className={`${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
      </div>
    );
  } else if (["array"].includes(colItem.data_type)) {
    let bodyClassName = class_name;
    return (
      <div key={colItem.id} style={{ ...styleObject, height: "auto" }}>
        {dataIs.map((dateItem, dateItemIndex) => {
          return (
            <div
              key={dateItemIndex}
              className={`${bodyClassName} ${colItem?.custom_classname || ""}`}
              id={`${colItem?.custom_id || ""}`}
              style={{ ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
            >
              {colItem?.elements.map((colElement, colElementIndex) => {
                return (
                  <CardItems
                    key={colElementIndex}
                    colItem={colElement}
                    dataConfig={dateItem}
                    layoutDataLineHeight={layoutDataLineHeight}
                    sm_id={sm_id}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  } else if (["string[]"].includes(colItem.data_type)) {
    let data = "";
    for (let index = 0; index < dataIs.length; index++) {
      const element = dataIs[index];
      if (index !== 0) {
        data += ", ";
      }
      data += element.join(", ");
    }
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{data}</span>
      </div>
    );
  } else if (["empty"].includes(colItem.type)) {
    return <div key={colItem.id} style={styleObject}></div>;
  } else if (["label"].includes(colItem.type)) {
    return (
      <div
        className={`${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{
          ...styleObject,
          display: "flex",
          alignItems: "center",
          ...(colItem.inline_style || {}),
          ...(stringToJSONChange(colItem.custom_css) || {})
        }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {colItem.name}
      </div>
    );
  } else if (["html"].includes(colItem.type)) {
    return (
      <div
        className={`${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        dangerouslySetInnerHTML={{
          __html: colItem?.content
        }}
      ></div>
    );
  } else if (["dynamic-html"].includes(colItem.type)) {
    return (
      <div
        className={`${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        dangerouslySetInnerHTML={{
          __html: dataConfig?.[colItem.id] || colItem?.content
        }}
      ></div>
    );
  } else if (["recordset", "recordset-list", "grid"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <RecordsetRenderer itemConfig={colItem} detailsViewData={dataConfig} />
      </div>
    );
  } else if (["rich-grid"].includes(colItem.type)) {
    return <div key={colItem.id} style={styleObject}></div>;
  } else if (["spacer"].includes(colItem.type)) {
    return <div key={colItem.id} style={{ ...styleObject, height: `${colItem?.height || 40}px` }}></div>;
  } else if (["separator"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        style={{
          ...styleObject
        }}
      >
        <hr
          style={{
            ...(colItem.inline_style || {}),
            ...(stringToJSONChange(colItem.custom_css) || {})
          }}
          id={`${colItem?.custom_id || ""}`}
          className={`${colItem?.custom_classname || ""}`}
        />
      </div>
    );
  } else if (["section"].includes(colItem.type)) {
    return (
      // <div key={colItem.id} style={{ ...styleObject }}>
      <div key={colItem.id} style={{ ...styleObject, padding: "8px" }}>
        <div
          className={`${Object.keys(detailsViewDataTimeline).length !== 0 && "dashboard_section"} ${
            colItem?.custom_classname || ""
          }`}
          id={`${colItem?.custom_id || ""}`}
          style={{
            height: colItem.height ? `${colItem.height}px` : "100%",
            ...(colItem.inline_style || {}),
            ...(stringToJSONChange(colItem.custom_css) || {})
          }}
        >
          <SectionRenderer
            itemConfig={colItem}
            detailsViewData={dataConfig}
            detailsViewDataTimeline={detailsViewDataTimeline}
            activityCardsConfig={activityCardsConfig}
            detailsViewDataActivityCard={detailsViewDataActivityCard}
            sm_id={sm_id}
          />
        </div>
      </div>
    );
  } else if (["image"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <img
          src={colItem.source}
          alt={colItem.name}
          id={`${colItem?.custom_id || ""}`}
          className={`${class_name} ${colItem?.custom_classname || ""}`}
          style={{ ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        />
      </div>
    );
  } else if (["button"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <KanbanButton sm_id={sm_id} itemConfig={colItem} itemType="single" reqData={dataConfig} />
      </div>
    );
  } else if (["dropdown"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <KanbanDropDown sm_id={sm_id} itemConfig={colItem} reqData={dataConfig} />
      </div>
    );
  } else {
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{dataIs}</span>
      </div>
    );
  }
};

export default CardItems;
