import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import toast from "react-hot-toast";
import { setErrorShow } from "../appview/appviewaction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

export const CHART_DASHBOARD_DATA_CONFIG = "CHART_DASHBOARD_DATA_CONFIG";
export const CHART_FILTER_DATA_APICALL = "CHART_FILTER_DATA_APICALL";
export const CHART_FILTER_DATA_FIELD = "CHART_FILTER_DATA_FIELD";
export const CHART_DATA_FIELD = "CHART_DATA_FIELD";

export const setDashboardConfig = (namespace, payloaddata) => ({
  type: CHART_DASHBOARD_DATA_CONFIG,
  payload: { namespace, payloaddata }
});

export const setFilterDataForAPICall = (namespace, payloaddata) => ({
  type: CHART_FILTER_DATA_APICALL,
  payload: { namespace, payloaddata }
});

export const setFilterFieldData = (namespace, payloaddata) => ({
  type: CHART_FILTER_DATA_FIELD,
  payload: { namespace, payloaddata }
});

export const setChartFieldData = (namespace, payloaddata) => ({
  type: CHART_DATA_FIELD,
  payload: { namespace, payloaddata }
});

export const getDashboardConfig =
  (namespace, chartDashboard, jobParamsTokenConfig = {}) =>
  (dispatch) => {
    let config = chartDashboard;
    dispatch(setFilterDataForAPICall(namespace, config?.["global_filters"]?.["fields"] || []));
    dispatch(setDashboardConfig(namespace, chartDashboard));

    let facet_by = [];
    let onscreen_filters = config?.["global_filters"]?.["fields"] || [];
    if (config.table_type === "rdbms") {
      for (let index = 0; index < onscreen_filters.length; index++) {
        const element = onscreen_filters[index];
        if (["varchar"].includes(element.field_type)) {
          element.data_type = "text";
        } else if (["date"].includes(element.field_type)) {
          element.data_type = "date";
        }
      }
    } else {
      for (let index = 0; index < onscreen_filters.length; index++) {
        const element = onscreen_filters[index];
        if (element.field_type === "learning-text") {
          element.data_type = element.field_type;
        }
        if (element.data_type === "text") element.type = "select";
        if (element.data_type === "learning-text") element.type = "select";
        if (element.data_type === "string") element.type = "select";
        if (element.data_type === "select") element.type = "select";
        if (element.type === "select") facet_by.push(element.id);
      }
    }

    dispatch(
      getFilterDataForAPICall(namespace, {
        facet_by: facet_by,
        table_type: config.table_type
      })
    );
  };

export const getFilterDataForAPICall =
  (namespace, filters = {}) =>
  async (dispatch) => {
    let search_query = {
      facet_by: filters.facet_by
    };

    if (filters.filter_by) {
      search_query["filter_by"] = Object.values(filters.filter_by);
    }
    let function_name = "fetch_chart_data";
    if (filters.table_type === "rdbms") {
      function_name = "fetch_chart_data_rdb";
    }
    try {
      let { data } = await axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/dynamic`,
        data: {
          data: {},
          function_name: function_name,
          params: {
            // table_id: tableId,
            chart_id: namespace,
            search_query: search_query,
            front_end: {
              params_config: {},
              action_config: {}
            }
          }
        },
        headers: getApiCallHeadersData()
      });
      data = data.data || {};
      let filterData = {};
      if (data.facets) {
        for (let index = 0; index < data.facets.length; index++) {
          const element = data.facets[index];
          filterData[element.id] = element.facet_values;
        }
      }
      dispatch(
        setFilterFieldData(namespace, {
          chartData: data.chart_data,
          tiles: data.tiles,
          filters: filters,
          filterData: filterData
        })
      );
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
      dispatch(setErrorShow(error.response.data?.message || error.response.data.error));
    }
  };

export const resetAllData = (namespace) => (dispatch) => {
  dispatch(setDashboardConfig(namespace, {}));
  dispatch(setFilterDataForAPICall(namespace, []));
  dispatch(setFilterFieldData(namespace, []));
  dispatch(setChartFieldData(namespace, []));
};
