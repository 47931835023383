import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { useMemo } from "react";
import TaskCard from "./TaskCard";

function ColumnContainer({ column, tasks, layout, namespace, sm_id }) {
  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const { setNodeRef } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column
    },
    disabled: false
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "500px",
        maxHeight: "maxContent"
      }}
    >
      <div className="columnHeader">
        <div style={{ display: "flex", gap: "5px" }}>
          <p>{column.name}</p>-<p>{tasks.length}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          overflowY: "auto",
          overflowX: "hidden",
          flexDirection: "column",
          flexGrow: "1",
          gap: "0.2rem"
        }}
      >
        <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <TaskCard key={task.id} task={task} layout={layout} namespace={namespace} sm_id={sm_id} />
          ))}
        </SortableContext>
      </div>
    </div>
  );
}

export default ColumnContainer;
