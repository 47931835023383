import React from "react";
import { gridCommonFunction } from "../../../../utils/gridCommonFunction";
import CardItems from "./CardItems";
import { stringToJSONChange } from "../../../../utils/commonFunction";

const CardRenderer = ({
  configData,
  repeatCard,
  detailsViewData,
  layoutDataLineHeight = 40,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  fieldLabelFlag,
  sm_id
}) => {
  let layoutConfig = configData.layouts;
  let dataConfig = detailsViewData;

  let bodyClassName = "";
  if (configData.className) {
    if (typeof configData.className === "object") {
      bodyClassName = Object.values(configData.className).join(" ");
    } else {
      bodyClassName = configData.className;
    }
  }
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < layoutConfig.length; index++) {
    let gridElement = layoutConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  if (repeatCard) {
    return (
      <>
        {dataConfig.map((cardData, cardIndex) => {
          return (
            <div
              key={cardIndex}
              className={`${bodyClassName} ${configData?.custom_classname || ""}`}
              id={`${configData?.custom_id || ""}`}
              style={{
                ...styleDataForGrid,
                height: "100%",
                ...(configData.inline_style || {}),
                ...(stringToJSONChange(configData.custom_css) || {})
              }}
            >
              {rowItemElements.map((colItem, colItemIndex) => {
                return (
                  <CardItems
                    key={colItemIndex}
                    colItem={colItem}
                    dataConfig={cardData}
                    layoutDataLineHeight={layoutDataLineHeight}
                    activityCardsConfig={activityCardsConfig}
                    detailsViewDataActivityCard={detailsViewDataActivityCard}
                    detailsViewDataTimeline={detailsViewDataTimeline}
                    fieldLabelFlag={fieldLabelFlag}
                    sm_id={sm_id}
                  />
                );
              })}
            </div>
          );
        })}
      </>
    );
  } else {
    return (
      <div
        className={`${bodyClassName} ${configData?.custom_classname || ""}`}
        id={`${configData?.custom_id || ""}`}
        style={{
          ...styleDataForGrid,
          height: "100%",
          width: "100%",
          ...(configData.inline_style || {}),
          ...(stringToJSONChange(configData.custom_css) || {})
        }}
      >
        {rowItemElements.map((colItem, colItemIndex) => {
          return (
            <CardItems
              key={colItemIndex}
              colItem={colItem}
              dataConfig={dataConfig}
              layoutDataLineHeight={layoutDataLineHeight}
              activityCardsConfig={activityCardsConfig}
              detailsViewDataActivityCard={detailsViewDataActivityCard}
              detailsViewDataTimeline={detailsViewDataTimeline}
              fieldLabelFlag={fieldLabelFlag}
              sm_id={sm_id}
            />
          );
        })}
      </div>
    );
  }
};

export default CardRenderer;
