import { useEffect, useRef, useState } from "react";

const LookupDropdown = ({
  options,
  keyName = "data",
  selectedVal,
  handleChange,
  isSearchable = false,
  label = "",
  isLookup = false,
  apiCallAndGetDataOptimizedFun,
  disabled
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setisFocus] = useState(false);

  // const inputRef = useRef(null);

  const inputRef = useRef(null);
  const selectValueRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectValueRef.current && selectValueRef.current.contains(event.target)) {
        setisFocus(true);
      } else {
        setisFocus(false);
      }
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (query) {
      apiCallAndGetDataOptimizedFun(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const selectOption = (option) => {
    if (isLookup) {
      handleChange(option.data);
    } else {
      handleChange(option[keyName]);
    }

    setQuery("");
    setIsOpen(!isOpen); // Toggle isOpen state
  };

  const getDisplayValue = () => {
    if (query) return query;
    const selectedOption = options.find((option) => option[keyName] === selectedVal);
    return selectedOption ? selectedOption.name : "";
  };

  const filter = (options) => {
    return options.filter((option) => {
      if (option.name) {
        return option.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
      }
      return null;
    });
  };

  return (
    <div className="searchable_dropdown">
      <div ref={inputRef} className="control">
        <div className="selected-value" ref={selectValueRef}>
          {isSearchable ? (
            <input
              type="text"
              value={getDisplayValue()}
              name="searchTerm"
              onChange={(e) => {
                if (disabled) {
                  return;
                }
                setQuery(e.target.value);
                handleChange(null);
              }}
              className={`did-floating-select ${isOpen || getDisplayValue() !== "" ? "has-value" : ""} ${
                isFocus ? "has-focus" : ""
              }`}
              onClick={(e) => {
                if (disabled) {
                  return;
                }
                e.stopPropagation(); // Prevent click from propagating to document
                setIsOpen(!isOpen); // Toggle isOpen state
                setisFocus(true);
              }}
              autoComplete="off"
              disabled={disabled}
            />
          ) : (
            <div
              ref={inputRef}
              className={`did-floating-select ${isOpen ? "has-value" : ""}`}
              onClick={(e) => {
                e.stopPropagation(); // Prevent click from propagating to document
                setIsOpen(!isOpen); // Toggle isOpen state
              }}
            >
              {getDisplayValue()}
            </div>
          )}

          {/* <label
            className={`did-floating-label ${isOpen || getDisplayValue() !== "" ? "has-value" : ""} ${
              isFocus ? "has-focus" : ""
            }  `}
          >
            {label}
          </label> */}
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
        <div className={`options ${isOpen ? "open" : ""}`}>
          {filter(options).map((option, index) => (
            <div
              key={index}
              onClick={() => selectOption(option)}
              className={`option ${option[keyName] === selectedVal ? "selected" : ""}`}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>

      <label
        className={`did-floating-label ${isOpen || getDisplayValue() !== "" ? "has-value" : ""} ${
          isFocus ? "has-focus" : ""
        }  `}
      >
        {label}
      </label>
    </div>
  );
};

export { LookupDropdown };
