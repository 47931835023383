import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterDataForAPICall } from "../../../../redux/chartview/chartviewaction";
import { convertNumbetToDate } from "../../../utils/commonFunction";
import { DateRangePicker } from "react-date-range";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";

function DFFRDateBox({ component, index, namespace }) {
  const storeData = useSelector((state) => state.chartviewstore);
  let filterDataForAPICall = storeData?.[`${namespace}_chartFilterDataForField`] || [];
  const dispatch = useDispatch();

  const [popupShow, setPopupShow] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  });
  const [dateRangeValueDate, setDateRangeValueDate] = useState(null);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleReset = () => {
    let date = { startDate: new Date(), endDate: new Date(), key: "selection" };
    setDateRangeValue(date);
    setPopupShow(false);
    setDateRangeValueDate(null);
    callToApi({ startDate: "", endDate: "", key: "selection" });
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDateRangeValue(selection);

    // 86400 One Day
    let startDate = new Date(selection.startDate);
    let endDate = new Date(selection.endDate);
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    setDateRangeValueDate({ startDate: startDate, endDate: endDate });
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!popupShow && dateRangeValueDate) {
  //     callToApi(dateRangeValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [popupShow]);

  const callToApi = (dateIs) => {
    let dataFilter = { ...filterDataForAPICall.filters };
    if (!dataFilter.filter_by) {
      dataFilter["filter_by"] = {};
    }
    let dataFilterObject = dataFilter.filter_by;
    if (dateIs.startDate === "" || dateIs.endDate === "") {
      delete dataFilterObject[component.id];
    } else {
      let startDate = new Date(dateIs.startDate);
      let endDate = new Date(dateIs.endDate);
      startDate = Math.floor(startDate.getTime() / 1000);
      endDate = Math.floor(endDate.getTime() / 1000);
      dataFilterObject[component.id] = {
        id: component.id,
        operator: "custom_date_range",
        human_date: "",
        custom_date_range: [
          convertNumbetToDate(startDate, "date", "number"),
          convertNumbetToDate(endDate, "date", "number")
        ]
      };
    }

    dispatch(getFilterDataForAPICall(namespace, dataFilter));
    setPopupShow(false);
  };

  return (
    <div
      className="recordview_filter_dropDown_outerBox"
      ref={isClickOutside}
      style={{ maxWidth: "520px", width: "395px" }}
    >
      <span className="recordview_filter_field_exp date_range_filter" onClick={() => setPopupShow(!popupShow)}>
        <span className="date_range_title">{component.display}</span>
        {dateRangeValueDate ? (
          <span>
            {dateRangeValueDate.startDate} - {dateRangeValueDate.endDate}
          </span>
        ) : (
          <span className="exp date_range">DD MM YYYY - DD MM YYYY</span>
        )}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="recordview_filter_field">
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[dateRangeValue]}
            direction="horizontal"
          />
          <div style={{ display: "flex", gap: "12px", padding: "0 5px" }}>
            <button
              className="zino_btn_primary"
              size="small"
              onClick={() => callToApi(dateRangeValueDate, dateRangeValue)}
            >
              Apply
            </button>
            <button className="zino_btn_outline_primary" size="small" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DFFRDateBox;
