import React from "react";

import PropTypes from "prop-types";
import { ArrowDownwardIconSvg, ArrowUpwardIconSvg, ChackBoxSvg, LockIconSvg } from "../../../zinoIcon";

const widthTypes = {
  max_width: "maxWidth",
  min_width: "minWidth",
  width: "width"
};

const TableHeadField = (props) => {
  const {
    onSelectAllClick,
    sortOrder,
    sortOrderBy,
    indeterminateFlag,
    checkedFlag,
    onRequestSort,
    tableDataHeader,
    setColumnsList,
    chackboxflag
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const lockedStyle = {
    left: chackboxflag ? "50px" : "0"
  };

  const styleObj = (headCell) => {
    let widthStyle = { width: "auto" };

    if (headCell?.width.type && headCell?.width.value && headCell.width.value !== "0") {
      widthStyle = { [widthTypes[headCell.width.type]]: headCell.width.value + "px" };
    }

    const allStyles = headCell?.locked ? { ...lockedStyle, ...widthStyle } : widthStyle;

    return allStyles;
  };

  const lockFieldHandler = (event, id, flag) => {
    event.stopPropagation();
    setColumnsList((state) => {
      let array = [...state];
      for (let index = 0; index < array.length; index++) {
        let element = array[index];
        element.locked = false;
      }
      if (!flag) {
        let itemIndex;
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          if (JSON.stringify(element).includes(id)) {
            element.locked = true;
            itemIndex = index;
            break;
          }
        }
        let oneItem = array.splice(itemIndex, 1);
        array = [...oneItem, ...array];
      }
      return array;
    });
  };

  return (
    <thead className="recordview_table_thead text_nocopy">
      <tr>
        {chackboxflag && (
          <th className="table_checkbox">
            {tableDataHeader.length > 0 && (
              <span>
                <ChackBoxSvg
                  fill="var(--primary-color)"
                  indeterminate={indeterminateFlag}
                  checked={checkedFlag}
                  onSelectAllClick={onSelectAllClick}
                />
              </span>
            )}
          </th>
        )}
        {tableDataHeader.map((headCell, index) => {
          if (headCell.sort) {
            return (
              <th
                style={styleObj(headCell)}
                key={index}
                align={headCell.numeric}
                className={headCell?.locked ? "RecordView_column_locked table_header" : ""}
              >
                <div onClick={createSortHandler(headCell.id || headCell.label)}>
                  <span>{headCell?.custom_label || headCell.label}</span>
                  {sortOrderBy === (headCell.id || headCell.label) ? (
                    <div className="sortIcon">
                      {sortOrder === "desc" ? (
                        <ArrowDownwardIconSvg />
                      ) : sortOrder === "asc" ? (
                        <ArrowUpwardIconSvg />
                      ) : (
                        <span></span>
                      )}
                    </div>
                  ) : null}
                  <div
                    className="lockIcon"
                    style={{ display: headCell?.locked ? "block" : "" }}
                    onClick={(event) => lockFieldHandler(event, headCell.id, headCell?.locked)}
                  >
                    <LockIconSvg locked={headCell?.locked} fill={headCell?.locked ? "#029BF1" : ""} />
                  </div>
                </div>
              </th>
            );
          } else {
            return (
              <th key={index} align={headCell.numeric} style={styleObj(headCell)}>
                <div>
                  <span>{headCell?.custom_label || headCell.label}</span>
                  <div
                    className="lockIcon"
                    style={{ display: headCell?.locked ? "block" : "" }}
                    onClick={(event) => lockFieldHandler(event, headCell.id, headCell?.locked)}
                  >
                    <LockIconSvg locked={headCell?.locked} fill={headCell?.locked ? "#029BF1" : ""} />
                  </div>
                </div>
              </th>
            );
          }
        })}
      </tr>
    </thead>
  );
};

TableHeadField.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(["asc", "desc"]).isRequired,
  sortOrderBy: PropTypes.string.isRequired,
  tableDataHeader: PropTypes.array.isRequired
};

export default TableHeadField;
