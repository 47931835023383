import React, { useEffect, useState } from "react";

import "./DetailsViewRender.css";

import DetailsViewItem from "./DetailsViewItem";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsviewConfigAndData } from "../../redux/detailsview/detailsviewaction";

const DetailsViewRender = ({ itemConfig, callFrom, jobParamsTokenConfig, functionObject = {} }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.detailsviewstore);
  let recordviewDataConfig = storeData?.[`${itemConfig.id}_detailsviewConfig`] || {};
  // const [detailsViewData, setDetailsViewData] = useState();
  const [jobParamsTokenConfigOld, setJobParamsTokenConfigOld] = useState();
  const [jobParamsConfig, setJobParamsConfig] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  // _detailsviewConfig
  const apiCallFunction = async () => {
    setShowLoader(true);
    await dispatch(
      getDetailsviewConfigAndData(itemConfig.id, {
        detailsview_id: itemConfig.id,
        instance_id: jobParamsTokenConfig.instance_id || jobParamsTokenConfig.id || jobParamsTokenConfig._id || null
      })
    );
    setShowLoader(false);

    // try {
    //   setShowLoader(true);
    //   let configObject = {
    //     data: {},
    //     function_name: "show_detailsview_popup",
    //     params: {
    // detailsview_id: itemConfig.id,
    // instance_id: jobParamsTokenConfig.instance_id || jobParamsTokenConfig.id || jobParamsTokenConfig._id || null,
    //       front_end: {
    //         action_config: {},
    //         params_config: {}
    //       }
    //     }
    //   };
    //   let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
    //     headers: getApiCallHeadersData()
    //   });
    //   data = data.data;
    //   let config = data?.detailsview_config?.data || {};
    //   if (data?.detailsview_config?.detailview_settings) {
    //     config["detailview_settings"] = data?.detailsview_config?.detailview_settings;
    //   }
    //   setDetailsViewData(config);
    // } catch (error) {
    //   setDetailsViewData({});
    //   toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    // } finally {
    //   setShowLoader(false);
    // }
    // setDetailsViewData(itemConfig?.config?.detailsview_data || {});
    setJobParamsConfig({
      sm_id: itemConfig.config.table_id,
      instance_id: jobParamsTokenConfig.instance_id || jobParamsTokenConfig.id || jobParamsTokenConfig._id || null,
      instance_ids: jobParamsTokenConfig.instance_ids || null
    });
  };

  useEffect(() => {
    if (
      itemConfig?.id &&
      jobParamsTokenConfig &&
      JSON.stringify(jobParamsTokenConfig) !== JSON.stringify(jobParamsTokenConfigOld)
    ) {
      setJobParamsTokenConfigOld(jobParamsTokenConfig);
      apiCallFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobParamsTokenConfig]);

  if (!itemConfig.config) {
    return undefined;
  }

  // if (!functionObject?.reloadFunction) {
  //   functionObject["reloadFunction"] = apiCallFunction;
  // }

  if (showLoader) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }
  if (Object.keys(recordviewDataConfig || {}).length > 0) {
    return (
      <DetailsViewItem
        itemConfig={recordviewDataConfig?.elements}
        activityCardsConfig={recordviewDataConfig?.activity_cards}
        detailsViewDataFormData={
          recordviewDataConfig?.detailsview_data?.form_data || recordviewDataConfig?.form_data || {}
        }
        detailsViewDataTimeline={
          recordviewDataConfig?.detailsview_data?.timeline_data || recordviewDataConfig?.timeline_data || []
        }
        detailsViewDataActivityCard={
          recordviewDataConfig?.detailsview_data?.activity_card_data || recordviewDataConfig?.activity_card_data || []
        }
        detailsViewDataActivitiesHistory={
          recordviewDataConfig?.detailsview_data?.allowed_activities_history ||
          recordviewDataConfig?.allowed_activities_history ||
          {}
        }
        detailsViewDataOrg={recordviewDataConfig}
        fieldLabelFlag={false}
        detailsViewItemConfig={itemConfig}
        jobParamsConfig={jobParamsConfig}
        functionObject={{ reloadFunction: apiCallFunction }}
        // printThis={callFrom !== "outside"}
        printThis={recordviewDataConfig?.detailview_settings?.show_print}
        callFrom="outside"
      />
    );
  } else {
    return <></>;
  }
};

export const DetailsViewRenderPopup = ({ itemConfig, rowData, functionObject = {} }) => {
  const [detailsViewData, setDetailsViewData] = useState();
  const [jobParamsConfig, setJobParamsConfig] = useState({});

  const apiCallFunction = async (processData, idIs) => {
    // try {
    //   let configObject = {
    //     data: {},
    //     function_name: "get_detailsview_data",
    //     params: {
    //       details_view_id: idIs,
    //       instance_id: processData.instance_id || processData.value.id || processData.value._id
    //     }
    //   };
    //   let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
    //     headers: getApiCallHeadersData()
    //   });
    //   data = data.data;
    //   data.timeline_data?.reverse();
    //   setDetailsViewData(data);
    // } catch (error) {
    //   toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    // }

    setDetailsViewData(itemConfig?.data?.detailsview_data || {});
    setJobParamsConfig({ sm_id: itemConfig.data.table_id || "", instance_id: rowData.instance_id || "" });
  };
  useEffect(() => {
    apiCallFunction(rowData, itemConfig.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!functionObject?.reloadFunction) {
    functionObject["reloadFunction"] = apiCallFunction;
  }

  if (Object.keys(detailsViewData || {}).length > 0) {
    return (
      <DetailsViewItem
        itemConfig={itemConfig.data.elements}
        activityCardsConfig={itemConfig.data.activity_cards}
        detailsViewDataFormData={detailsViewData?.form_data || {}}
        detailsViewDataTimeline={detailsViewData?.timeline_data || []}
        detailsViewDataActivityCard={detailsViewData?.activity_card_data || []}
        detailsViewDataActivitiesHistory={detailsViewData?.allowed_activities_history || {}}
        detailsViewDataOrg={{ ...detailsViewData, detailview_settings: itemConfig?.detailview_settings || {} }}
        fieldLabelFlag={false}
        detailsViewItemConfig={itemConfig}
        jobParamsConfig={jobParamsConfig}
        functionObject={functionObject}
        printThis={itemConfig?.detailview_settings?.show_print}
        callFrom="outside"
      />
    );
  } else {
    return <></>;
  }
};

export const DetailsViewRenderForm = ({ config, detailsViewData }) => {
  // eslint-disable-next-line no-unused-vars
  const [itemConfig, setItemConfig] = useState({
    config: {
      elements: config.elements,
      activity_cards: {}
    },
    id: ""
  });

  if (!detailsViewData) {
    return <div></div>;
  } else {
    return (
      <DetailsViewItem
        itemConfig={itemConfig.config.elements}
        activityCardsConfig={itemConfig.config.activity_cards}
        detailsViewDataFormData={detailsViewData || {}}
        detailsViewDataTimeline={[]}
        detailsViewDataActivityCard={[]}
        detailsViewDataOrg={{}}
        fieldLabelFlag={false}
        detailsViewItemConfig={itemConfig}
        printThis={false}
        functionObject={{
          reloadFunction: () => {
            setItemConfig({
              config: {
                elements: config.elements,
                activity_cards: {}
              },
              id: ""
            });
          }
        }}
        callFrom="outside"
      />
    );
  }
};

export default DetailsViewRender;
