import React, { Fragment } from "react";
import FilterSelectFieldLV from "../filter/FilterSelectFieldLV";
import DateRangePickerField from "../filter/DateRangePickerField";
import FilterMSelectFieldLV from "../filter/FilterMSelectFieldLV";
import NumberFilter from "../filter/NumberFilter";
import { useSelector } from "react-redux";

const TableFilter = ({ namespace, recordviewTableData, recordviewDataConfig, tableDataHeader }) => {
  const storeData = useSelector((state) => state.recordviewstore);
  let recordviewTableType = storeData?.[`${namespace}_recordviewTableType`];

  if (recordviewTableType === "rdbms") {
    let filterDataArray = recordviewTableData.facets;
    let filterDataObject = {};
    for (let index = 0; index < filterDataArray.length; index++) {
      const element = filterDataArray[index];
      filterDataObject[element.id] = element.facet_values;
    }
    let lableObject = {};
    for (let index = 0; index < tableDataHeader.length; index++) {
      const element = tableDataHeader[index];
      if (element.custom_label) {
        lableObject[element.label] = element.custom_label;
      }
    }
    return (
      <div className="recordview_filter_outerBox">
        {recordviewDataConfig.onscreen_filters.map((filterIs, index) => {
          let filter = JSON.parse(JSON.stringify(filterIs));
          if (lableObject[filter.id]) {
            filter.display = lableObject[filter.id];
          }
          let options = filterDataObject[filter.id] || [];

          switch (filter.field_type) {
            case "varchar":
              if (filter.multiselect) {
                return <FilterMSelectFieldLV key={index} filter={filter} namespace={namespace} options={options} />;
              } else {
                return <FilterSelectFieldLV key={index} filter={filter} namespace={namespace} options={options} />;
              }

            case "date":
              return <DateRangePickerField key={index} filter={filter} namespace={namespace} />;

            case "int":
              return <NumberFilter key={index} filter={filter} namespace={namespace} options={options} />;

            default:
              return <Fragment key={index}></Fragment>;
          }
        })}
      </div>
    );
  }

  let filterDataArray = recordviewTableData.facets;
  let filterDataObject = {};

  for (let index = 0; index < filterDataArray.length; index++) {
    const element = filterDataArray[index];
    filterDataObject[element.id] = element.facet_values;
  }

  let objectOfTableHeader = {};
  for (let index = 0; index < tableDataHeader.length; index++) {
    const element = tableDataHeader[index];
    objectOfTableHeader[element.id] = element?.custom_label || element?.label || "";
  }

  return (
    <div className="recordview_filter_outerBox">
      {recordviewDataConfig.onscreen_filters.map((filterIs, index) => {
        let filter = JSON.parse(JSON.stringify(filterIs));
        let options = filterDataObject[filter.id] || [];
        if (objectOfTableHeader[filter.id]) {
          filter.display = objectOfTableHeader[filter.id];
        }
        if (filter.custom_label) {
          filter.display = filter.custom_label;
        }
        if (filter.field_type === "learning-text") {
          filter.data_type = filter.field_type;
        }

        switch (filter.data_type || filter.field_type) {
          case "text":
          case "string":
          case "select":
          case "learning-text":
          case "textfield":
            if (filter.multiselect) {
              return <FilterMSelectFieldLV key={index} filter={filter} namespace={namespace} options={options} />;
            } else {
              return <FilterSelectFieldLV key={index} filter={filter} namespace={namespace} options={options} />;
            }

          // case "date":
          //   return <DatePickerField key={index} filter={filter} namespace={namespace} />;

          // case "datetime":
          //   return <DateTimePickerField key={index} filter={filter} namespace={namespace} />;

          case "date":
          case "datetime":
          case "date-range":
          case "datetime-range":
            return <DateRangePickerField key={index} filter={filter} namespace={namespace} />;

          // case "datetime-range":
          //   return <Fragment key={index}></Fragment>;

          case "number":
            return <NumberFilter key={index} filter={filter} namespace={namespace} options={options} />;

          default:
            return <Fragment key={index}></Fragment>;
        }
      })}
    </div>
  );
};

export default TableFilter;
