import axios from "axios";
import React from "react";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import toast from "react-hot-toast";

const ActionButtons = () => {
  const handleImportSampleData = async () => {
    try {
      await axios.get(`${getApiCallLocalPath()}api/v1/generate-data`, {
        headers: getApiCallHeadersData()
      });
      toast.success("sample data is getting created");
    } catch (error) {
      toast.error(error?.message || "sample data not created");
    } finally {
    }
  };

  return (
    <div>
      <div style={{ padding: "0 15px" }}>
        <div style={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
          <button className="zino_btn_primary" onClick={handleImportSampleData}>
            Import Sample Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionButtons;
