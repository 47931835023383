import React from "react";
import CardRenderer from "./card/CardRenderer";
import Collapse from "./collapse/collapse";

const SectionRenderer = ({
  itemConfig,
  detailsViewData,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  fieldLabelFlag,
  sm_id
}) => {
  let bodyClassName = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      bodyClassName = Object.values(itemConfig.className).join(" ");
    } else {
      bodyClassName = itemConfig.className;
    }
  }
  if (itemConfig.collapse) {
    return (
      <Collapse styleClassName={bodyClassName} sectionTitle={itemConfig.name}>
        <CardRenderer
          repeatCard={itemConfig.repeat}
          configData={itemConfig.config}
          detailsViewData={detailsViewData}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataTimeline={detailsViewDataTimeline}
          fieldLabelFlag={fieldLabelFlag}
          sm_id={sm_id}
        />
      </Collapse>
    );
  } else {
    itemConfig.config.className = bodyClassName;
    return (
      <CardRenderer
        repeatCard={itemConfig.repeat}
        configData={itemConfig.config}
        detailsViewData={detailsViewData}
        activityCardsConfig={activityCardsConfig}
        detailsViewDataActivityCard={detailsViewDataActivityCard}
        detailsViewDataTimeline={detailsViewDataTimeline}
        fieldLabelFlag={fieldLabelFlag}
        sm_id={sm_id}
      />
    );
  }
};

export default SectionRenderer;
