import axios from "axios";
import toast from "react-hot-toast";

import { getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle } from "../utils/apiCallFunction";
import { getApiCallHeadersData, removeStorageItem } from "../utils/storageFunction";

const useAxios = () => {
  const axiosInstance = axios.create({
    baseURL: getApiCallLocalPath() + "api/v1",
    timeout: 20000,
    headers: getApiCallHeadersData()
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data) {
        return response.data;
      }

      return response;
    },
    (error) => {
      throw error;
    }
  );

  const handleError = (error) => {
    console.error(error);

    if (error?.response?.data?.code === 401) {
      removeStorageItem("", [
        "Zino_app_user",
        "Zino_domain_web",
        "Zino_app_id",
        "Zino_app_name",
        "Zino_app_is_public",
        "Zino_app_theme_id",
        "Zino_app_user_go_to"
      ]);
      window.open("/", "_self");
    }

    if (error?.name !== "CanceledError") {
      let message = "";

      if (error?.response?.data?.message) {
        message = error.response.data.message;
      } else if (typeof error === "string") {
        message = error;
      } else {
        message = "Something went wrong";
      }

      toast.error(message, toastErrorMessageStyle());
    }
  };

  const handleSuccess = (message) => {
    toast.success(message, toastSuccessMessageStyle());
  };

  return { axiosInstance, handleSuccess, handleError };
};

export default useAxios;
