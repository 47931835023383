import React, { useEffect, useState } from "react";
import { commonFunction } from "../../utils/commonFunction";
import {
  getStorageItem,
  localStorageFilterOperation,
  removeStorageItem,
  setStorageItem
} from "../../../../utils/storageFunction";
import { useParams } from "react-router-dom";

const NumberFilter = ({ namespace, filter, options }) => {
  const { pagename } = useParams();
  const [option, setOption] = useState({ minValue: 0, maxValue: 0 });

  const handleChange = (value, type) => {
    let temp = { ...option };
    if (type === "minValue") {
      temp.minValue = value;
    } else if (type === "maxValue") {
      temp.maxValue = value;
    }
    setOption(temp);

    callToApi(temp.maxValue && temp.minValue, temp);
  };
  const callToApi = (flag, temp) => {
    if (flag) {
      const query = {
        id: filter.id,
        operator: "between",
        number_range: [parseInt(temp.minValue), parseInt(temp.maxValue)]
      };

      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", query]);
      setStorageItem(`${pagename}_${namespace}_${filter.id}`, JSON.stringify(temp));
      localStorageFilterOperation({ pageName: pagename, namespace, filterQuery: query });
    } else {
      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", null]);
      removeStorageItem(`${pagename}_${namespace}_${filter.id}`);
      localStorageFilterOperation({
        pageName: pagename,
        namespace,
        filterQuery: { id: filter.id },
        isRemoval: true
      });
    }
  };

  const loaclFun = async () => {
    let storeData = getStorageItem(`${pagename}_${namespace}_${filter.id}`);

    if (storeData) {
      storeData = JSON.parse(storeData);
      setOption(storeData);
    }
  };

  useEffect(() => {
    loaclFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="recordview_filter_dropDown_outerBox">
      <span className="recordview_filter_field_exp" style={{ alignItems: "center" }}>
        <span className="title">{filter.display}</span>
        <div className="recordview_number_field_container">
          <input
            type="text"
            className="app_inputBox"
            value={option.minValue}
            onChange={({ target: { value } }) => handleChange(value, "minValue")}
          />
          <p>to</p>
          <input
            type="text"
            className="app_inputBox"
            value={option.maxValue}
            onChange={({ target: { value } }) => handleChange(value, "maxValue")}
          />
        </div>
        {/* {option ? <span>{option}</span> : <span className="exp">All</span>} */}
      </span>
    </div>
  );
};

export default NumberFilter;
