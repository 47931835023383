import React from "react";

import "./Components.css";

import {
  JobActionRenderer,
  DetailsViewRender,
  DropdownActionRenderer,
  TableViewRenderer,
  CollapseSection,
  SMDetailsViewRender,
  MapViewRender,
  CalendarRender
} from "../lib/index";

import FormComponent from "./appview/FormComponent";
import ChartComponent from "./appview/ChartComponent";
import RecordViewComponent from "./appview/RecordViewComponent";
import { gridCommonFunction } from "../utils/gridCommonFunction";
import KanbanRenderer from "../lib/kanbanrenderer/KanbanRenderer";
import { FSIconRenderer } from "../lib/zinoIcon";
import { PageTabViewRenderer } from "../lib/tabviewlib/TabViewRenderer";
import { HtmlRender } from "../lib/detailsviewlib/DynamicHtml";
import { stringToJSONChange } from "../lib/utils/commonFunction";

const PageView = ({ pageConfig, callFrom = "inside", jobParamsTokenConfig = {}, functionObject }) => {
  let rowItemElements = [];
  let LayoutArray = [];

  for (let index = 0; index < pageConfig.length; index++) {
    let gridElement = pageConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  if (callFrom === "outside" && Object.keys(jobParamsTokenConfig).length === 0) {
    return;
  }

  return (
    <div style={{ ...styleDataForGrid, padding: "48x 48px 0 48px" }} className="pageView_container">
      {rowItemElements.map((colItem, colItemIndexIs) => {
        let styleObject = {
          gridArea: `zino-${colItem?.i || colItem.id}`
        };

        let class_name = "";
        if (colItem.className) {
          if (typeof colItem.className === "object") {
            class_name = Object.values(colItem.className).join(" ");
          } else {
            class_name = colItem.className;
          }
        }

        if (colItem.hide) {
          return undefined;
        } else {
          switch (colItem.type) {
            case "button":
              return (
                <div key={colItem.id + colItemIndexIs} style={{ ...styleObject }} className="pageView_item_outer">
                  <JobActionRenderer itemConfig={colItem} functionObject={functionObject} />
                </div>
              );
            case "dropdown":
              return (
                <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, padding: "20px" }}>
                  <DropdownActionRenderer itemConfig={colItem} functionObject={functionObject} />
                </div>
              );

            case "chart":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <ChartComponent
                    itemConfig={colItem}
                    namespace={colItem.id}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );

            case "recordsview":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <RecordViewComponent
                    itemConfig={colItem}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );

            case "detailsview":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <DetailsViewRender
                    itemConfig={colItem}
                    callFrom={callFrom}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );

            case "mapsview":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <MapViewRender itemConfig={colItem} namespace={colItem.id} functionObject={functionObject} />
                </div>
              );

            case "smview":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <SMDetailsViewRender itemConfig={colItem} functionObject={functionObject} />
                </div>
              );

            case "html":
              return (
                <HtmlRender
                  key={colItem.id + colItemIndexIs}
                  colItem={colItem}
                  styleObject={styleObject}
                  jobParamsConfig={jobParamsTokenConfig}
                />
              );

            case "label":
              return (
                <div
                  id={`${colItem?.custom_id || ""}`}
                  className={`${class_name} ${colItem?.custom_classname || ""} block_label pageView_item_outer`}
                  key={colItem.id + colItemIndexIs}
                  style={{
                    ...styleObject,
                    ...(colItem.inline_style || {}),
                    ...(stringToJSONChange(colItem.custom_css) || {})
                  }}
                >
                  <FSIconRenderer icon={colItem?.icon || ""} />
                  {colItem.name}
                </div>
              );

            case "image":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "20px" }}
                  className="pageView_item_outer"
                >
                  <img
                    src={colItem.source}
                    alt={colItem.name}
                    className={`${class_name} ${colItem?.custom_classname || ""}}`}
                    id={`${colItem?.custom_id || ""}`}
                    style={{
                      aspectRatio: "16/9",
                      height: "auto",
                      width: "100%",
                      ...(colItem.inline_style || {}),
                      ...(stringToJSONChange(colItem.custom_css) || {})
                    }}
                  />
                </div>
              );

            case "form":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <FormComponent
                    itemConfig={colItem}
                    functionObject={functionObject}
                    extraRowData={jobParamsTokenConfig || {}}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    callFrom="screen"
                  />
                </div>
              );

            case "empty":
              return <div key={colItem.id + colItemIndexIs} style={styleObject}></div>;

            case "tabview":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <PageTabViewRenderer
                    configData={colItem}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );

            case "tableview":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{
                    height: colItem.height ? `${colItem.height}px` : "100%",
                    ...{ styleObject },
                    ...(colItem.inline_style || {}),
                    ...(stringToJSONChange(colItem.custom_css) || {})
                  }}
                >
                  <TableViewRenderer dataConfig={colItem.config} tableConfig={colItem} />
                </div>
              );

            case "section":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <PageView
                    pageConfig={colItem.config.layout}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );

            case "collapse_section":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <CollapseSection sectionTitle={colItem.name}>
                    <PageView
                      pageConfig={colItem.config.layout}
                      jobParamsTokenConfig={jobParamsTokenConfig}
                      functionObject={functionObject}
                    />
                  </CollapseSection>
                </div>
              );

            case "spacer":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, height: `${colItem?.height || 40}px` }}
                ></div>
              );

            case "separator":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{
                    ...styleObject
                  }}
                >
                  <hr
                    style={{
                      ...(colItem.inline_style || {}),
                      ...(stringToJSONChange(colItem.custom_css) || {})
                    }}
                    id={`${colItem?.custom_id || ""}`}
                    className={`${colItem?.custom_classname || ""}`}
                  />
                </div>
              );

            case "kanban":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <KanbanRenderer configData={colItem} functionObject={functionObject} />
                </div>
              );

            case "calendar":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <CalendarRender configData={colItem} functionObject={functionObject} />
                </div>
              );
            default:
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  {colItem.type}
                </div>
              );
          }
        }
      })}
    </div>
  );
};

export default PageView;
