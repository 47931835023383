import { getStorageItem } from "../utils/storageFunction";

export const adminNavbarConfig = () => {
  let logo = getStorageItem("Zino_app_data");
  if (!logo) {
    logo = {
      logotextsize: "24",
      logotextweight: "800",
      logosize: "",
      logosrc: "",
      logotext: "HOME",
      logotextcolor: "#f94b01",
      lastpage: "/home"
    };
  } else {
    logo = JSON.parse(logo);
  }

  return {
    logo: logo,
    style: {
      nav_fixed: true,
      nav_position: "top-right"
    },
    menu: [
      {
        display: "User Management",
        icon: "",
        page_id: "",
        type: "dropdown",
        dropdown: [
          {
            display: "Location",
            path: "zadmin/location",
            icon: "",
            page_id: "",
            type: "page"
          },
          {
            display: "User",
            path: "zadmin/user",
            icon: "",
            page_id: "",
            type: "page"
          },
          {
            display: "Group",
            path: "zadmin/group",
            icon: "",
            page_id: "",
            type: "page"
          },
          {
            display: "Org",
            path: "zadmin/org",
            icon: "",
            page_id: "",
            type: "page"
          }
        ]
      },
      {
        display: "Metrics",
        path: "zadmin/metrics",
        icon: "",
        page_id: "",
        type: "page"
      },
      {
        display: "Master Data",
        path: "zadmin/masterdata",
        icon: "",
        page_id: "",
        type: "page"
      },

      {
        display: "Web Hooks",
        path: "zadmin/excel",
        icon: "",
        page_id: "",
        type: "page"
      },
      {
        display: "File Management",
        icon: "",
        page_id: "",
        type: "dropdown",
        dropdown: [
          {
            display: "User Export Files",
            path: "zadmin/export-files",
            icon: "",
            page_id: "",
            type: "page"
          },
          {
            display: "Downloads",
            path: "zadmin/downloads",
            icon: "",
            page_id: "",
            type: "page"
          },
          {
            display: "Assets",
            path: "zadmin/assets",
            icon: "",
            page_id: "",
            type: "page"
          },
          {
            display: "PDF List",
            path: "zadmin/pdflist",
            icon: "",
            page_id: "",
            type: "page"
          }
        ]
      },
      {
        display: "Action button",
        path: "zadmin/action-buttons",
        icon: "",
        page_id: "",
        type: "page"
      }
    ],
    menu_bottom: []
  };
};
