import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { getApiCallLocalPath, toastErrorMessageStyle } from "../utils/apiCallFunction";
import { getApiCallHeadersData, isJsonString } from "../utils/storageFunction";
import { saveAs } from "file-saver";
import MasterDataPopup from "./admin/MasterDataPopup";
import { CloseIconSvg } from "../lib/zinoIcon";
import { PopupBoxSection } from "../lib";

const MasterDataItem = ({ master_data, type }) => {
  const [masterDataObj, setMasterDataObj] = useState(master_data);
  const [excelMasterData, setExcelMasterData] = useState(
    JSON.stringify(
      master_data.learning_master_data ||
        master_data.grid_master_data ||
        master_data.color_master_data ||
        master_data.custom_master_data,
      null,
      2
    )
  );
  const [fileData, setFileData] = useState();
  const [showMasterData, setShowMasterData] = useState(false);
  const [isOpenExForm, setIsOpenExForm] = useState(false);
  const [isOpenEditExcel, setIsOpenEditExcel] = useState(false);

  // Old 01
  const submitMasterData = async (newdata) => {
    try {
      let payload = {
        name: masterDataObj.name,
        type: masterDataObj.type
      };

      let dataIs = [];
      if (typeof newdata === "string") {
        dataIs = isJsonString(newdata, "[", "]");
      } else {
        dataIs = isJsonString(JSON.stringify(newdata), "[", "]");
      }

      if (!dataIs) {
        toast.error("Master data is not a valid JSON", toastErrorMessageStyle());
        return;
      }

      if (masterDataObj.type === "learning_text") {
        payload["learning_master_data"] = dataIs;
      } else if (masterDataObj.type === "custom") {
        payload["custom_master_data"] = dataIs;
      } else if (masterDataObj.type === "key_value") {
        payload["custom_master_data"] = dataIs;
      } else if (masterDataObj.type === "grid") {
        payload["grid_master_data"] = dataIs;
      } else if (masterDataObj.type === "color_config") {
        payload["color_master_data"] = dataIs;
      } else {
        return;
      }

      let { data } = await axios.put(`${getApiCallLocalPath()}api/v1/masterdata/${masterDataObj.id}`, payload, {
        headers: getApiCallHeadersData()
      });

      if (masterDataObj.type === "learning_text") {
        setMasterDataObj({ ...masterDataObj, learning_master_data: dataIs });
      } else if (masterDataObj.type === "custom") {
        setMasterDataObj({ ...masterDataObj, custom_master_data: dataIs });
      } else if (masterDataObj.type === "key_value") {
        setMasterDataObj({ ...masterDataObj, custom_master_data: dataIs });
      } else if (masterDataObj.type === "grid") {
        setMasterDataObj({ ...masterDataObj, grid_master_data: dataIs });
      } else if (masterDataObj.type === "color_config") {
        setMasterDataObj({ ...masterDataObj, color_master_data: dataIs });
      } else {
        return;
      }

      toast.success(data.message, {
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "green"
        },
        iconTheme: {
          primary: "green",
          secondary: "#FFFAEE"
        }
      });
      setIsOpenEditExcel(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Master data is not a valid JSON", toastErrorMessageStyle());
    }
  };

  const downloadMasterData = async () => {
    try {
      const response = await axios.get(
        `${getApiCallLocalPath()}api/v1/masterdata/download/${masterDataObj.type}/${masterDataObj.id}`,
        {
          headers: getApiCallHeadersData(),
          responseType: "blob"
        }
      );
      toast.success("masterdata downloaded");
      const fileName = "masterdata.csv";
      saveAs(response.data, fileName);
    } catch (error) {
      toast.error(error?.message || "masterdata not downloaded");
    }
  };

  const uploadMasterData = async (masterdata) => {
    try {
      const formData = new FormData();
      formData.append("file", fileData);

      await axios.post(
        `${getApiCallLocalPath()}api/v1/masterdata/upload/${masterdata.type}/${masterdata.id}`,
        formData,
        {
          headers: { ...getApiCallHeadersData(), "Content-Type": "multipart/form-data" }
        }
      );
      toast.success("masterdata uploaded");
    } catch (error) {
      toast.error(error?.message || "masterdata not uploaded");
    } finally {
    }
  };

  // Old 02

  const data =
    masterDataObj.learning_master_data ||
    masterDataObj.grid_master_data ||
    masterDataObj.color_master_data ||
    masterDataObj.custom_master_data;

  const giveMeIcon = (type) => {
    return (
      <span style={{ fontSize: "18px" }} className="material-symbols-outlined">
        {type}
      </span>
    );
  };
  return (
    <>
      <td>{masterDataObj.name}</td>

      <td style={{ marginTop: "10px", display: "flex", gap: "15px" }}>
        <button
          className="zino_btn_outline_primary"
          style={{ justifyContent: "center" }}
          onClick={() => setShowMasterData(true)}
          title="View data"
        >
          {giveMeIcon("visibility")}
        </button>
        {type !== "grid" && type !== "color_config" && (
          <>
            <button
              title="Download"
              className="zino_btn_outline_primary"
              style={{ justifyContent: "center" }}
              onClick={downloadMasterData}
            >
              {giveMeIcon("download")}
            </button>

            <button
              title="Upload"
              className="zino_btn_outline_primary"
              style={{ justifyContent: "center" }}
              onClick={() => setIsOpenExForm(true)}
            >
              {giveMeIcon("upload")}
            </button>
          </>
        )}
        <button
          title="Upload"
          className="zino_btn_outline_primary"
          style={{ justifyContent: "center", display: type !== "grid" && type !== "color_config" ? "none" : "block" }}
          onClick={() => setIsOpenEditExcel(true)}
        >
          {giveMeIcon("edit")}
        </button>
      </td>

      <PopupBoxSection showPopup={isOpenExForm} className="slide-left-to-right">
        <div style={{ width: "80vw" }}>
          <div className="popupSection_header">
            <span className="popupSection_header_display"></span>
            <span className="popupSection_header_closeBTN" onClick={() => setIsOpenExForm(false)}>
              <CloseIconSvg />
            </span>
          </div>
          <div style={{ padding: "0 5px" }}>
            <div className="choose_file_outer">
              <div className="icon">
                <svg
                  fill={"#000000"}
                  height="48px"
                  width="48px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512.001 512.001"
                >
                  <g>
                    <g>
                      <path d="M344.381,143.771C254.765,56.017,102.37,103.776,79.825,227.7c-31.849,4.598-59.138,25.445-72.018,55.076 c-0.016,0.035-0.032,0.07-0.047,0.107c-26.687,61.602,18.784,130.232,85.51,130.232h282.267 c75.246,0,136.463-61.216,136.463-136.462C512,189.241,430.314,123.682,344.381,143.771z M375.537,381.12H93.271 c-69.246,0-84.534-98.263-18.714-119.456c14.753-4.65,43.01-7.348,74.38,21.892c6.464,6.024,16.586,5.667,22.61-0.794 c6.024-6.464,5.668-16.586-0.794-22.61c-17.93-16.712-38.071-27.33-58.484-31.453c22.034-99.077,147.374-131.851,215.247-56.305 c4.189,4.661,10.714,6.451,16.693,4.57c67.272-21.117,135.795,29.374,135.795,99.69 C480.005,334.256,433.141,381.12,375.537,381.12z" />
                    </g>
                  </g>
                </svg>
              </div>

              <input
                type="file"
                className="ff_file_field_button"
                multiple={false}
                onChange={(e) => {
                  setFileData(e.target.files[0]);
                }}
                style={{ width: "200px" }}
                accept=".xlsx, .csv, .tsv, .xls"
              />
            </div>
          </div>
          <button
            className="zino_btn_primary"
            style={{ margin: "15px auto" }}
            onClick={() => uploadMasterData(masterDataObj)}
          >
            Upload
          </button>
        </div>
      </PopupBoxSection>

      <PopupBoxSection showPopup={isOpenEditExcel}>
        <div style={{ width: "50vw" }}>
          <div className="popupSection_header">
            <span className="popupSection_header_display">{masterDataObj.name}</span>
            <span className="popupSection_header_closeBTN" onClick={() => setIsOpenEditExcel(false)}>
              <CloseIconSvg />
            </span>
          </div>
          <div style={{ padding: "0 5px" }}>
            <textarea
              placeholder={placeholderIs}
              style={{ minHeight: "20vh", padding: "5px", width: "100%" }}
              value={excelMasterData}
              onChange={(e) => setExcelMasterData(e.target.value)}
            />
            <button
              className="zino_btn_primary"
              style={{ margin: "15px auto" }}
              onClick={() => submitMasterData(excelMasterData)}
            >
              Update
            </button>
            {/* <MasterDataExcel masterData={masterDataObj} masterDataType={type} /> */}
          </div>
        </div>
      </PopupBoxSection>

      <MasterDataPopup
        data={data}
        type={type}
        showMasterDat={showMasterData}
        setShowMasterData={setShowMasterData}
        title={masterDataObj.name}
      />
    </>
  );
};

export default MasterDataItem;

// Old 01
// const [customMasterData, setCustomMasterData] = useState(
//   JSON.stringify(master_data.custom_master_data || [], null, 2)
// );
// const [newMasterData, setNewMasterData] = useState("");
// const [learningMasterData, setLearningMasterData] = useState(master_data.learning_master_data || []);

// const [keyvalueMasterData, setKeyvalueMasterData] = useState(
//   [...(master_data?.custom_master_data || [["", ""]]), ["", ""]] || [["", ""]]
// );
// const [isEditing, setIsEditing] = useState(keyvalueMasterData.length - 1);

// const handleAddInput = () => {
//   if (!newMasterData) {
//     return;
//   }
//   const newInputs = [...learningMasterData, newMasterData];
//   setLearningMasterData(newInputs);
//   setNewMasterData("");
// };

// const handleDeleteInput = (index) => {
//   const newInputs = [...learningMasterData];
//   newInputs.splice(index, 1);
//   setLearningMasterData(newInputs);
// };

// const submitMasterData = async () => {
//   try {
//     let payload = {
//       name: master_data.name,
//       type: master_data.type
//     };

//     if (master_data.type === "learning_text") {
//       let dataIs = isJsonString(JSON.stringify(learningMasterData), "[", "]");
//       if (!dataIs) {
//         toast.error("Master data is not a valid JSON", toastErrorMessageStyle());
//         return;
//       }
//       payload["learning_master_data"] = dataIs;
//     } else if (master_data.type === "custom") {
//       let dataIs = isJsonString(JSON.stringify(customMasterData), "[", "]");
//       payload["custom_master_data"] = JSON.parse(dataIs);
//       if (!dataIs) {
//         toast.error("Master data is not a valid JSON", toastErrorMessageStyle());
//         return;
//       }
//     } else if (master_data.type === "key_value") {
//       let dataIs = [...keyvalueMasterData];
//       if (!dataIs[dataIs.length - 1][0]) {
//         dataIs.pop();
//       }
//       payload["custom_master_data"] = dataIs;
//     } else {
//       return;
//     }

//     let { data } = await axios.put(`${getApiCallLocalPath()}api/v1/masterdata/${master_data.id}`, payload, {
//       headers: getApiCallHeadersData()
//     });
//     // if (master_data.type === "learning_text") {
//     //   setLearningMasterData(data.master_data);
//     // } else if (master_data.type === "custom") {
//     //   setMasterData(data.master_data);
//     // } else {
//     //   setMasterData(data.master_data);
//     // }

//     toast.success(data.message, {
//       style: {
//         border: "1px solid green",
//         padding: "16px",
//         color: "green"
//       },
//       iconTheme: {
//         primary: "green",
//         secondary: "#FFFAEE"
//       }
//     });
//   } catch (error) {
//     toast.error(error.response?.data?.message || "Master data is not a valid JSON", toastErrorMessageStyle());
//   }
// };

// const handleUploadFile = (file) => {
//   /* Boilerplate to set up FileReader */
//   const reader = new FileReader();
//   const rABS = !!reader.readAsBinaryString;
//   reader.onload = (e) => {
//     /* Parse data */
//     const bstr = e.target.result;
//     const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
//     /* Get first worksheet */
//     const wsname = wb.SheetNames[0];
//     const ws = wb.Sheets[wsname];
//     /* Convert array of arrays */
//     const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
//     /* Update state */
//     setFileData(data);
//   };
//   if (rABS) reader.readAsBinaryString(file);
//   else reader.readAsArrayBuffer(file);
// };

// Old 02
// const handleAddKeyvalueInput = () => {
//   if (!keyvalueMasterData[keyvalueMasterData.length - 1][0]) {
//     toast.error("Add Data", toastErrorMessageStyle());
//     return;
//   }
//   const newInputs = [...keyvalueMasterData, ["", ""]];
//   setKeyvalueMasterData(newInputs);
//   setIsEditing(newInputs.length - 1);
// };
// const handleDeleteKeyvalueInput = (index) => {
//   const newInputs = [...keyvalueMasterData];
//   newInputs.splice(index, 1);
//   setKeyvalueMasterData(newInputs);
//   setIsEditing(newInputs.length - 1);
// };
// const handleKeyvalueLabelChange = (index, labelValue) => {
//   const newInputs = [...keyvalueMasterData];
//   const value = labelValue.replace(/ /g, " ");
//   newInputs[index] = [labelValue, value];
//   // newInputs[index] = [labelValue, newInputs[index][1] || value];
//   setKeyvalueMasterData(newInputs);
// };
// const handleKeyvalueValueChange = (index, name, value) => {
//   const newInputs = [...keyvalueMasterData];
//   newInputs[index] = [name, value.replace(/ /g, " ")];
//   setKeyvalueMasterData(newInputs);
// };

const placeholderIs = `Enter Master Data 
   [
    {
      "name": "name",
      "grid_data": {values:[[heads], [rows], [rows]]}
    }
  ]


`;
