import React from "react";

import { ReactComponent as PdfIcon } from "../Images/PdfIcon.svg";
import { ReactComponent as WordIcon } from "../Images/WordIcon.svg";
import { ReactComponent as ExcelIcon } from "../Images/Xls.svg";
import { ReactComponent as FileIcon } from "../Images/FileIcon.svg";
import { ReactComponent as Jpg } from "../Images/Jpg.svg";
import { ReactComponent as Png } from "../Images/Png.svg";

const IconDecider = ({ fileName }) => {
  if (!fileName) {
    return;
  }
  const format = fileName.split(".").pop();

  let icon = null;

  if (format === "pdf") {
    icon = (
      <span>
        <PdfIcon style={{ width: "35px", height: "35px" }} />
      </span>
    );
  } else if (
    format === "msword" ||
    format === "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    format === "doc" ||
    format === "docx"
  ) {
    icon = (
      <span>
        <WordIcon style={{ width: "35px", height: "35px" }} />
      </span>
    );
  } else if (
    format === "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    format === "vnd.ms-excel" ||
    format === "xlsx" ||
    format === "xls"
  ) {
    icon = (
      <span>
        <ExcelIcon style={{ width: "35px", height: "35px" }} />
      </span>
    );
  } else if (format === "jpg" || format === "jpeg") {
    icon = (
      <span>
        <Jpg style={{ width: "35px", height: "35px" }} />
      </span>
    );
  } else if (format === "png") {
    icon = (
      <span>
        <Png style={{ width: "35px", height: "35px" }} />
      </span>
    );
  } else {
    <span>
      <FileIcon style={{ width: "35px", height: "35px" }} />
    </span>;
  }

  return icon;
};

export default IconDecider;
