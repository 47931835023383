import React, { useState } from "react";
import "./JobActionRenderer.css";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import { FSIconRenderer } from "../../zinoIcon";
import PopupBoxSection from "../../viewlib/PopupBoxSection/PopupBoxSection";
import { stringToJSONChange } from "../../utils/commonFunction";

const JobActionRenderer = ({ itemConfig, reqData = {}, jobParamsConfig, functionObject }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [aiPrompt, setAiPrompt] = useState("");

  let aiJobs = ["perform_activity_ai"];

  const { createNewTask, cacheProcessData } = usePageHandlerContext();

  // const componentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   copyStyles: true,
  //   removeAfterPrint: true,
  //   content: () => componentRef?.current
  // });

  const handleJobCallFunction = () => {
    // if (itemConfig.job_template === "print_detailsview") {
    //   if (itemConfig?.params?.detailsview_id) {
    //     let detailsview_id = itemConfig.params.detailsview_id;
    //     let dvHtml = document?.getElementById(detailsview_id);
    //     if (dvHtml) {
    //       componentRef.current = dvHtml;
    //       handlePrint();
    //     }
    //   }
    //   return;
    // }
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig.action_start,
      action_end: itemConfig.action_end
    };
    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
      sm_id:
        jobParamsConfig?.sm_id ||
        itemConfig.data_id ||
        itemConfig?.action_data?.sm_id ||
        cacheProcessData?.value?.sm_id ||
        null,
      instance_id: jobParamsConfig?.instance_id || null,
      instance_ids: jobParamsConfig?.instance_ids || null,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    if (Object.keys(reqData).length === 0 && Object.keys(cacheProcessData?.value || {}).length > 0) {
      reqData = cacheProcessData?.value;
    }

    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData = {};
    } else if (["perform_activity_ai"].includes(action_config.action_name)) {
      reqData = { prompt: aiPrompt };
    } else {
      reqData = { ...reqData, sm_id: action_config.sm_id };
    }

    createNewTask(element_config, action_config, reqData);

    setAiPrompt("");
    setIsPopupOpen(false);
  };

  let class_name = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      class_name = Object.values(itemConfig.className).join(" ");
    } else {
      class_name = itemConfig.className;
    }
  }
  let buttonClass = itemConfig.disabled ? "zino_btn_disabled" : "zino_btn_outline_primary";

  if (aiJobs.includes(itemConfig?.job_template)) {
    return (
      <>
        <button
          className={`${buttonClass} ${class_name} ${itemConfig?.custom_classname || ""}`}
          onClick={() => {
            setIsPopupOpen(true);
          }}
          id={`${itemConfig?.custom_id || ""}`}
          style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
        >
          <FSIconRenderer icon={itemConfig?.icon || ""} />
          {itemConfig?.name}
        </button>
        {isPopupOpen && (
          <PopupBoxSection>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "15px"
              }}
            >
              <label htmlFor="aiprompt"></label>
              <textarea
                className="aiprompt_textarea"
                id="aiprompt"
                name="prompt"
                rows="20"
                cols="50"
                onChange={(e) => {
                  setAiPrompt(e.target.value);
                }}
                defaultValue={aiPrompt}
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
                padding: "15px"
              }}
            >
              <button
                className={`zino_btn_outline_primary ${itemConfig?.custom_classname || ""}`}
                onClick={() => {
                  setAiPrompt("");
                  setIsPopupOpen(false);
                }}
                id={`${itemConfig?.custom_id || ""}`}
                style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
              >
                <FSIconRenderer icon={itemConfig?.icon || ""} />
                Close
              </button>
              <button
                className={`zino_btn_primary ${itemConfig?.custom_classname || ""}`}
                onClick={handleJobCallFunction}
                id={`${itemConfig?.custom_id || ""}`}
                style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
              >
                <FSIconRenderer icon={itemConfig?.icon || ""} />
                {itemConfig?.name}
              </button>
            </div>
          </PopupBoxSection>
        )}
      </>
    );
  } else {
    return (
      <button
        className={`${buttonClass} ${class_name} ${itemConfig?.custom_classname || ""}`}
        onClick={handleJobCallFunction}
        id={`${itemConfig?.custom_id || ""}`}
        style={{ ...(itemConfig.inline_style || {}), ...(stringToJSONChange(itemConfig.custom_css) || {}) }}
      >
        <FSIconRenderer icon={itemConfig?.icon || ""} marginR="0px" />
        {itemConfig?.name}
      </button>
    );
  }
};

export default JobActionRenderer;

export const JobActionRendererButtonGroup = ({ itemConfig, reqData = {}, jobParamsConfig, functionObject }) => {
  const { createNewTask, cacheProcessData } = usePageHandlerContext();
  const handleJobCallFunction = (buttonItemConfig) => {
    let element_config = {
      element_id: buttonItemConfig.id,
      action_start: buttonItemConfig.action_start,
      action_end: buttonItemConfig.action_end
    };
    let action_config = {
      action_in: buttonItemConfig?.job_template || buttonItemConfig?.action_in || buttonItemConfig.action,
      navigate_to: buttonItemConfig?.params?.name || buttonItemConfig.navigate_to,
      action_data: buttonItemConfig?.action_data,
      action_name: buttonItemConfig?.job_template || buttonItemConfig?.action_data?.job_name,
      sm_id:
        jobParamsConfig?.sm_id ||
        buttonItemConfig.data_id ||
        buttonItemConfig?.action_data?.sm_id ||
        cacheProcessData?.value?.sm_id ||
        null,
      instance_id: jobParamsConfig?.instance_id || null,
      instance_ids: jobParamsConfig?.instance_ids || null,
      activity_id: buttonItemConfig?.action_data?.activity_id,
      data_source: buttonItemConfig?.data_source,
      data_source_type: buttonItemConfig?.data_type,
      params: buttonItemConfig?.params || {}
    };

    if (Object.keys(reqData).length === 0 && Object.keys(jobParamsConfig || {}).length > 0) {
      reqData = jobParamsConfig;
    } else {
      reqData = { ...reqData, sm_id: action_config.sm_id };
    }
    createNewTask(element_config, action_config, reqData);
  };

  // let class_name = "";
  // if (itemConfig.className) {
  //   if (typeof itemConfig.className === "object") {
  //     class_name = Object.values(itemConfig.className).join(" ");
  //   } else {
  //     class_name = itemConfig.className;
  //   }
  // }

  let recent_activities_id = [];

  if (reqData?.recent_activities) {
    for (let index = 0; index < reqData.recent_activities.length; index++) {
      const element = reqData?.recent_activities?.[index]?.activity_id;
      if (element && !recent_activities_id.includes(element)) recent_activities_id.push(element);
    }
  }

  let button_list = itemConfig?.button_list || [];
  return (
    <div style={{ width: "100%", display: "flex", gap: "8px", flexWrap: "wrap" }}>
      {button_list.map((buttonConfig, buttonIndex) => {
        let class_name = "";
        if (buttonConfig.className) {
          if (typeof buttonConfig.className === "object") {
            class_name = Object.values(buttonConfig.className).join(" ");
          } else {
            class_name = buttonConfig.className;
          }
        }
        // let display_logic = buttonConfig?.display_logic;
        // let flag = false;
        // if (display_logic) {
        //   let logic_data = display_logic?.logic_data || [];

        //   if (display_logic.logic_type === "activity_performed") {
        //     for (let index = 0; index < logic_data.length; index++) {
        //       const element = logic_data[index];
        //       if (recent_activities_id.includes(element)) {
        //         flag = true;
        //         break;
        //       }
        //     }
        //   } else if (display_logic.logic_type === "state_base") {
        //     if (logic_data.includes(reqData?.current_state)) {
        //       flag = true;
        //     }
        //   } else {
        //     flag = true;
        //   }
        // } else {
        //   flag = true;
        // }
        // if (flag) {
        return (
          <button
            key={buttonIndex}
            className={`zino_btn_primary ${class_name}`}
            onClick={() => handleJobCallFunction(buttonConfig)}
            style={buttonConfig.inline_style || {}}
          >
            <FSIconRenderer icon={buttonConfig?.icon || ""} />
            <span>{buttonConfig?.name}</span>
          </button>
        );
        // } else {
        //   return <></>;
        // }
      })}
    </div>
  );
};
