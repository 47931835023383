import React, { useLayoutEffect, useState, useCallback, useEffect } from "react";

import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import axios from "axios";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";

import { SearchableDropdown } from "../html/HtmlInput";

function FFAppUserField({ component, currentConfigKey, namespace }) {
  const { user } = useSelector((state) => state.userviewstore);
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  const [outputOption, setOutputOption] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ""
  );
  const [inputOption, setInputOption] = useState([]);

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async (term) => {
    try {
      let field_Data = {
        field_id: component.id
      };
      if (formConfigData.id) field_Data["form_id"] = formConfigData.id;
      if (formConfigData.sm_id) field_Data["sm_id"] = formConfigData.sm_id;
      if (formConfigData.activity_id) field_Data["activity_id"] = formConfigData.activity_id;
      // if (formConfigData.entity_id) field_Data["entity_id"] = formConfigData.entity_id;
      // if (formConfigData.sm_activity_id) field_Data["activity_id"] = formConfigData.sm_activity_id;
      let { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          Params: component?.app_user_filter || {},
          data: field_Data,
          function_name: "get_app_users"
        },
        { headers: getApiCallHeadersData() }
      );

      let viewData = data.data?.users || [];
      setInputOption(viewData);
    } catch (error) {
      setInputOption([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  useEffect(() => {
    apiCallAndGetDataOptimizedFun();
    apiCallAndGetDataDebounce(apiCallAndGetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputOption.length > 0 && !outputOption && component.prefill_current_user) {
      for (let index = 0; index < inputOption.length; index++) {
        const element = inputOption[index];
        if (element.id === user.login_user_id) {
          validate(element);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption]);

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setOutputOption(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    setOutputOption(value);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
    // setInputOption([]);
  };
  if (component.show) {
    let styleIs = {};
    if (component.disable) {
      styleIs["pointerEvents"] = "none";
    }
    return (
      <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
        <div className="w_100">
          <SearchableDropdown
            id={component.id}
            options={inputOption}
            isSearchable={true}
            dropdownType="app-user"
            disabled={component.disable}
            selectedVal={outputOption?.name || null}
            keyName="name"
            handleChange={(value) => {
              validate(value);
            }}
            label={component.label}
            style={styleIs}
          />
        </div>
        {errors.length > 0 && (
          <>
            <div className="invalid_feedback">
              {errors.map((error, index) => (
                <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                  {error}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default FFAppUserField;
