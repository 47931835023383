import React from "react";
import CardRenderer from "./card/CardRenderer";
import Collapse from "./collapse/collapse";
import "./SectionRenderer.css";

const SectionRenderer = ({
  itemConfig,
  detailsViewData,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  fieldLabelFlag,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  checkbox,
  handleCheckboxClick,
  selectedItems,
  detailsViewDataOrg,
  functionObject
}) => {
  let bodyClassName = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      bodyClassName = Object.values(itemConfig.className).join(" ");
    } else {
      bodyClassName = itemConfig.className;
    }
  }
  itemConfig.config.className = bodyClassName;
  itemConfig.config.inlineStyle = itemConfig.inline_style || {};
  if (itemConfig.collapse) {
    return (
      <Collapse styleClassName={bodyClassName} sectionTitle={itemConfig.display}>
        <CardRenderer
          repeatCard={itemConfig.repeat}
          configData={itemConfig.config}
          detailsViewData={detailsViewData}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataTimeline={detailsViewDataTimeline}
          fieldLabelFlag={fieldLabelFlag}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
        />
      </Collapse>
    );
  } else {
    return (
      <CardRenderer
        repeatCard={itemConfig.repeat}
        configData={itemConfig.config}
        detailsViewData={detailsViewData}
        activityCardsConfig={activityCardsConfig}
        detailsViewDataActivityCard={detailsViewDataActivityCard}
        detailsViewDataTimeline={detailsViewDataTimeline}
        fieldLabelFlag={fieldLabelFlag}
        detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
        jobParamsConfig={jobParamsConfig}
        checkbox={checkbox}
        handleCheckboxClick={handleCheckboxClick}
        selectedItems={selectedItems}
        detailsViewDataOrg={detailsViewDataOrg}
        functionObject={functionObject}
      />
    );
  }
};

export default SectionRenderer;
