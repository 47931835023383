import React, { useEffect, useState } from "react";

import { checkDataUniqueOrNot, getDataById, validateGridCollItem } from "../utils/storeFunction";
import { commonFunction } from "../../utils/commonFunction";
import { FSIconRenderer } from "../../../zinoIcon";

const FGLearningTextField = ({
  namespace,
  gridCollItem,
  currentConfigKey,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  const [outputOption, setOutputOption] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || ""
  );
  const [inputOptionError, setInputOptionError] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []
  );

  const [inputOption, setInputOption] = useState([]);
  const [inputOptionFilterData, setInputOptionFilterData] = useState([]);
  const [inputOptionIds, setInputOptionIds] = useState([]);
  const [termText, setTermText] = useState("");

  const apiCallAndGetData = async (term) => {
    let document = gridCollItem?.data?.values || [];
    setInputOption(document);
  };

  useEffect(() => {
    if (stateObject.gridComponentFormElements) {
      setOutputOption(getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElements]);

  useEffect(() => {
    setInputOptionError(getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElementsError]);

  useEffect(() => {
    let array = [];
    let arrayIds = [];
    const startWithPattern = new RegExp(`^${termText}.*$`, "i");
    for (let index = 0; index < inputOption.length; index++) {
      const element = inputOption[index];
      if (startWithPattern.test(element)) {
        array.push(element);
        arrayIds.push(`${currentConfigKey}_${index}_lookup_option`);
      }

      if (array.length === 10) {
        break;
      }
    }

    setInputOptionIds(arrayIds);
    setInputOptionFilterData(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOption, termText]);

  useEffect(() => {
    apiCallAndGetData("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionKeydownFunction = (event, data = "") => {
    let currentCollItemId = event.target.id;
    let nextCollItemId = "";

    switch (event.key) {
      case "ArrowUp":
        // if (inputOptionIds.length === 0) {
        //   commonFunctionObject.keyboardKeydownFun(event);
        // }
        if (currentCollItemId === `${currentConfigKey}_lookup_option`) {
          nextCollItemId = currentConfigKey;
        } else if (currentCollItemId === inputOptionIds[0]) {
          nextCollItemId = `${currentConfigKey}_lookup_option`;
        } else {
          let index = inputOptionIds.indexOf(currentCollItemId);
          if (index !== -1) {
            nextCollItemId = inputOptionIds?.[index - 1] || "";
          }
        }
        event.preventDefault();
        break;

      case "ArrowDown":
        if (currentCollItemId === currentConfigKey) {
          nextCollItemId = `${currentConfigKey}_lookup_option`;
        } else if (currentCollItemId === `${currentConfigKey}_lookup_option`) {
          nextCollItemId = inputOptionIds[0];
        } else {
          let index = inputOptionIds.indexOf(currentCollItemId);
          if (index !== -1) {
            nextCollItemId = inputOptionIds?.[index + 1] || "";
          }
        }
        event.preventDefault();
        break;

      case "Tab":
        if (gridCollItem.allow_new) {
          validate(event, data || termText, false);
        }

        if (event.shiftKey) {
          commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey, "left");
          event.preventDefault();
        } else {
          commonFunctionObject.keyboardKeydownFun(event, "outside", currentConfigKey, "right");
          event.preventDefault();
        }
        break;

      case "Enter":
        if (gridCollItem.allow_new) {
          validate(event, data || termText, true);
        } else {
          validate(event, data, true);
        }
        break;

      default:
        break;
    }

    if (nextCollItemId) {
      let nextItem = document.getElementById(nextCollItemId);
      nextItem.focus();
    }
  };

  const validate = async (e, value, addNewRowFlag) => {
    if (gridCollItem.unique) {
      const uniqueDataFlag = checkDataUniqueOrNot(
        stateObject.gridComponentFormElements,
        gridCollItem.id,
        value || "",
        gridFlagStateObject.gridRowIndex
      );
      if (uniqueDataFlag) {
        value = "";
      }
    }

    setOutputOption(value);

    await validateGridCollItem(
      e,
      namespace,
      currentConfigKey,
      stateObject,
      value || "",
      gridCollItem,
      commonFunction,
      gridFlagStateObject,
      addNewRowFlag,
      stateFunctionObject,
      commonFunctionObject,
      true
    );
  };

  if (stateObject.gridFocusedElement === currentConfigKey) {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type="text"
          id={currentConfigKey}
          className={"formGrid_coll_item_input_focus"}
          onChange={(e) => {
            setTermText(e.target.value);
          }}
          value={termText}
          onKeyDown={optionKeydownFunction}
          disabled={gridCollItem.disable}
          autoComplete="off"
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
        />

        <div id={currentConfigKey + "_optionlist"} className="formGrid_coll_lookup_option_outbox">
          <input
            type="text"
            id={currentConfigKey + "_lookup_option"}
            className={"formGrid_coll_lookup_option"}
            onKeyDown={optionKeydownFunction}
            value={inputOptionFilterData.length > 0 ? "Select Option" : "No Data"}
            readOnly={true}
          />
          {inputOptionFilterData.map((lookupOption, lookupOptionIndex) => {
            return (
              <input
                type="text"
                id={currentConfigKey + "_" + lookupOptionIndex + "_lookup_option"}
                key={currentConfigKey + "_" + lookupOptionIndex + "_lookup_option"}
                className={"formGrid_coll_lookup_option"}
                onClick={(e) => validate(e, lookupOption, false)}
                onKeyDown={(e) => optionKeydownFunction(e, lookupOption)}
                value={lookupOption}
                readOnly={true}
                title={lookupOption}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 250}px`,
          width: `${gridCollItem?.width || 250}px`,
          maxWidth: `${gridCollItem?.width || 250}px`
        }}
      >
        <input
          type="text"
          id={currentConfigKey}
          className={"formGrid_coll_item_input"}
          onFocus={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          onClick={() => stateFunctionObject.setGridFocusedElement(currentConfigKey)}
          value={outputOption || ""}
          title={outputOption || ""}
          readOnly={true}
          onChange={() => {}}
          disabled={gridCollItem.disable}
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
        />
        {inputOptionError.length > 0 && (
          <span className="formGrid_coll_item_input_icon">
            <FSIconRenderer icon={"error"} iconColor={"red"} marginR="0" iconTitle={inputOptionError.join(" , ")} />
          </span>
        )}
      </div>
    );
  }
};

export default FGLearningTextField;
