import axios from "axios";
import { toast } from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

// ACTION TYPE
export const DETAILSVIEW_PAYLOAD_DATA = "DETAILSVIEW_PAYLOAD_DATA";
export const DETAILSVIEW_CONFIG_DATA = "DETAILSVIEW_CONFIG_DATA";
export const DETAILSVIEW_REMOVE_ALL = "DETAILSVIEW_REMOVE_ALL";

// ACTIONS
export const setDetailsviewPayloadData = (namespace, payloaddata) => ({
  type: DETAILSVIEW_PAYLOAD_DATA,
  payload: { namespace, payloaddata }
});

export const setDetailsviewConfig = (namespace, payloaddata) => ({
  type: DETAILSVIEW_CONFIG_DATA,
  payload: { namespace, payloaddata }
});

export const removeDetailsviewData = (namespace, payloaddata) => ({
  type: DETAILSVIEW_REMOVE_ALL,
  payload: { namespace, payloaddata }
});

export const getDetailsviewConfigAndData = (namespace, config) => async (dispatch) => {
  // API Call and get recordview data
  try {
    dispatch(setDetailsviewConfig(namespace, {}));
    let { data } = await axios({
      method: "post",
      url: `${getApiCallLocalPath()}api/v1/dynamic`,
      data: {
        data: {},
        function_name: "show_detailsview_popup",
        params: {
          detailsview_id: config.detailsview_id,
          instance_id: config.instance_id,
          front_end: {
            action_config: {},
            params_config: {}
          }
        }
      },
      headers: getApiCallHeadersData()
    });
    data = data.data;

    let viewData = data?.detailsview_config?.data || {};
    if (data?.detailsview_config?.detailview_settings) {
      viewData["detailview_settings"] = data?.detailsview_config?.detailview_settings;
    }

    dispatch(setDetailsviewConfig(namespace, viewData));
    dispatch(setDetailsviewPayloadData(namespace, config));

    return true;
  } catch (error) {
    dispatch(setDetailsviewConfig(namespace, {}));
    toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    return false;
  }
};
